@import url("/assets/zoni_edu/node_modules/typeface-poppins/index.css");
@import url("/assets/zoni_edu/node_modules/typeface-barlow/index.css");
@import url("/assets/zoni_edu/node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import url("/assets/zoni_edu/node_modules/typeface-roboto/index.css");
@import url("/assets/zoni_edu/node_modules/select2/dist/css/select2.min.css");
@import url("/assets/zoni_edu/node_modules/select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css");
@import url("/assets/zoni_edu/node_modules/flag-icons/css/flag-icons.min.css");
@import url("/assets/zoni_edu/node_modules/intl-tel-input/build/css/intlTelInput.min.css");
* {
  font-family: Poppins;
}
/* Uncomment the following line to see the borders */
.select2-selection__choice {
  font-size: 12px !important;
  /* Adjust the font size */
  padding: 2px 8px !important;
  /* Adjust the padding */
  margin: 4px 0 !important;
  /* Adjust the margin */
  display: inline-flex !important;
  /* Ensure they are inlined and do not break */
  margin-left: 4px !important;
  /* Adjust per your aesthetic needs */
}
/* Adjusting the close button size and position of selected items */
.select2-selection__choice__remove {
  font-size: 10px !important;
  /* Adjust the font size */
  margin-left: 4px !important;
  /* Adjust the margin */
}
.select2-selection__rendered {
  white-space: nowrap;
  /* Prevent wrapping into the next line */
  overflow-x: auto;
  /* Add scroll on overflow */
  overflow-y: hidden;
  /* Prevent vertical overflow */
}
nav.navbar .btn {
  font-size: 18px;
  padding: 0 1rem;
}
nav.navbar img.zoni_logo {
  padding-right: 30px;
}
.custom-toggler .navbar-toggler-icon {
  /*Adjust color of the hamburger*/
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 82, 155)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  font-size: 22px;
}
a.navbar-brand.ml-3 {
  align-self: center;
}
nav.navbar.navbar-light.bg-light {
  background-color: #FFF !important;
  /* !important may be needed if Bootstrap's styles are being stubborn */
}
/* Adjusting the default background and border for the toggler */
button.navbar-toggler.custom-toggler {
  background-color: transparent;
  /* Change to your desired default background color */
  border: none;
  /* Removing the border */
}
/* Adjusting the focus and active state appearance */
button.navbar-toggler.custom-toggler:focus,
.custom-toggler:active {
  outline: none;
  /* Removing the browser's default outline */
  box-shadow: none;
  /* Removing Bootstrap's default blue box-shadow */
}
div#navbarNav {
  background-color: #f3f7f9;
}
div.col.custom-link-col {
  margin-bottom: 3px;
  /* Space between columns on small screens */
}
a.custom-link {
  font-family: Poppins;
  color: #004a93;
  /* White text color */
  display: block;
  /* Make the link block-level */
  padding: 10px 15px;
  /* Add padding to the link */
  border-radius: 5px;
  /* Rounded corners for the link */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
  text-decoration: none;
  /* Remove underline on hover */
  background-color: #f3f7f9;
}
.custom-link i {
  /* Targeting FontAwesome icons */
  margin-left: 10px;
  /* Space between the icon and the text */
}
a.custom-link:hover {
  color: #FFF;
  /* White text color on hover */
  background-color: #004a93;
  /* A light background color on hover */
  text-decoration: none;
  /* Remove underline on hover */
}
a.btn.btn-header {
  color: #004a93;
  text-decoration: none;
  transition: background-color 0.3s;
}
a.btn.btn-header:hover {
  color: #FFF;
  text-decoration: none;
  background-color: #004a93;
}
div.text-muted.mt-0.txtheader {
  color: #004a93 !important;
}
div.text-muted.mt-0.txtheader:hover {
  color: #FFF !important;
}
.floating-whatsapp-btn {
  position: fixed;
  bottom: 15px;
  left: 20px;
  z-index: 9999;
  text-align: center;
}
.whatsapp-link {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #28d468;
  /* WhatsApp green color */
  text-align: center;
  font-size: 32px;
  /* Adjust based on your preference */
  color: #ffffff;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  margin-bottom: 0px;
  /* Space between the icon and the text */
  text-decoration: none;
}
.whatsapp-link:hover {
  background-color: #198742;
  /* Darker WhatsApp green for hover */
  text-decoration: none;
}
.whatsapp-text {
  color: #ffffff;
  background-color: rgba(37, 211, 102, 0);
  /* Slightly transparent WhatsApp green */
  padding: 0px 0px;
  border-radius: 12px;
  font-size: 14px;
  /* Adjust based on your preference */
  text-decoration: none;
  transform: translateY(-7px);
}
.home-header .modal-content input::-moz-placeholder {
  color: #BBB;
  /* Light gray color */
  opacity: 0.5;
  /* Adjust opacity to your liking */
  font-family: "Courier New", monospace;
  /* Use a monospace font for a dashed appearance */
}
.home-header .modal-content input::placeholder {
  color: #BBB;
  /* Light gray color */
  opacity: 0.5;
  /* Adjust opacity to your liking */
  font-family: "Courier New", monospace;
  /* Use a monospace font for a dashed appearance */
}
@media (max-width: 768px) {
  .home-header .navbar-toggler {
    margin: 0;
    padding: 0;
  }
}
.home-header .navbar,
.home-header .d-flex,
.home-header .d-lg-flex,
.home-header .d-md-flex,
.home-header .d-sm-flex {
  margin: 0;
  padding: 0;
}
.language-selector {
  position: fixed;
  bottom: -10px;
  right: 20px;
  text-align: center;
  z-index: 9999;
}
.language-selector .dropdown-toggle {
  display: block;
  width: 60px;
  /* Equal width and height for a circle */
  height: 60px;
  border-radius: 50%;
  /* Full border-radius for circular shape */
  background-color: #007bff;
  /* Bootstrap primary color, or choose your own */
  text-align: center;
  color: #ffffff;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  padding: 0;
  /* Adjust padding to align contents at the center */
  line-height: 60px;
  /* Align contents vertically */
}
.language-selector .lang-code-text {
  color: #ffffff;
  background-color: rgba(37, 211, 102, 0);
  /* Slightly transparent WhatsApp green */
  padding: 0px 0px;
  border-radius: 12px;
  font-size: 14px;
  /* Adjust based on your preference */
  text-decoration: none;
  transform: translateY(-38px) !important;
}
.language-selector .lang-label-text {
  color: #FFFFFF;
  background-color: rgba(37, 211, 102, 0);
  /* Slightly transparent WhatsApp green */
  padding: 0px 0px;
  border-radius: 12px;
  font-size: 14px;
  /* Adjust based on your preference */
  text-decoration: none;
  transform: translateY(-55px) !important;
}
.banner .video-main {
  position: inherit;
  z-index: -999999;
  display: block;
  vertical-align: bottom;
  max-height: 720px;
  max-width: 1280px;
}
.banner:last-child {
  margin-bottom: 0;
}
.banner .custom-btn {
  width: 100%;
  /* This will make sure all buttons have the same width */
  height: 100%;
  font-size: 1.3rem;
  /* This will increase the text size of the buttons */
  text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.2);
}
.banner .custom-btn-mobile {
  text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.2);
}
.banner .tours_company_text {
  font-size: 0.65rem;
  padding: 0px;
  margin-top: -7px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
  line-height: 1;
}
.banner .custom_height {
  height: 62px;
}
.banner .custom_height_mobile {
  height: 44px;
  font-size: 1.2rem;
}
.banner .tours_company_text_mobile {
  font-size: 0.5rem;
  padding: 0px;
  margin-top: -6px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
  line-height: 1;
}
.banner .quote-text {
  padding: 20px 0;
  color: #00529B;
  font-family: "Roboto Serif", serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 127.023%;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .banner .quote-text {
    font-size: 1.2rem;
  }
}
.banner .quote-text .slogan {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 127.023%;
}
.banner .trade_mark {
  color: #00529B;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: -6px;
  left: -2px;
}
@media (max-width: 768px) {
  .banner .trade_mark {
    font-size: 0.8rem;
  }
}
.banner .author {
  display: block;
  text-align: left;
  color: #00529B;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 127.023%;
}
@media (max-width: 768px) {
  .banner .author {
    font-size: 0.8rem;
  }
}
.learn-engl {
  background-color: #f3f7f9;
  padding-top: 6rem;
}
.learn-engl a {
  cursor: pointer;
  outline: 0;
  transition: all 0.25s ease-in-out;
}
.learn-engl a:focus,
.learn-engl a:hover {
  outline: 0;
  text-decoration: none;
}
.learn-engl .img,
.learn-engl .col-world img {
  height: auto;
  max-width: 100%;
}
.learn-engl .center {
  text-align: center;
}
.learn-engl .col-12,
.learn-engl .col-lg-6 {
  padding-bottom: 2rem;
}
.learn-engl .tit h2 {
  color: #0571d0;
  font-size: 3em;
  margin-bottom: -15px;
  font-weight: 700;
}
.learn-engl .tit h3 {
  color: #004a93;
  font-size: 1.5em;
  margin-bottom: -15px;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .learn-engl .tit h2 {
    font-size: 2em;
  }
  .learn-engl .tit h2 h3 {
    font-size: 1em;
  }
}
.learn-engl .col-world img {
  margin-bottom: -5rem;
}
.learn-engl .a-card-body-main {
  text-decoration: none;
}
.learn-engl .card.sombra-card {
  border-radius: 1rem;
  box-shadow: 0 4px 5px #808080;
  padding: 5px;
  transition: all 0.5s ease;
  background-color: #fff;
}
.learn-engl .card.sombra-card:hover {
  color: #fff;
  background-color: #004a93;
}
.learn-engl .a-card-body-main .sect-card .card-body h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 5px;
}
.learn-engl .a-card-body-main .sect-card .card-body p {
  font-size: 14px;
  line-height: 14.5px;
  margin-bottom: 0;
}
.learn-engl .a-card-body-main .sect-card .card-body h4,
.learn-engl .a-card-body-main .sect-card .card-body p,
.learn-engl .a-card-body-main .sect-card .btn-card p {
  color: #00529B;
}
.learn-engl .a-card-body-main:hover .sect-card .card-body h4,
.learn-engl .a-card-body-main:hover .sect-card .card-body p,
.learn-engl .a-card-body-main:hover .sect-card .btn-card p {
  color: #fff;
}
.learn-engl .a-card-body-main .sect-card .btn-card {
  font-size: 1.2em;
  padding: 0 2rem;
  text-align: left;
}
.learn-engl .custom-shape-divider-bottom-1690920639 {
  bottom: 0;
  right: 0;
  line-height: 0;
  overflow: hidden;
  transform: rotate(-180deg);
  width: 100%;
}
.learn-engl .custom-shape-divider-bottom-1690920639 svg {
  display: block;
  height: 135px;
  position: relative;
  width: calc(100% + 1.3px);
}
.learn-engl .custom-shape-divider-bottom-1690920639 .shape-fill {
  fill: #78981f;
}
.learn-engl .card-img-top {
  border-radius: 1rem 1rem 0 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.learn-engl .roundedimg {
  border-radius: 0.5rem 0.5rem 0 0;
}
.learn-engl .study_card {
  width: auto;
  margin: 0 auto;
}
.learn-engl path.shape-fill {
  fill: #FFD700 !important;
}
/* tour-with-us */
.custom-shape-divider-bottom-1690920639 {
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(-180deg);
}
.custom-shape-divider-bottom-1690920639 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 135px;
}
.custom-shape-divider-bottom-1690920639 path.shape-fill {
  fill: #FFD700 !important;
}
.tour-with-us {
  margin-top: -1rem;
  padding-bottom: 7rem;
  background: #FFD700;
}
.tour-with-us .tit {
  text-align: center;
  position: relative;
  bottom: 3rem;
}
.tour-with-us .tit h2 {
  color: #00529B;
  font-size: 3em;
  margin-bottom: -15px;
}
.tour-with-us .tit h3 {
  color: #00529B;
  font-size: 1.5em;
  margin-bottom: -15px;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .tour-with-us .tit h2 {
    color: #00529B;
    font-size: 2em;
    margin-bottom: -15px;
  }
  .tour-with-us .tit h2 h3 {
    color: #00529b;
    font-size: 1em;
    padding: 0 1rem;
    margin-bottom: -15px;
  }
}
.tour-with-us .btn-tit {
  text-align: center;
  position: relative;
  bottom: 2rem;
}
.tour-with-us .subtit h2 {
  color: #00529B;
  font-size: 2.5em;
  margin-bottom: 4rem;
}
.tour-with-us .btn-tit {
  text-align: center;
}
.tour-with-us .order {
  font-size: 1.2rem;
  color: #00529B;
  margin-top: 1rem;
  text-decoration: underline;
}
.tour-with-us .order:hover {
  font-size: 1.2rem;
  color: var(--color-quiniario);
  margin-top: 1rem;
  text-decoration: underline;
}
.tour-with-us {
  /* Ensuring FontAwesome icon aligns well with the text */
}
.tour-with-us .card h3 {
  color: #004a93;
  font-size: 1.5em;
  font-weight: bold;
  padding-right: 15px;
}
.tour-with-us .text-1 {
  align-items: center;
  /* vertically center content */
  justify-content: right;
  /* horizontally center content */
  color: var(--color-secundario);
  font-weight: bold;
}
.tour-with-us a.learn-more-btn {
  background-color: transparent;
  /* Change to your preferred color */
  border: transparent;
  color: #ef3a43;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
  /* Smooth transition for hover effect */
  display: inline-block;
  padding: 6px 12px;
  /* Adjust padding as needed */
  width: 150px;
  /* Optional: set a specific width if needed */
}
.tour-with-us .learn-more-btn:hover {
  color: #b40f17;
  /* Slightly darker shade for hover */
  transform: scale(1.05);
  /* Slight zoom on hover */
  background-color: transparent !important;
  /* Change to your preferred color */
}
.tour-with-us .learn-more-btn i.fas {
  margin-right: 5px;
  vertical-align: middle;
}
.tour-with-us .custom-btn-order-brochure {
  display: inline-block;
  padding: 6px 12px;
  /* Adjust padding as needed */
  width: 200px;
  /* Optional: set a specific width if needed */
}
.tour-with-us .custom-card {
  border-radius: 1.5rem;
}
.tour-with-us .card-deck {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.tour-with-us .card {
  flex: 1 0 auto;
}
.tour-with-us .shadow-custom {
  border-radius: 1rem;
  box-shadow: 0 4px 5px #808080;
  padding: 5px;
  transition: all 0.5s ease;
  background-color: #fff;
  /* Optional: Add a hover effect to enhance the shadow when the card is hovered */
}
.tour-with-us .shadow-custom:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-4px);
  /* This gives a slight "lift" effect on hover */
}
.tour-with-us .carousel-inner .custom-card {
  height: 80%;
  width: 80%;
  margin: 0 auto;
  /* This will center the card */
}
.tour-with-us .carousel-indicators {
  position: relative;
  /* Resets the default 'absolute' positioning */
  bottom: auto;
  /* Resets the default 'bottom' positioning */
  list-style-type: none;
}
.tour-with-us .carousel-indicators li {
  text-indent: -9999px;
  /* Hides the number in case it appears */
}
.tour-with-us div.plan_your_own {
  padding-right: 15px;
  padding-left: 15px;
}
.tour-with-us .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300529B'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.tour-with-us .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300529B'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.tour-with-us .carousel-control-prev-icon,
.tour-with-us .carousel-control-next-icon {
  background-color: transparent;
}
.tour-with-us .carousel-control-prev-icon:hover,
.tour-with-us .carousel-control-next-icon:hover {
  background-color: rgba(0, 74, 147, 0.1);
}
.tour-with-us .own-trip {
  display: inline-flex;
  align-items: center;
}
.tour-with-us .own-trip h3 strong {
  color: #00529B;
  font-size: 2rem;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .tour-with-us .own-trip {
    display: block;
  }
  .tour-with-us .own-trip h3 strong {
    color: #00529B;
    font-size: 1.5rem;
  }
}
.tour-with-us .scheduled-tour {
  background: #00529B;
  border-radius: 5rem;
  padding: 1rem 2rem;
}
.tour-with-us .scheduled-tour p strong {
  color: #fff;
  font-size: 1.2rem;
}
.tour-with-us .scheduled-tour form input {
  border-radius: 5rem;
  background: #E4F4FF;
  border: 4px solid #fff;
}
.tour-with-us .translated-row {
  position: absolute;
  top: 65px;
  /* Adjust as needed */
  z-index: 1000;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.tour-with-us .reference-element {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.tour-with-us .text-LLC {
  position: absolute;
  top: 20px;
  z-index: 1000;
  font-size: 1rem;
}
a {
  cursor: pointer;
  outline: 0;
  transition: all 0.25s ease-in-out;
}
a:focus,
a:hover {
  outline: 0;
  text-decoration: none;
}
:root {
  --color-primario: #0074e4;
  --color-secundario: #00529b;
  --color-quiniario: #ef3a43;
}
.center {
  text-align: center;
}
.join-us {
  background-color: #f3f7f9;
  padding-top: 7rem;
  padding-bottom: 5rem;
}
.join-us .container {
  width: 65rem;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .join-us {
    background-color: #f3f7f9;
    padding-top: 8rem;
    padding-bottom: 2rem;
  }
  .join-us .container {
    width: auto;
  }
}
.join-us .col-lg-12 {
  margin-bottom: 3rem;
  padding: 2rem 0;
}
.join-us .tit h2 {
  color: #ef3a43;
  font-size: 3em;
}
.join-us .col-card h3,
.join-us .subtit h2 {
  color: #00529b;
  font-size: 3em;
}
.join-us .row2 {
  gap: 8rem;
}
.join-us .col-card h3 {
  margin-bottom: 2rem;
}
.join-us .img1,
.join-us .img2 {
  border-radius: 1.5rem;
  height: 40vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.join-us .col-card p {
  color: var(--color-primario);
  font-size: 1.5em;
}
.text-LLC {
  color: #00529B;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 119%;
  /* 14.28px */
  margin-top: -45px;
}
.join-us .title-joinus {
  color: #00529B;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 101.5%;
  /* 24.36px */
  margin-top: 30px;
  margin-bottom: 30px;
}
.join-us .subtitle-joinus {
  color: #00529B;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 112.5% */
  margin-top: 30px;
  margin-bottom: 30px;
}
.card-joinus {
  border-radius: 16px;
  border: 1px solid var(--grey-2, #FAFAFA);
  background: #FFF;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
}
.adj-video {
  border-radius: 21px;
  text-align: center;
  margin-right: 20px;
  margin-left: 20px;
}
.subtit-joinus {
  color: #00529B;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subtit-joinus h2 {
  font-size: 1.5rem;
}
.video-container {
  position: relative;
}
.play-button {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-size: 2rem;
  /* Adjust as per requirement */
  z-index: 10;
  cursor: pointer;
  display: flex;
  /* Use Flexbox layout */
  justify-content: center;
  /* Center children horizontally */
  align-items: center;
  /* Center children vertically */
  width: 50px;
  /* Adjust as per requirement */
  height: 50px;
  /* Adjust as per requirement */
  /*border-radius: 50%; /* Makes it a circle */
  background-color: transparent;
  /* Semi-transparent black */
}
.play-button i {
  margin: 0;
  padding: 0;
  color: white;
}
.video-wrapper {
  position: relative;
}
#videoControls {
  position: absolute;
  bottom: 10%;
  right: 50%;
  transform: translateX(50%);
  display: none;
}
.video-thumbnail {
  border-radius: 15px;
  /* Adjust this value to achieve the desired rounding */
  overflow: hidden;
  /* Ensures that the content inside the div also respects the rounded corners */
}
.play-button:hover i.fa-play {
  color: #ef3a43;
  /* Change to whatever color you desire on hover */
}
.play-button:hover {
  background-color: rgba(25, 25, 25, 0.5);
}
.join-us .modal-fullscreen .modal-body {
  padding: 20px 0;
  /* Adding a little bit of padding to prevent the video from sticking to the top and bottom of the screen */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-fullscreen .modal-body video {
  max-height: calc(100vh - 150px);
  /* Calculating the viewport height and subtracting some pixels for the modal header and potential paddings */
  max-width: calc(100vh - 20px);
}
.join-us .subtitle-about {
  font-size: 0.7rem;
}
/* Affiliated */
.Affiliated {
  padding: 5rem 0rem;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .Affiliated {
    padding: 2rem 0rem;
  }
}
.Affiliated .tit h2 {
  color: #00529b;
  font-size: 2em;
}
.Affiliated .tit p {
  color: #00529b;
  font-size: 1.5em;
}
.Affiliated .swiper {
  width: 100%;
  height: 100%;
}
.Affiliated .mySwiper1 {
  height: 100%;
  padding-bottom: 3rem;
}
.Affiliated .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}
.Affiliated .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 60%;
  justify-content: center;
  align-items: center;
}
.Affiliated .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  box-shadow: 0 3px 5px;
}
.Affiliated .swiper .swiper-button-prev {
  background: var(--color-secundario);
  height: 4vh;
  width: 4vh;
  border-radius: 10rem;
}
.Affiliated .swiper .swiper-button-prev:after {
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
}
.Affiliated .swiper .swiper-button-next {
  background: var(--color-secundario);
  height: 4vh;
  width: 4vh;
  border-radius: 10rem;
}
.Affiliated .swiper .swiper-button-next:after {
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
}
.Affiliated .swiper-slide a {
  position: absolute;
  right: 0;
  bottom: 1rem;
}
.Affiliated .swiper-slide a button {
  border: 0;
  background: linear-gradient(-45deg, #142B3A, #0094FF);
  color: #fff;
  font-weight: 300;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: all 500ms ease;
}
.Affiliated .swiper-slide a button:hover {
  border: 0;
  background: linear-gradient(-45deg, #142B3A, var(--color-quiniario));
  color: #fff;
  font-weight: 300;
  font-size: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 1rem 0 0 1rem;
  transition: all 500ms ease;
}
/* School Trips */
.trips {
  background: #0074E4;
}
.trips .container {
  width: 70rem;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .trips .container {
    width: auto;
  }
}
.trips .tit h2 {
  color: #fff;
  font-size: 2em;
}
.col-trips {
  display: block;
  padding: 1rem;
}
.col-trips .card {
  background: #fff;
  border-radius: 1rem;
  border: 0;
}
.col-trips .card img {
  height: 15rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.trips .card-body h3 {
  color: var(--color-secundario);
  font-size: 1.2rem;
  margin: 0;
}
/* external css: flickity.css */
.carousel-cell {
  width: 43%;
  height: 45rem;
  margin-left: 20px;
  border-radius: 5px;
  justify-content: center;
}
.carousel-cell img {
  position: relative;
  border-radius: 5px;
  box-shadow: -2px 3px 10px gray;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -70px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 2;
  }
}
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: var(--color-quiniario);
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .carousel-cell {
    width: 100%;
    height: 23rem;
    margin-left: 20px;
    border-radius: 5px;
    justify-content: center;
  }
  .flickity-page-dots .dot {
    height: 25rem;
    touch-action: pan-y;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .carousel-cell {
    width: 75%;
    height: 34rem;
    margin-left: 20px;
    border-radius: 5px;
    justify-content: center;
  }
  .flickity-page-dots .dot {
    height: 40rem;
    touch-action: pan-y;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .carousel-cell {
    width: 50%;
    height: 31rem;
    margin-left: 20px;
    border-radius: 5px;
    justify-content: center;
  }
  .flickity-page-dots .dot {
    height: 35rem;
    touch-action: pan-y;
  }
}
@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .carousel-cell {
    width: 40%;
    height: 34rem;
    margin-left: 20px;
    border-radius: 5px;
    justify-content: center;
  }
  .flickity-page-dots .dot {
    height: 38rem;
    touch-action: pan-y;
  }
}
/* Carousel Trips */
.carousel-cell-trips {
  width: 32%;
  height: 35rem;
  margin-left: 20px;
  border-radius: 5px;
  justify-content: center;
  display: inline-grid;
}
.carousel-cell-trips img {
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
}
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: var(--color-quiniario);
  border-radius: 50%;
  opacity: 0.2;
  cursor: pointer;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .carousel-cell-trips {
    width: 100%;
    height: 45rem;
    margin-left: 20px;
    border-radius: 5px;
    justify-content: center;
  }
}
.flickity-enabled {
  position: relative;
  height: 23rem;
}
#popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
#popup img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
#popup-contenido {
  position: relative;
  margin: 1% auto;
  padding: 20px;
  width: 100%;
  max-width: 100rem;
  background-color: #fff;
  text-align: center;
}
#btn-cerrar {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
/************* ************* ************* *************/
/*************                             *************/
/*************      CULTURAL AVENTURES     *************/
/*************                             *************/
/************* ************* ************* *************/
/* Header */
.cult-aven-header {
  padding-top: 2rem;
}
.cult-aven-header .group-tit {
  text-align: right;
}
.cult-aven-header .group-tit .tit {
  color: var(--color-secundario);
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
}
.cult-aven-header .group-tit p {
  color: var(--color-secundario);
  font-size: 1.5rem;
  line-height: 1.5;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .cult-aven-header .group-tit {
    text-align: center;
  }
  .cult-aven-header .group-tit .tit {
    color: var(--color-secundario);
    font-size: 2rem;
    line-height: 1.2;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  .cult-aven-header .group-tit p {
    color: var(--color-secundario);
    font-size: 1rem;
    line-height: 1.2;
  }
  .cult-aven-header .container img {
    max-width: 100%;
    height: auto;
  }
}
/************* ************* ************* *************/
/*************                             *************/
/*************      CULTURAL AVENTURES     *************/
/*************                             *************/
/************* ************* ************* *************/
/* Header */
.cult-aven-header {
  padding-top: 2rem;
}
.cult-aven-header .group-tit {
  text-align: right;
}
.cult-aven-header .group-tit .tit {
  color: var(--color-secundario);
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 3rem;
}
.cult-aven-header .group-tit p {
  color: var(--color-secundario);
  font-size: 1.5rem;
  line-height: 1.5;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .cult-aven-header .group-tit {
    text-align: center;
  }
  .cult-aven-header .group-tit .tit {
    color: var(--color-secundario);
    font-size: 2rem;
    line-height: 1.2;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  .cult-aven-header .group-tit p {
    color: var(--color-secundario);
    font-size: 1rem;
    line-height: 1.2;
  }
  .cult-aven-header .container img {
    max-width: 100%;
    height: auto;
  }
}
/************* ************* ************* *************/
/*************                             *************/
/*************      CULTURAL AVENTURES     *************/
/*************                             *************/
/************* ************* ************* *************/
/* Header */
.cult-aven-header {
  padding-top: 2rem;
}
.cult-aven-header .group-tit {
  text-align: right;
}
.cult-aven-header .group-tit .tit {
  color: var(--color-secundario);
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
}
.cult-aven-header .group-tit p {
  color: var(--color-secundario);
  font-size: 1.5rem;
  line-height: 1.5;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .cult-aven-header .group-tit {
    text-align: center;
  }
  .cult-aven-header .group-tit .tit {
    color: var(--color-secundario);
    font-size: 2rem;
    line-height: 1.2;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  .cult-aven-header .group-tit p {
    color: var(--color-secundario);
    font-size: 1rem;
    line-height: 1.2;
  }
}
.cult-aven-header .container img {
  max-width: 100%;
  height: auto;
}
/************* ************* ************* *************/
/*************                             *************/
/*************      CULTURAL AVENTURES     *************/
/*************                             *************/
/************* ************* ************* *************/
/* Header */
.cult-aven-header {
  padding-top: 2rem;
}
.cult-aven-header .group-tit {
  text-align: right;
}
.cult-aven-header .group-tit .tit {
  color: var(--color-secundario);
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
}
.cult-aven-header .group-tit p {
  color: var(--color-secundario);
  font-size: 1.5rem;
  line-height: 1.5;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .cult-aven-header .group-tit {
    text-align: center;
  }
  .cult-aven-header .group-tit .tit {
    color: var(--color-secundario);
    font-size: 2rem;
    line-height: 1.2;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  .cult-aven-header .group-tit p {
    color: var(--color-secundario);
    font-size: 1rem;
    line-height: 1.2;
  }
}
.cult-aven-header .container img {
  max-width: 100%;
  height: auto;
}
/************* ************* ************* *************/
/*************                             *************/
/*************      CULTURAL AVENTURES     *************/
/*************                             *************/
/************* ************* ************* *************/
/* Header */
.cult-aven-header {
  padding-top: 2rem;
}
.cult-aven-header .group-tit {
  text-align: right;
}
.cult-aven-header .group-tit .tit {
  color: var(--color-secundario);
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1rem;
}
.cult-aven-header .group-tit p {
  color: var(--color-secundario);
  font-size: 1.5rem;
  line-height: 1.5;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .cult-aven-header .group-tit {
    text-align: center;
  }
  .cult-aven-header .group-tit .tit {
    color: var(--color-secundario);
    font-size: 2rem;
    line-height: 1.2;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  .cult-aven-header .group-tit p {
    color: var(--color-secundario);
    font-size: 1rem;
    line-height: 1.2;
  }
}
.cult-aven-header .container img {
  max-width: 100%;
  height: auto;
}
.text-title-general-info {
  color: #0074E4;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: 117.5%;
}
#gallery-general {
  background: #F3F7F9;
}
.label-days-tour {
  width: 180px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 7px;
  background: #FFD700;
  color: #00529B;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.breadcumb-location-tour {
  color: #005490;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.general-text-info {
  color: #142B3A;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* ---- grid ---- */
gutter {
  width: 4%;
}
.gutter-sizer {
  width: 4%;
}
.grid {
  background: #EEE;
  max-width: 1200px;
}
/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
  text-align: center;
}
/* ---- grid-item ---- */
.grid-sizer,
.grid-item {
  width: 50%;
}
.grid-item {
  float: right;
  background: #D26;
  border: 2px solid #333;
  border-color: hsla(0, 0%, 0%, 0.5);
  border-radius: 5px;
}
.grid-item--width2 {
  width: 40%;
}
.grid-item--width3 {
  width: 60%;
}
.grid-item--height2 {
  height: 200px;
}
.grid-item--height3 {
  height: 260px;
}
.grid-item--height4 {
  height: 360px;
}
.banner .col-descrip {
  text-align: justify;
  font-size: 14px;
}
.banner carousel_tour_image {
  max-width: 800px;
  max-height: 450px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#content-info {
  background: #FFF;
  max-width: 100%;
  margin: auto;
}
.header-accordion {
  color: #005490;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.list-ul {
  list-style: none;
}
.hr-header {
  stroke-width: 1px;
  stroke: #004A93;
}
.btn-general-info {
  border-radius: 23px;
  background: #FFD700;
  color: #00529B;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 197px;
  height: 44px;
}
.accordion-button {
  border: 1px solid #EBEBEB !important;
  height: 75px !important;
  flex-shrink: 0 !important;
  align-self: stretch !important;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.1px;
}
.accordion-button:not(.collapsed) {
  background-color: white !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-body {
  border: 1px solid #EBEBEB;
  background: #F3F7F9;
}
.list-day-tour {
  color: #004A93;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.2px;
}
.description-day {
  color: #000;
  /* paragraph */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tour-content-info .floating-btn-container {
  position: fixed;
  top: 120px;
  left: 40px;
  z-index: 100;
}
.tour-content-info .floating-btn-container .floating-btn {
  margin-bottom: 4px;
}
.tour-content-info .floating-btn-container small {
  display: block;
  text-align: center;
  font-size: 1rem;
  color: #004a93;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* interested */
.bg-interes {
  background: url(/assets/zoni_edu/assets/img/shared_components/bg-interes.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 60vh;
}
.bg-interes .group {
  position: relative;
  top: 5rem;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .bg-interes {
    background: url(/assets/zoni_edu/assets/img/shared_components/bg-interes.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 80vh;
  }
  .bg-interes .group {
    position: relative;
    top: 0rem;
  }
}
.bg-interes .group h3 strong {
  color: var(--color-secundario);
  font-size: 2.5rem;
  font-weight: bold;
}
.bg-interes .group p {
  color: var(--color-secundario);
  font-size: 1.5rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .bg-interes .group {
    position: relative;
    top: 0rem;
  }
  .bg-interes .group h3 strong {
    color: var(--color-secundario);
    font-size: 2rem;
    font-weight: bold;
  }
  .bg-interes .group p {
    color: var(--color-secundario);
    font-size: 1rem;
  }
}
.bg-interes form .bg-form {
  display: inline-flex;
  border: 3px solid darkgray;
  border-radius: 5rem;
  padding: 5px;
  width: 35rem;
  align-items: center;
}
.bg-interes form input {
  border-radius: 0 5rem 5rem 0;
  width: 30rem;
  border: none;
}
.bg-interes button {
  background: #FFD700;
  border: 3px solid #FFD700;
  border-radius: 1rem;
  padding: 0.2rem 3rem;
  margin-right: 1rem;
  font-size: 1rem;
  color: var(--color-secundario);
  font-weight: 700;
  transition: all 0.5s ease;
}
.bg-interes button:hover {
  background: var(--color-quiniario);
  border: 3px solid var(--color-quiniario);
  border-radius: 1rem;
  padding: 0.2rem 3rem;
  margin-right: 1rem;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  transition: all 500ms ease;
}
.bg-interes form .bg-form-2 {
  display: block;
  text-align: center;
}
.bg-interes form .bg-form-2 input {
  width: 100%;
  border-radius: 5rem;
  border: 1px solid #337ab7;
  margin-bottom: 2rem;
}
.tour-filter,
.tour-orga {
  /* Carousel Buttons */
  /* Position the icons in the center of the buttons */
}
.tour-filter .carousel-control-prev,
.tour-orga .carousel-control-prev,
.tour-filter .carousel-control-next,
.tour-orga .carousel-control-next {
  width: 50px;
  /* Width of the circle */
  height: 50px;
  /* Height of the circle */
  background-color: #FFD700;
  /* Background color of the circle */
  border-radius: 50%;
  /* Makes it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #004a93;
}
.tour-filter .carousel-control-prev:hover,
.tour-orga .carousel-control-prev:hover,
.tour-filter .carousel-control-next:hover,
.tour-orga .carousel-control-next:hover {
  background-color: #FFD700;
  /* On hover, make it slightly more opaque */
}
.tour-filter .carousel-control-prev i,
.tour-orga .carousel-control-prev i,
.tour-filter .carousel-control-next i,
.tour-orga .carousel-control-next i {
  font-size: 20px;
  /* Adjust the size of the arrow icons as needed */
  color: #004a93;
}
.tour-filter ol,
.tour-orga ol {
  list-style-type: none;
  /* This hides the default numbers */
  counter-reset: li;
  /* Initialize a counter for the list */
}
.tour-filter .card,
.tour-orga .card {
  border-radius: 1.5rem;
  /* Adjust the pixel value as needed */
  overflow: hidden;
}
.tour-filter .card .img-fluid,
.tour-orga .card .img-fluid {
  border-radius: 1.5rem;
  /* Adjust this as needed */
}
.tour-filter .card overlay_over_image,
.tour-orga .card overlay_over_image {
  border-radius: 1.5rem;
  /* Adjust this as needed */
}
.tour-filter .card img,
.tour-orga .card img {
  width: 100%;
  /* Take the full width of the card. */
  height: 450px;
  /* Set a fixed height */
  -o-object-fit: cover;
     object-fit: cover;
  /* Crop and resize the image to fit the container while preserving its aspect ratio. */
}
/* tour organizer */
.tour-orga {
  background: #FFD700;
}
.tour-orga .group-text {
  color: var(--color-secundario);
  text-shadow: -1px 1px 2px #0000008a;
}
.tour-orga .group-text h5 {
  font-size: 1rem;
  letter-spacing: 5px;
}
.tour-orga .group-text h3 strong {
  font-size: 3.5rem;
  color: var(--color-secundario);
}
.tour-orga form .col-form {
  align-self: center;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .tour-orga .group-text {
    text-align: center;
    color: var(--color-secundario);
    text-shadow: -1px 1px 2px #0000008a;
  }
  .tour-orga .group-text h3 strong {
    font-size: 2.5rem;
    color: var(--color-secundario);
  }
  .tour-orga form .col-form {
    align-self: center;
    text-align: center;
  }
}
.tour-orga form label {
  color: var(--color-secundario);
  font-size: 1.2rem;
  padding-left: 1rem;
}
.tour-orga form .form-select {
  height: 3rem;
  width: 80%;
  border: none;
  border-radius: 5px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .tour-orga form .form-select {
    height: 3rem;
    width: 100%;
    border: none;
    border-radius: 5px;
  }
}
.tour-orga form button {
  background: var(--color-secundario);
  border: 1px solid var(--color-secundario);
  border-radius: 5rem;
  padding: 0.5rem 3rem;
  font-size: 1.2rem;
  color: #fff;
  transition: all 500ms ease;
}
.tour-orga form button:hover {
  background: var(--color-quiniario);
  border: 1px solid var(--color-quiniario);
  border-radius: 5rem;
  padding: 0.5rem 3rem;
  font-size: 1.2rem;
  color: #fff;
  transition: all 500ms ease;
}
.tour-orga .text-bottom p {
  color: var(--color-secundario);
  font-size: 2rem;
  text-align: center;
  letter-spacing: 8px;
}
.tour-orga .text-bottom p strong {
  color: var(--color-secundario);
  font-size: 2rem;
  text-align: center;
  letter-spacing: 8px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .tour-orga .text-bottom {
    padding: 0 2rem;
  }
  .tour-orga .text-bottom p {
    color: var(--color-secundario);
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: 8px;
  }
  .tour-orga .text-bottom p strong {
    color: var(--color-secundario);
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: 8px;
  }
}
/* swiper cultural adventures */
.swiper {
  width: 100%;
  height: 60vh;
}
/* Estilos iniciales sin hover */
.swiper-slide .cont-img {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: auto;
}
.swiper-slide .cont-img img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 400px;
  height: 400px;
  border-radius: 1rem;
  box-shadow: 0 5px 10px -10px #000;
}
.swiper-slide .group {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  position: absolute;
  top: 20px;
  transition: background-image 0.5s ease;
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .mySwiper {
    height: 50vh;
  }
  .swiper-slide .cont-img img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 300px;
    height: 300px;
    border-radius: 1rem;
    box-shadow: 0 5px 10px -10px #000;
  }
  .swiper-slide .group {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh;
    position: absolute;
    top: 10px;
    transition: background-image 0.5s ease;
  }
}
@media screen and (min-width: 375px) and (max-width: 424px) {
  .mySwiper {
    height: 58vh;
  }
  .swiper-slide .cont-img img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 350px;
    height: 350px;
    border-radius: 1rem;
    box-shadow: 0 5px 10px -10px #000;
  }
  .swiper-slide .group {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    position: absolute;
    top: 10px;
    transition: background-image 0.5s ease;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .mySwiper {
    height: 65vh;
  }
  .swiper-slide .cont-img img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 400px;
    height: 400px;
    border-radius: 1rem;
    box-shadow: 0 5px 10px -10px #000;
  }
  .swiper-slide .group {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    position: absolute;
    top: 10px;
    transition: background-image 0.5s ease;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mySwiper {
    height: 65vh;
  }
  .swiper-slide .cont-img img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 333px;
    height: 350px;
    border-radius: 1rem;
    box-shadow: 0 5px 10px -10px #000;
  }
  .swiper-slide .group {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53vh;
    position: absolute;
    top: 10px;
    transition: background-image 0.5s ease;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .mySwiper {
    height: 60vh;
  }
  .swiper-slide .cont-img img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 290px;
    height: 350px;
    border-radius: 1rem;
    box-shadow: 0 5px 10px -10px #000;
  }
  .swiper-slide .group {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53vh;
    position: absolute;
    top: 10px;
    transition: background-image 0.5s ease;
  }
}
.swiper-slide .group .tit p {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s, max-height 0.3s;
}
/* Mostrar el contenido dentro de la clase "tit" al pasar el cursor */
.swiper-slide:hover .group .tit p {
  opacity: 1;
  max-height: 100px;
  /* Puedes ajustar la altura máxima para controlar la transición */
  transition: opacity 0.3s, max-height 0.3s;
}
.swiper .group .bg-small {
  background: #FFD700;
  position: absolute;
  border-radius: 2rem 0 0 0;
  top: 0rem;
  right: 0;
  padding: 0.5rem 2rem;
}
.swiper .group .bg-small p {
  margin: auto;
  color: var(--color-secundario);
  font-weight: bold;
  font-size: 15px;
}
.swiper .group .tit {
  padding: 0 5rem;
}
.swiper .group .tit h3 strong {
  color: #fff;
  font-size: 2rem;
}
.swiper .group .tit p {
  color: #fff;
  font-size: 1.2rem;
}
@media screen and (min-width: 320px) and (max-width: 1439px) {
  .swiper .group .bg-small {
    background: #FFD700;
    position: absolute;
    border-radius: 2rem 0 0 0;
    top: 0.5rem;
    right: 0;
    padding: 0.5rem 2rem;
  }
  .swiper .group .bg-small p {
    margin: auto;
    color: var(--color-secundario);
    font-weight: bold;
  }
  .swiper .group .tit {
    padding: 0 2rem;
  }
  .swiper .group .tit h3 strong {
    color: #fff;
    font-size: 20px;
  }
  .swiper .group .tit p {
    color: #fff;
    font-size: 1rem;
  }
}
.swiper .group button {
  position: absolute;
  bottom: 0rem;
  left: 1rem;
  background: none;
  border: 3px solid #fff;
  border-radius: 5rem;
  padding: 0.2rem 2rem;
  font-size: 1rem;
  color: #fff;
  transition: all 0.5s ease;
}
.swiper .group button:hover {
  background: #FFD700;
  border: 3px solid #FFD700;
  border-radius: 5rem;
  padding: 0.2rem 2rem;
  font-size: 1rem;
  color: var(--color-secundario);
  transition: all 500ms ease;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .swiper .group button {
    position: absolute;
    bottom: 0;
    left: 1rem;
    background: none;
    border: 3px solid #fff;
    border-radius: 5rem;
    padding: 0.2rem 1rem;
    font-size: 1rem;
    color: #fff;
    transition: all 0.5s ease;
  }
  .swiper .group button:hover {
    background: #FFD700;
    border: 3px solid #FFD700;
    border-radius: 5rem;
    padding: 0.2rem 1rem;
    font-size: 1rem;
    color: var(--color-secundario);
    transition: all 500ms ease;
  }
}
.swiper .swiper-button-prev {
  background: yellow;
  height: 4vh;
  width: 4vh;
  border-radius: 10rem;
}
.swiper .swiper-button-prev::after {
  font-size: 1rem;
  font-weight: bold;
}
.swiper .swiper-button-next {
  background: yellow;
  height: 4vh;
  width: 4vh;
  border-radius: 10rem;
}
.swiper .swiper-button-next::after {
  font-size: 1rem;
  font-weight: bold;
}
/* End swiper cultural adventures */
.custom-shape-divider-bottom-1690306780 {
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(-180deg);
}
.custom-shape-divider-bottom-1690306780 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(-180deg);
}
.custom-shape-divider-bottom-1690306780 .shape-fill {
  fill: #FFD700;
}
.custom-shape-divider-top-1690306873 {
  top: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.custom-shape-divider-top-1690306873 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(-180deg);
}
.custom-shape-divider-top-1690306873 .shape-fill {
  fill: #FFD700;
}
/* mission statement */
.miss-stat {
  padding: 2rem 0;
}
.miss-stat p {
  color: #808080;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .miss-stat {
    padding-bottom: 7rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 424px) {
  .miss-stat .col-miss {
    height: 50vh;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .miss-stat .col-miss {
    height: 60vh;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .miss-stat .col-miss {
    height: 100vh;
  }
}
@media screen and (min-width: 1024px) {
  .miss-stat .col-miss {
    height: 80vh;
  }
}
.miss-stat img {
  max-width: 100%;
  height: auto;
}
.miss-stat .icon-zoni {
  background: #F3F7F9;
  border-radius: 15rem;
  border: 12px solid white;
  width: 12rem;
  height: 16rem;
  display: flex;
  position: relative;
  bottom: 10rem;
  right: 21rem;
  align-items: center;
  justify-content: center;
}
.miss-stat .icon-zoni img {
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .miss-stat .icon-zoni {
    background: #F3F7F9;
    border-radius: 15rem;
    border: 7px solid white;
    width: 7rem;
    height: 9rem;
    display: flex;
    position: relative;
    bottom: 6rem;
    right: 12rem;
    align-items: center;
    justify-content: center;
  }
  .miss-stat .icon-zoni img {
    max-width: 100%;
    height: auto;
    width: 3rem;
  }
}
@media screen and (min-width: 375px) and (max-width: 424px) {
  .miss-stat .icon-zoni {
    background: #F3F7F9;
    border-radius: 15rem;
    border: 7px solid white;
    width: 7rem;
    height: 9rem;
    display: flex;
    position: relative;
    bottom: 6rem;
    right: 15rem;
    align-items: center;
    justify-content: center;
  }
  .miss-stat .icon-zoni img {
    max-width: 100%;
    height: auto;
    width: 3rem;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .miss-stat .icon-zoni {
    background: #F3F7F9;
    border-radius: 15rem;
    border: 7px solid white;
    width: 8rem;
    height: 10rem;
    display: flex;
    position: relative;
    bottom: 7rem;
    right: 17rem;
    align-items: center;
    justify-content: center;
  }
  .miss-stat .icon-zoni img {
    max-width: 100%;
    height: auto;
    width: 4rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .miss-stat .icon-zoni {
    background: #F3F7F9;
    border-radius: 15rem;
    border: 15px solid white;
    width: 12rem;
    height: 17rem;
    display: flex;
    position: relative;
    bottom: 12rem;
    right: 32rem;
    align-items: center;
    justify-content: center;
  }
  .miss-stat .icon-zoni img {
    max-width: 100%;
    height: auto;
    width: 6rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .miss-stat .icon-zoni {
    background: #F3F7F9;
    border-radius: 15rem;
    border: 10px solid white;
    width: 9rem;
    height: 12rem;
    display: flex;
    position: relative;
    bottom: 8rem;
    right: 14rem;
    align-items: center;
    justify-content: center;
  }
  .miss-stat .icon-zoni img {
    max-width: 100%;
    height: auto;
    width: 4rem;
  }
}
.miss-stat .group-text {
  margin-top: 0rem;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .miss-stat .group-text {
    margin-top: 5rem;
  }
}
.miss-stat .group-text span {
  color: #FFD700;
  font-size: 1rem;
  font-weight: bold;
}
.miss-stat .group-text h3 {
  color: var(--color-secundario);
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .miss-stat .group-text h3 {
    color: var(--color-secundario);
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }
}
.miss-stat .group-text a {
  color: var(--color-quiniario);
}
.miss-stat .group-text a:hover {
  color: var(--color-primario);
  text-decoration: underline;
}
/* Agrega estilos personalizados para el enlace de "Read More" */
.miss-stat {
  /* Ocultar el contenido adicional en el segundo párrafo inicialmente */
}
.miss-stat .read-more-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
}
.miss-stat .read-more-content.hidden {
  display: none;
}
.miss-stat .read-more-content {
  margin-top: 2rem;
}
/* Testimonial */
.testim {
  background: #FAFAFA;
  padding-top: 5rem;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .testim {
    background: #FAFAFA;
    padding-top: 10rem;
  }
}
.testim .group span strong {
  color: #FFD700;
  font-size: 1rem;
}
.testim .group h3 {
  color: var(--color-secundario);
  font-size: 3.5rem;
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .testim .group h3 {
    color: var(--color-secundario);
    font-size: 2.5rem;
    font-weight: bold;
  }
}
.testim .cont {
  background: #fff;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 10px -5px gray;
}
.testim .cont .quotes {
  background: #FFD700;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 10rem;
  color: #fff;
  position: absolute;
  top: 1rem;
  right: 20rem;
}
.testim .cont .quotes i {
  position: absolute;
  top: 13px;
  left: 13px;
  font-size: 2rem;
}
.testim .cont .test-img img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10rem;
  width: 10rem;
  height: 10rem;
}
.testim .cont h3 {
  font-size: 1.5rem;
}
.testim .cont span {
  font-size: 1rem;
}
.testim .cont .range {
  margin-top: 1rem;
}
.testim .cont .range i {
  color: #FFBD39;
  font-size: 1.5rem;
}
.testim .cont .range p {
  font-size: 1rem;
  color: #808080;
}
/*<================================= Card Testimonials 320px to 374px =================================>*/
@media screen and (min-width: 320px) and (max-width: 374px) {
  .mySwiper3 {
    width: 100%;
    height: 80vh;
  }
}
.testim .cont {
  background: #fff;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 10px -5px gray;
}
.testim .cont .quotes {
  background: #FFD700;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 10rem;
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 14rem;
}
.testim .cont .quotes i {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 1.5rem;
}
.testim .cont .test-img img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10rem;
  width: 10rem;
  height: 10rem;
}
.testim .cont h3 {
  font-size: 1.5rem;
}
.testim .cont span {
  font-size: 1rem;
}
.testim .cont .range {
  margin-top: 1rem;
}
.testim .cont .range i {
  color: #FFBD39;
  font-size: 1.5rem;
}
.testim .cont p {
  font-size: 14px;
  color: #808080;
}
/*<================================= End Card Testimonials 320px to 374px =================================>*/
/*<================================= Card Testimonials 375px to 424px =================================>*/
@media screen and (min-width: 375px) and (max-width: 424px) {
  .mySwiper3 {
    width: 100%;
    height: 75vh;
  }
}
.testim .cont {
  background: #fff;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 10px -5px gray;
}
.testim .cont .quotes {
  background: #FFD700;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 10rem;
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 17rem;
}
.testim .cont .quotes i {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 1.5rem;
}
.testim .cont .test-img img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10rem;
  width: 10rem;
  height: 10rem;
}
.testim .cont h3 {
  font-size: 1.5rem;
}
.testim .cont span {
  font-size: 1rem;
}
.testim .cont .range {
  margin-top: 1rem;
}
.testim .cont .range i {
  color: #FFBD39;
  font-size: 1.5rem;
}
.testim .cont p {
  font-size: 14px;
  color: #808080;
}
/*<================================= End Card Testimonials 375px to 424px =================================>*/
/*<================================= Card Testimonials 425px to 767px =================================>*/
@media screen and (min-width: 425px) and (max-width: 767px) {
  .mySwiper3 {
    width: 100%;
    height: 75vh;
  }
}
.testim .cont {
  background: #fff;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 10px -5px gray;
}
.testim .cont .quotes {
  background: #FFD700;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 10rem;
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 20rem;
}
.testim .cont .quotes i {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 1.5rem;
}
.testim .cont .test-img img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10rem;
  width: 10rem;
  height: 10rem;
}
.testim .cont h3 {
  font-size: 1.5rem;
}
.testim .cont span {
  font-size: 1rem;
}
.testim .cont .range {
  margin-top: 1rem;
}
.testim .cont .range i {
  color: #FFBD39;
  font-size: 1.5rem;
}
.testim .cont p {
  font-size: 14px;
  color: #808080;
}
/*<================================= End Card Testimonials 425px to 767px =================================>*/
/*<================================= Card Testimonials 768px to 1023px =================================>*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mySwiper3 {
    width: 100%;
    height: 80vh;
  }
}
.testim .cont {
  background: #fff;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 10px -5px gray;
}
.testim .cont .quotes {
  background: #FFD700;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 10rem;
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 16rem;
}
.testim .cont .quotes i {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 1.5rem;
}
.testim .cont .test-img img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10rem;
  width: 10rem;
  height: 10rem;
}
.testim .cont h3 {
  font-size: 1.5rem;
}
.testim .cont span {
  font-size: 1rem;
}
.testim .cont .range {
  margin-top: 1rem;
}
.testim .cont .range i {
  color: #FFBD39;
  font-size: 1.5rem;
}
.testim .cont p {
  font-size: 14px;
  color: #808080;
}
/*<================================= End Card Testimonials 768px to 1023px =================================>*/
/*<================================= Card Testimonials 1024px to 1439px =================================>*/
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .mySwiper3 {
    width: 100%;
    height: 85vh;
  }
}
.testim .cont {
  background: #fff;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 10px -5px gray;
}
.testim .cont .quotes {
  background: #FFD700;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 10rem;
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 14rem;
}
.testim .cont .quotes i {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 1.5rem;
}
.testim .cont .test-img img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10rem;
  width: 10rem;
  height: 10rem;
}
.testim .cont h3 {
  font-size: 1.5rem;
}
.testim .cont span {
  font-size: 1rem;
}
.testim .cont .range {
  margin-top: 1rem;
}
.testim .cont .range i {
  color: #FFBD39;
  font-size: 1.5rem;
}
.testim .cont p {
  font-size: 14px;
  color: #808080;
}
/*<================================= End Card Testimonials 1024px to 1439px =================================>*/
/*<================================= Card Testimonials 1440px =================================>*/
@media screen and (min-width: 1440px) {
  .mySwiper3 {
    width: 100%;
    height: 70vh;
  }
}
.testim .cont {
  background: #fff;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 2rem;
  box-shadow: 0 4px 10px -5px gray;
}
.testim .cont .quotes {
  background: #FFD700;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 10rem;
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 14rem;
}
.testim .cont .quotes i {
  position: absolute;
  top: 9px;
  left: 10px;
  font-size: 1.5rem;
}
.testim .cont .test-img img {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10rem;
  width: 10rem;
  height: 10rem;
}
.testim .cont h3 {
  font-size: 1.5rem;
}
.testim .cont span {
  font-size: 1rem;
}
.testim .cont .range {
  margin-top: 1rem;
}
.testim .cont .range i {
  color: #FFBD39;
  font-size: 1.5rem;
}
.testim .cont p {
  font-size: 14px;
  color: #808080;
}
/*<================================= End Card Testimonials 1440px =================================>*/
/* FOOTER
================================================== */
.site-footer {
  overflow-x: hidden;
}
.site-footer .container-fluid {
  position: relative;
  padding: 5rem 0;
  text-align: center;
  border-bottom: 1px solid #dddddd;
}
.site-footer .container {
  padding: 70px 15px 70px;
}
.site-footer ul {
  margin-bottom: 0;
}
.site-footer ul li {
  padding: 0 10px;
}
.site-footer ul a {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  text-decoration: none;
}
.site-footer ul a:hover {
  color: #196fc2;
  text-decoration: none;
}
.site-footer a {
  font-size: 12px;
  color: #00529b;
  text-transform: uppercase;
  text-decoration: none;
}
.site-footer a:hover {
  color: #196fc2;
  text-decoration: none;
}
.site-footer .tit-footer {
  color: var(--color-secundario);
  font-size: 1.5em;
  text-align: center;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .site-footer .tit-footer {
    color: var(--color-secundario);
    font-size: 1.5em;
    margin-top: 5rem;
    text-align: center;
  }
}
.site-footer .rectangle {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  background-color: #005490;
  border: none;
  color: #ffffff;
  box-shadow: none;
}
.site-footer .rectangle:hover {
  background-color: #EE3A43;
  color: #ffffff;
}
.site-footer .copyright {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  /* FOOTER*/
  .site-footer .container-fluid {
    padding: 40px 15px;
  }
  .site-footer .container {
    padding: 40px 15px 80px;
  }
}
.site-footer a button {
  color: #ffffff;
  font-size: 1.5em;
  border-radius: 1rem;
  background: #005490;
  border-color: #005490;
}
.site-footer a button:hover {
  color: #ffffff;
  font-size: 1.5em;
  border-radius: 1rem;
  background: #EE3A43;
  border-color: #EE3A43;
}
/* 
 * Rectangle 
 */
.rectangle,
.big-rectangle {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 1px solid #dddddd;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-box-shadow: -4px 4px 0px 0px #dddddd;
  box-shadow: -4px 4px 0px 0px #dddddd;
}
.rectangle i,
.big-rectangle img,
.rectangle span,
.rectangle img {
  position: absolute;
  top: 50%;
  right: 50%;
  font-size: 22px;
  transform: translate(50%, -50%) rotate(45deg);
  -webkit-transform: translate(50%, -50%) rotate(45deg);
  -moz-transform: translate(50%, -50%) rotate(45deg);
  -o-transform: translate(50%, -50%) rotate(45deg);
  -ms-transform: translate(50%, -50%) rotate(45deg);
}
.big-rectangle {
  position: absolute;
  left: auto;
  top: 50%;
  right: 50%;
  margin: 0 auto;
  width: 230px;
  height: 230px;
  background-color: #196fc2;
  border: 0;
  transform: translate(50%, -50%) rotate(-45deg);
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
  -moz-transform: translate(50%, -50%) rotate(-45deg);
  -o-transform: translate(50%, -50%) rotate(-45deg);
  -ms-transform: translate(50%, -50%) rotate(-45deg);
  -o-box-shadow: -10px 10px 0px 0px #dddddd;
  box-shadow: -10px 10px 0px 0px #dddddd;
}
.medium-rectangle {
  width: 75px;
  height: 75px;
}
.site-footer {
  /* Each slide is exactly one viewport width */
  /* Simple slide-one/slide-two styling */
  /*
   * Keyframe breakdown (10s total):
   *
   *  0-10%:   hold at 0 (slide #1)
   * 10-20%:   transition to -100vw (slide #2)
   * 20-30%:   hold at -100vw
   * 30-40%:   transition to -200vw (slide #3)
   * 40-50%:   hold at -200vw
   * 50-60%:   transition to -300vw (slide #4)
   * 60-70%:   hold at -300vw
   * 70-80%:   transition to -400vw (slide #5)
   * 80-90%:   hold at -400vw
   * 90-100%:  STILL at -400vw (no movement).
   *
   * At the exact end (100%), we remain at -400vw. Then CSS restarts
   * at 0% => transform: translateX(0). Because slide #5 and #1 are identical,
   * there's no visual "jump" or color change.
   */
}
.site-footer .footer-slider {
  display: flex;
  width: 500vw;
  height: 100%;
  animation: slideLeftWithPause 15s linear infinite;
}
.site-footer .footer-slider .slide {
  width: 100vw;
  height: 100%;
  flex-shrink: 0;
  /* don't resize */
}
.site-footer .footer-slider .slide-one {
  background-color: #f5f9fc;
}
.site-footer .footer-slider .slide-two {
  background-color: #01529b;
}
@keyframes slideLeftWithPause {
  /* SLIDE #1: hold at 0vw */
  0%,
  6.25% {
    transform: translateX(0);
  }
  /* transition to SLIDE #2 (-100vw) */
  18.75% {
    transform: translateX(100vw);
  }
  /* hold SLIDE #2 */
  31.25% {
    transform: translateX(100vw);
  }
  /* transition to SLIDE #3 (-200vw) */
  43.75% {
    transform: translateX(200vw);
  }
  /* hold SLIDE #3 */
  56.25% {
    transform: translateX(200vw);
  }
  /* transition to SLIDE #4 (-300vw) */
  68.75% {
    transform: translateX(300vw);
  }
  /* hold SLIDE #4 */
  81.25% {
    transform: translateX(300vw);
  }
  /* transition to SLIDE #5 (-400vw) */
  93.75% {
    transform: translateX(400vw);
  }
  /* hold SLIDE #5 */
  93.75%,
  100% {
    transform: translateX(400vw);
  }
}
.site-footer .footer-slider:hover {
  animation-play-state: paused;
}
.site-footer .footer-slider .image-container-award::before {
  content: '';
  position: absolute;
  right: calc(-50vw + 50%);
  top: 86.615%;
  width: 50vw;
  /* 591 - 512 */
  height: 13.5385%;
  background-color: #01529b;
  z-index: 9;
}
.site-footer .footer-slider .image-container-award::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 79.357%;
  width: 50vw;
  /* 591 - 512 */
  height: 18.7692%;
  background-color: #FFFFFF;
  z-index: 9;
}
.site-footer .footer-slider .image-container {
  position: relative;
}
.site-footer img {
  z-index: 10;
}
.site-footer .copyright-div .copyright {
  opacity: 0.8;
}
.site-footer .footer-svg path {
  fill: #EF3E42;
  opacity: 0.95;
}
.site-footer .copyright-div:hover .footer-svg path {
  opacity: 1;
}
.site-footer .copyright-div:hover .copyright {
  opacity: 1;
}
.site-footer .copyright-div {
  min-width: 0;
  min-height: 0;
  max-width: 500px;
}
.toast-news-container .toast-container {
  position: fixed;
  z-index: 1050;
}
@media (min-width: 576px) {
  .toast-news-container .toast-container {
    bottom: 1rem;
    left: 6rem;
  }
}
@media (max-width: 575.98px) {
  .toast-news-container .toast-container {
    bottom: 1rem;
    right: 50%;
    transform: translate(50%, 0%);
  }
}
.toast-news-container .toast {
  border-radius: 24px;
  width: 300px;
  height: 350px;
  overflow: hidden;
  color: white;
}
.toast-news-container .toast-header {
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background: #ffffff;
  color: white;
  border-bottom: 0px;
  height: 50%;
  /* Top half for the background */
  position: relative;
  overflow: hidden;
}
.toast-news-container .toast-header .background-image {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, -4%);
}
.toast-news-container .center-image {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-height: 50%;
  height: auto;
}
.toast-news-container .top-left-corner-image {
  position: absolute;
  top: 20%;
  right: 15%;
  transform: translate(50%, -50%);
  max-height: 20%;
  height: auto;
}
.toast-news-container .toast-body {
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  background: #ffffff;
  color: black;
  padding-top: 10%;
  height: 50%;
}
.toast-news-container .btn-close {
  color: black;
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
}
.toast-news-container .toastTitle {
  color: #EF3E42;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 114%;
  /* 17.1px */
  margin: 0 auto;
  margin-top: -40px;
  max-width: 100%;
  position: relative;
  z-index: 1100;
}
.toast-news-container .toastSubTitle {
  color: #063A6D;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 114%;
  /* 15.96px */
  margin-top: 4px;
  margin-bottom: 25px;
}
.toast-news-container .toastMessage1,
.toast-news-container .toastMessage2 {
  color: #053050;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  text-align: center;
  margin: 0;
  padding: 0;
  display: block;
}
.toast-news-container .toastMessage1 {
  font-weight: 600;
  line-height: 13px;
}
.toast-news-container .toastMessage2 {
  font-weight: 400;
  line-height: 13px;
  margin-top: -16px;
}
.toast-news-container .learnMoreBtn {
  margin-top: 5px;
}
.stepsmodal {
  /* FORMS */
  /* PRELOADER */
}
.stepsmodal #qbox-container {
  position: relative;
  padding: 20px;
}
.stepsmodal #steps-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
}
.stepsmodal .step {
  display: none;
}
.stepsmodal .step h4 {
  margin: 0 0 26px 0;
  padding: 0;
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.6;
}
.stepsmodal .progress {
  border-radius: 0px !important;
}
.stepsmodal .q__question {
  position: relative;
}
.stepsmodal .q__question:not(:last-child) {
  margin-bottom: 10px;
}
.stepsmodal .question__input {
  position: absolute;
  right: -9999px;
}
.stepsmodal .question__label {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 45px 10px 20px;
  /* Adjust padding to make space for the custom radio */
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
.stepsmodal .question__input:checked + .question__label:before {
  background-color: #0074E4;
  box-shadow: 0 0 0 0;
}
.stepsmodal .question__label:before,
.stepsmodal .question__label:after {
  position: absolute;
  content: "";
}
.stepsmodal .question__label:before {
  top: 12px;
  right: 10px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #ced4da;
  transition: all 0.15s ease-in-out;
}
.stepsmodal .question__input:checked + .question__label:after {
  top: 22px;
  right: 18px;
  width: 10px;
  height: 5px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
}
.stepsmodal input:focus {
  outline: none;
}
.stepsmodal #input-container {
  display: inline-block;
  box-shadow: none !important;
  margin-top: 36px !important;
}
.stepsmodal label.form-check-label.radio-lb {
  margin-left: 15px;
}
.stepsmodal #q-box__buttons {
  text-align: center;
}
.stepsmodal input[type="text"],
.stepsmodal input[type="email"] {
  padding: 8px 14px;
}
.stepsmodal .form-check-input:checked[type=radio],
.stepsmodal .form-check-input:checked[type=radio]:hover,
.stepsmodal .form-check-input:checked[type=radio]:focus,
.stepsmodal .form-check-input:checked[type=radio]:active {
  border: none !important;
  outline: 0px !important;
  box-shadow: none !important;
}
.stepsmodal .form-check-input:focus,
.stepsmodal input[type="radio"]:hover {
  box-shadow: none;
  cursor: pointer !important;
}
.stepsmodal #success {
  display: none;
}
.stepsmodal #success h4 {
  color: #0074E4;
}
.stepsmodal .back-link {
  font-weight: 700;
  color: #0074E4;
  text-decoration: none;
  font-size: 18px;
}
.stepsmodal .back-link:hover {
  color: #82000a;
}
.stepsmodal #preloader-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
}
.stepsmodal #preloader {
  width: 120px;
  height: 119px;
  border-top-color: #fff;
  border-radius: 100%;
  display: block;
  position: relative;
  top: 50%;
  right: 50%;
  margin: -75px -75px 0 0;
  animation: spin 2s linear infinite;
  z-index: 1001;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.stepsmodal #preloader-wrapper .preloader-section {
  width: 51%;
  height: 100%;
  position: fixed;
  top: 0;
  background: #F7F9FF;
  z-index: 1000;
}
.stepsmodal #preloader-wrapper .preloader-section.section-left {
  right: 0;
}
.stepsmodal #preloader-wrapper .preloader-section.section-right {
  left: 0;
}
.stepsmodal .loaded #preloader-wrapper .preloader-section.section-left {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.stepsmodal .loaded #preloader-wrapper .preloader-section.section-right {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.stepsmodal .loaded #preloader {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.stepsmodal .loaded #preloader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}
.stepsmodal .q-box__question {
  border: 1px solid #ccc;
  /* Example border */
  padding: 10px;
  /* Add padding for better spacing */
  margin-bottom: 10px;
  /* Space between each question box */
  border-radius: 5px;
  /* Optional: round the corners */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* Ensures content wraps inside the box */
}
.stepsmodal .q-box__question .form-check-input {
  margin-left: 10px;
  /* Space between input and label */
}
.otc-modal-container .otc {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 350px;
}
.otc-modal-container .otc fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
.otc-modal-container .otc fieldset div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.otc-modal-container input[type="number"] {
  width: 3rem;
  margin: 0.2em;
  padding: 0.5em;
  font-size: 1.5rem;
  text-align: center;
  -moz-appearance: textfield;
       appearance: textfield;
  -webkit-appearance: textfield;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.2s;
}
.otc-modal-container input[type="number"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.otc-modal-container input::-webkit-outer-spin-button,
.otc-modal-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otc-modal-container .visually-hidden {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
@media (max-width: 576px) {
  .otc-modal-container .otc {
    max-width: 100%;
    padding: 0 1rem;
  }
  .otc-modal-container input[type="number"] {
    width: 2.5rem;
    font-size: 1.25rem;
  }
}
/* store-explorer.less */
/* Scope all styles to the .store-exporer section to prevent conflicts */
.store-exporer .card-img-top {
  height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
}
.store-exporer .card-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.store-exporer .card-text {
  font-size: 0.75rem;
}
.store-exporer .products-main-container {
  background-color: #0074E4;
  border-radius: 12px;
  padding-top: 0px;
  padding-right: 0px;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .store-exporer .products-main-container {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .store-exporer .products-main-container {
    margin-top: 100px;
  }
}
.store-exporer .esl-teacher-div {
  background-color: #ff6700;
  border-top-right-radius: 12px;
}
@media (max-width: 768px) {
  .store-exporer .esl-teacher-div {
    border-top-left-radius: 12px;
  }
}
@media (min-width: 768px) {
  .store-exporer .esl-teacher-div {
    height: calc(100% - 15px);
  }
}
.store-exporer .esl-teacher-div a {
  text-decoration: none;
}
.store-exporer .esl-teacher-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .store-exporer .esl-teacher-title {
    text-align: center;
    padding-top: 30px;
  }
}
@media (min-width: 768px) {
  .store-exporer .esl-teacher-title {
    text-align: right;
    padding-top: 50px;
    padding-right: 30px;
    margin-bottom: 0px;
    padding-left: 12px;
  }
}
.store-exporer .esl-teacher-subtitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .store-exporer .esl-teacher-subtitle {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .store-exporer .esl-teacher-subtitle {
    text-align: right;
    padding-right: 30px;
    padding-left: 12px;
  }
}
.store-exporer .teacher-image-div {
  text-align: center;
}
.store-exporer .teacher-image-div img {
  padding-top: 5px;
  padding-right: 15px;
  padding-left: 15px;
  z-index: 1;
  position: relative;
}
@media (max-width: 768px) {
  .store-exporer .teacher-image-div img {
    max-width: 60%;
  }
}
.store-exporer .product-card {
  border-radius: 12px;
  margin-right: 12px;
  margin-left: 12px;
}
.store-exporer .product-card .card-body h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  color: #5C5858;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.store-exporer #store-products {
  /* Ensure the container has a height or min-height */
  min-height: 400px;
  /* or any suitable value */
  /* Use flex layout to center child elements */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* vertical centering */
  align-items: center;
  /* horizontal centering */
  padding-bottom: 12px;
}
.store-exporer .pagination {
  border-radius: 50px;
  /* Makes the entire pagination container rounded */
  overflow: hidden;
  /* Ensures child elements stay within the rounded edges */
  background-color: #0074E4;
  /* Adds a light background color for contrast */
}
.store-exporer .pagination .page-item {
  margin: 0 5px;
  /* Adds spacing between pagination items */
}
.store-exporer .pagination .page-item.active .page-link {
  background-color: #ff6700;
  /* Active page background color */
  color: #fff;
  /* Active page text color */
  border: none;
  /* Removes border for active state */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Adds a subtle shadow */
}
.store-exporer .pagination .page-item:not(.active):hover .page-link {
  background-color: #004a93;
  /* Hover background color */
  color: #fff;
  /* Hover text color */
  border-color: transparent;
  /* Removes border on hover */
}
.store-exporer .pagination .page-item .page-link {
  border-radius: 50%;
  /* Makes the individual pagination items circular */
  width: 30px;
  /* Sets width for circular shape */
  height: 30px;
  /* Sets height for circular shape */
  display: flex;
  /* Ensures proper alignment */
  align-items: center;
  /* Vertically centers the text */
  justify-content: center;
  /* Horizontally centers the text */
  border: 1px solid #004a93;
  /* Border color */
  color: #004a93;
  /* Default text color */
  transition: all 0.3s ease;
  /* Smooth transition for hover effects */
}
.banner h1 {
  font-size: 3rem;
}
.banner img {
  max-width: 100%;
  height: auto;
}
/* job explorer */
.explorer {
  background: #004a93;
}
.explorer .group-text {
  color: white;
}
.explorer .group-text h5 {
  font-size: 1rem;
  letter-spacing: 5px;
}
.explorer .group-text h3 strong {
  font-size: 3.5rem;
  color: white;
}
.explorer form .col-form {
  align-self: center;
}
.custom-shape-divider-bottom-explorer {
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(-180deg);
}
.custom-shape-divider-bottom-explorer svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(-180deg);
}
.custom-shape-divider-bottom-explorer .shape-fill {
  fill: #004a93;
}
.custom-shape-divider-top-explorer {
  top: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.custom-shape-divider-top-explorer svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(-180deg);
}
.custom-shape-divider-top-explorer .shape-fill {
  fill: #004a93;
}
.explorer {
  /* Carousel Buttons */
  /* Position the icons in the center of the buttons */
}
.explorer .carousel-control-prev,
.explorer .carousel-control-next {
  width: 50px;
  /* Width of the circle */
  height: 50px;
  /* Height of the circle */
  background-color: #ef3a43;
  /* Background color of the circle */
  border-radius: 50%;
  /* Makes it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  /* Stack icon and text vertically */
  padding: 0;
  /* Remove padding if any */
  text-decoration: none;
  /* Remove underline from hyperlink */
}
.explorer .carousel-control-prev i,
.explorer .carousel-control-next i {
  margin-bottom: -10px;
  /* Remove margin if any */
}
.explorer .carousel-control-text {
  margin-top: 0;
  /* Adjust this value to reduce space between icon and text */
  display: block;
  /* Ensure the text appears on a new line below the icon */
  margin-top: 10px;
  /* Adjust space between icon and text */
  color: white;
  /* Set text color */
  font-size: 12px;
  /* Set text size */
  text-align: center;
  /* Center the text below the icon */
}
.explorer .carousel-control-prev:hover,
.explorer .carousel-control-next:hover {
  background-color: #ef3a43;
  /* On hover, make it slightly more opaque */
}
.explorer .carousel-control-prev i,
.explorer .carousel-control-next i {
  font-size: 20px;
  /* Adjust the size of the arrow icons as needed */
  color: white;
}
.explorer ol {
  list-style-type: none;
  /* This hides the default numbers */
  counter-reset: li;
  /* Initialize a counter for the list */
}
.explorer .card {
  border-radius: 1.5rem;
  /* Adjust the pixel value as needed */
  overflow: hidden;
}
.explorer .card .img-fluid {
  border-radius: 1.5rem;
  /* Adjust this as needed */
}
.explorer .card overlay_over_image {
  border-radius: 1.5rem;
  /* Adjust this as needed */
}
.explorer .card img {
  width: 100%;
  /* Take the full width of the card. */
  height: 450px;
  /* Set a fixed height */
  -o-object-fit: cover;
     object-fit: cover;
  /* Crop and resize the image to fit the container while preserving its aspect ratio. */
}
.explorer label {
  color: white;
}
.join h3 {
  font-size: 3rem;
  padding: 0 6rem;
}
.join .col-text {
  height: 40rem;
}
.join .col-img {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.join .col-img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 40rem;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .join h3 {
    font-size: 2rem;
    padding: 0;
  }
  .join .col-text {
    height: 25rem;
  }
  .join .col-img img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 25rem;
  }
}
.join .col-img .play-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.join .col-img .play-icon-container:hover .play-icon {
  color: #ff0000 !important;
  /* Change color to red on hover */
}
.join .modal-fullscreen .modal-body {
  padding: 20px 0;
  /* Adding a little bit of padding to prevent the video from sticking to the top and bottom of the screen */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-fullscreen .modal-body video {
  max-height: calc(100vh - 150px);
  /* Calculating the viewport height and subtracting some pixels for the modal header and potential paddings */
  max-width: calc(100vh - 20px);
}
/**************************************** Carousel ***********************************************/
.discover .carousel-inner .carousel-item .card {
  margin: 0 0.5em;
  box-shadow: -2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
}
.discover .custom-card {
  transition: transform 0.3s, box-shadow 0.3s;
  /* Smooth transition */
}
.discover .custom-card:hover {
  transform: scale(1.05);
  /* Slightly scale the card up */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  /* Add a shadow for "lifting" effect */
}
.main h1 {
  font-size: 4rem;
}
.main p {
  font-size: 1rem;
}
.main img {
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 320px) and (max-width: 425px) {
  .main h1 {
    font-size: 3rem;
  }
  .main p {
    font-size: 1rem;
  }
}
.full-job {
  background: #F3F7F9;
  padding: 7rem 0;
}
.full-job h4 i {
  color: #ef3a43;
}
.full-job .hr-separator {
  margin-bottom: 3rem;
  border: 1px solid #003060;
  opacity: 1;
}
.full-job .col-acordion-two button {
  background: aliceblue;
  box-shadow: 0px 2px;
}
.full-job .accordion-button:after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-right: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  background-color: #86b7fe;
  border-radius: 5rem;
}
.full-job small {
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}
/* zoni awards */
.awards h1 {
  color: #004a93;
}
.awards .carousel-inner .carousel-item .img-1,
.awards .carousel-inner .carousel-item .img-2 {
  height: 45vh;
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  /* Alinea la imagen en el centro */
}
.awards .carousel-inner .carousel-item .img-2 {
  height: 30vh;
}
.awards .carousel-control-prev,
.awards .carousel-control-next {
  width: 50px;
  height: 50px;
  background-color: #ef3a43;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  padding: 0;
  text-decoration: none;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
@media screen and (max-width: 767px) {
  .awards .carousel-inner .carousel-item .img-1,
  .awards .carousel-inner .carousel-item .img-2 {
    height: 50vh;
  }
}
/* End zoni awards */
.tours-banner h1 {
  color: #004a93;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;
  z-index: 9999;
}
.tours-banner p {
  color: #004a93;
  font-size: 1.5rem;
  line-height: 1.5;
}
.tours-banner .card-title {
  color: #004a93;
  font-size: 1.5rem;
  line-height: 1.5;
}
.tours-banner .card-text {
  color: #004a93;
  font-size: 1rem;
  line-height: 1.5;
}
.tours-banner video {
  width: 100%;
  height: 100%;
}
.tours-banner .custom-card {
  background-color: #f3f7f9;
}
.intro {
  margin-top: 3rem;
}
.intro i {
  color: #ef3a43;
}
.intro .first-text {
  text-align: justify;
}
.intro .accordion .accordion-item .accordion-body p {
  text-align: justify;
}
.intro .accordion .accordion-item i {
  color: #FFD700;
}
.intro h2 {
  color: #004a93;
}
.team {
  background: #F3F7F9;
  padding: 7rem 0;
}
.team i {
  color: #ef3a43;
}
.team .card {
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 10px -5px #808080;
}
.team .card i {
  color: #FFD700;
}
.team h2 {
  color: #004a93;
}
.ready {
  padding: 7rem 0;
}
.ready i {
  color: #ef3a43;
}
.ready .nav-item i {
  color: #FFD700;
}
.ready .tab-content .tab-pane {
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 1rem;
}
.ready .tab-content #passports {
  border-color: #ef3a43;
}
.ready .tab-content #finance {
  border-color: #FFD700;
}
.ready .tab-content #keepingInTouch {
  border-color: #78981f;
}
.ready .tab-content #whatToPack {
  border-color: #0571d0;
}
.ready .tab-content #conduct {
  border-color: #004a93;
}
.ready .tab-content #photosVideos {
  border-color: #ff6700;
}
.expect {
  background: #FFD700;
  padding: 7rem 0;
}
.expect i {
  color: var(--color-quinario);
}
.expect .card {
  background: #f3f7f9;
  padding: 2rem;
  border: 1px solid #0571d0;
  border-radius: 0.5rem;
}
.expect .card p {
  text-align: justify;
}
.expect h2 {
  color: #004a93;
}
.planning {
  padding: 7rem 0;
}
.planning i {
  color: #ef3a43;
}
.planning .lead {
  text-align: justify;
}
.planning h2 {
  color: #004a93;
}
.planning .number-icon {
  display: inline-block;
  width: 23px;
  height: 23px;
  min-width: 23px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  background-color: #FFD700;
  /* Color of the circle */
  color: #0571d0;
  /* Color of the number */
  font-weight: bold;
  margin-left: 15px;
  /* Space between the icon and the text */
}
.safety {
  padding: 7rem 0;
  background: #F3F7F9;
}
.safety i {
  color: #ef3a43;
}
.safety .lead {
  text-align: justify;
}
.safety .accordion button i {
  color: #FFD700;
}
.safety h2 {
  color: #004a93;
}
.banner h1 strong {
  color: #004a93;
}
.banner .logo {
  text-align: center;
}
.banner .col-md-6 {
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .banner .logo {
    text-align: end;
  }
  .banner .col-md-6 {
    text-align: start;
  }
}
.sub {
  background: #1E569F;
  box-shadow: 0 10px #ef3a43;
  position: relative;
}
.sub .col-img {
  position: absolute;
  bottom: -10px;
  text-align: center;
  padding: 0;
}
@media screen and (min-width: 1024px) {
  .sub .col-img {
    position: absolute;
    bottom: -43px;
    text-align: center;
    padding: 0;
  }
}
.sub .col-img img {
  position: relative;
  max-width: 100%;
  height: auto;
  width: auto;
}
.sub .text {
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
}
.sub2 .custom-shape-divider-top-1701376353 {
  top: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.sub2 .custom-shape-divider-top-1701376353 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}
.sub2 .custom-shape-divider-top-1701376353 .shape-fill {
  fill: #134796;
}
.about-us p {
  color: #00529B;
  line-height: 1.2;
  text-align: justify;
}
.about-us .timeline {
  position: relative;
  margin: 0px auto;
}
.about-us .timeline .spacing-top {
  padding-top: 5rem;
}
.about-us .timeline .spacing-bottom {
  padding-bottom: 5rem;
}
.about-us .timeline::before {
  content: '';
  position: absolute;
  right: 50%;
  width: 4px;
  height: 100%;
  background: #004A93;
}
.about-us .timeline ul {
  margin: 0;
  padding: 0;
}
.about-us .timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  margin: 3rem 0;
  padding: 20px 40px;
  box-sizing: border-box;
}
.about-us .timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}
.about-us .timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}
.about-us .timeline ul li .content {
  padding: 0 2rem;
}
.about-us .timeline ul li .content:before {
  content: "";
  width: 85%;
  height: 97%;
  border: 3px solid #EF3E42;
  border-top: none;
  border-left: none;
  position: absolute;
  bottom: 0px;
  right: 35px;
}
.about-us .timeline ul li .content:after {
  content: "";
  width: 37px;
  height: 3px;
  background: #EF3E42;
  position: absolute;
  top: 13px;
  right: 0;
}
.about-us .timeline ul li:nth-child(2n) .content:before {
  border-right-color: #004a93;
  border-bottom-color: #004a93;
}
.about-us .timeline ul li:nth-child(even) .content:before {
  right: auto;
  left: 35px;
  transform: rotateY(-180deg);
}
.about-us .timeline ul li:nth-child(2n) .content:after {
  background: #004A93;
}
.about-us .timeline ul li:nth-child(even) .content:after {
  right: auto;
  left: 0px;
}
.about-us .timeline ul li:nth-child(odd):before {
  content: '';
  position: absolute;
  top: 7px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: #EF3E42;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #fff;
  z-index: 999;
}
.about-us .timeline ul li:nth-child(even):before {
  content: '';
  position: absolute;
  top: 8px;
  left: -10px;
  width: 15px;
  height: 15px;
  background: #004A93;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #fff;
  z-index: 999;
}
.about-us .timeline ul li .title-1 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #EF3E42;
}
.about-us .timeline ul li .title-2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #004A93;
  text-align: right;
}
.about-us .timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
}
.about-us .timeline ul li .img-1 img {
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .about-us .timeline ul li:nth-child(odd) .logo {
    position: absolute;
    top: 0;
    right: -350px;
    margin: 0;
    padding: 8px 16px;
    max-width: 100%;
    height: auto;
    width: 7rem;
  }
  .about-us .timeline ul li:nth-child(even) .logo {
    position: absolute;
    top: 0;
    left: -205px;
    margin: 0;
    padding: 8px 16px;
    max-width: 100%;
    height: auto;
    width: 7rem;
  }
}
@media screen and (max-width: 1000px) {
  .about-us .timeline {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .about-us .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  .about-us .timeline:before {
    right: 20px;
  }
  .about-us .content {
    padding-bottom: 4rem;
  }
  .about-us .timeline ul li:nth-child(even) .content:before {
    top: -8px;
    right: auto;
    left: 29px;
    transform: rotateY(0deg);
  }
  .about-us .timeline ul li:nth-child(even) .content:after {
    left: auto;
    right: 0px;
  }
  .about-us .timeline ul li:nth-child(odd),
  .about-us .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: right;
    padding-right: 50px;
  }
  .about-us .timeline ul li:nth-child(odd):before,
  .about-us .timeline ul li:nth-child(even):before {
    top: -18px;
    right: 14px;
  }
  .about-us .timeline ul li:nth-child(odd) .time,
  .about-us .timeline ul li:nth-child(even) .time {
    top: -30px;
    right: 50px;
    left: inherit;
  }
  .about-us .timeline ul li .content:before {
    content: "";
    width: 85%;
    height: 105%;
    border: 3px solid #EF3E42;
    border-top: none;
    border-left: none;
    position: absolute;
    bottom: 0px;
    right: 35px;
    margin-right: 15px;
  }
  .about-us .timeline ul li .content:after {
    content: "";
    width: 37px;
    height: 3px;
    background: #EF3E42;
    position: absolute;
    top: -11px;
    right: 0;
    margin-right: 15px;
  }
  .about-us .timeline ul li:nth-child(odd) .logo,
  .about-us .timeline ul li:nth-child(even) .logo {
    margin: 0;
    padding: 15px 35px;
    max-width: 100%;
    height: auto;
    width: 7rem;
  }
}
@media screen and (max-width: 425px) {
  .about-us .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  .about-us .timeline:before {
    right: 20px;
  }
  .about-us .content {
    padding-bottom: 4rem;
  }
  .about-us .timeline ul li:nth-child(even) .content:before {
    top: -8px;
    right: auto;
    left: 11px;
    transform: rotateY(0deg);
  }
  .about-us .timeline ul li:nth-child(even) .content:after {
    left: auto;
    right: 0px;
  }
  .about-us .timeline ul li:nth-child(odd),
  .about-us .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: right;
    padding-right: 50px;
  }
  .about-us .timeline ul li:nth-child(odd):before,
  .about-us .timeline ul li:nth-child(even):before {
    top: -18px;
    right: 14px;
  }
  .about-us .timeline ul li:nth-child(odd) .time,
  .about-us .timeline ul li:nth-child(even) .time {
    top: -30px;
    right: 50px;
    left: inherit;
  }
  .about-us .timeline ul li .content:before {
    content: "";
    width: 85%;
    height: 105%;
    border: 3px solid #EF3E42;
    border-top: none;
    border-left: none;
    position: absolute;
    bottom: 0px;
    right: 35px;
    margin-right: 15px;
  }
  .about-us .timeline ul li .content:after {
    content: "";
    width: 37px;
    height: 3px;
    background: #EF3E42;
    position: absolute;
    top: -11px;
    right: 0;
    margin-right: 15px;
  }
  .about-us .timeline ul li:nth-child(odd) .logo,
  .about-us .timeline ul li:nth-child(even) .logo {
    margin: 0;
    padding: 15px 35px;
    max-width: 100%;
    height: auto;
    width: 7rem;
  }
}
@media screen and (max-width: 375px) {
  .about-us .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  .about-us .timeline:before {
    right: 20px;
  }
  .about-us .content {
    padding-bottom: 4rem;
  }
  .about-us .timeline ul li:nth-child(even) .content:before {
    top: -8px;
    right: auto;
    left: 3px;
    transform: rotateY(0deg);
  }
  .about-us .timeline ul li:nth-child(even) .content:after {
    left: auto;
    right: 0px;
  }
  .about-us .timeline ul li:nth-child(odd),
  .about-us .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: right;
    padding-right: 50px;
  }
  .about-us .timeline ul li:nth-child(odd):before,
  .about-us .timeline ul li:nth-child(even):before {
    top: -18px;
    right: 14px;
  }
  .about-us .timeline ul li:nth-child(odd) .time,
  .about-us .timeline ul li:nth-child(even) .time {
    top: -30px;
    right: 50px;
    left: inherit;
  }
  .about-us .timeline ul li .content:before {
    content: "";
    width: 75%;
    height: 103%;
    border: 3px solid #EF3E42;
    border-top: none;
    border-left: none;
    position: absolute;
    bottom: 0px;
    right: 35px;
    margin-right: 15px;
  }
  .about-us .timeline ul li .content:after {
    content: "";
    width: 37px;
    height: 3px;
    background: #EF3E42;
    position: absolute;
    top: -11px;
    right: 0;
    margin-right: 15px;
  }
  .about-us .timeline ul li:nth-child(odd) .logo,
  .about-us .timeline ul li:nth-child(even) .logo {
    margin: 0;
    padding: 15px 35px;
    max-width: 100%;
    height: auto;
    width: 7rem;
  }
}
@media screen and (max-width: 375px) {
  .about-us .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  .about-us .timeline:before {
    right: 20px;
  }
  .about-us .content {
    padding-bottom: 4rem;
  }
  .about-us .timeline ul li:nth-child(even) .content:before {
    top: -8px;
    right: auto;
    left: -5px;
    transform: rotateY(0deg);
  }
  .about-us .timeline ul li:nth-child(even) .content:after {
    left: auto;
    right: 0px;
  }
  .about-us .timeline ul li:nth-child(odd),
  .about-us .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: right;
    padding-right: 50px;
  }
  .about-us .timeline ul li:nth-child(odd):before,
  .about-us .timeline ul li:nth-child(even):before {
    top: -18px;
    right: 14px;
  }
  .about-us .timeline ul li:nth-child(odd) .time,
  .about-us .timeline ul li:nth-child(even) .time {
    top: -30px;
    right: 50px;
    left: inherit;
  }
  .about-us .timeline ul li .content:before {
    content: "";
    width: 85%;
    height: 105%;
    border: 3px solid #EF3E42;
    border-top: none;
    border-left: none;
    position: absolute;
    bottom: 0px;
    right: 35px;
    margin-right: 15px;
  }
  .about-us .timeline ul li .content:after {
    content: "";
    width: 37px;
    height: 3px;
    background: #EF3E42;
    position: absolute;
    top: -11px;
    right: 0;
    margin-right: 15px;
  }
  .about-us .timeline ul li:nth-child(odd) .logo,
  .about-us .timeline ul li:nth-child(even) .logo {
    margin: 0;
    padding: 15px 35px;
    max-width: 100%;
    height: auto;
    width: 7rem;
  }
}
.about-us .timeline ul li .content a {
  color: #EF3E42;
  position: relative;
  pointer-events: auto;
  z-index: 10;
  /* Increase this value if needed */
}
.goals {
  background: #004a93;
  padding-bottom: 4rem;
  margin-top: 5rem;
}
@media screen and (min-width: 1023px) {
  .goals .container-fluid {
    padding: 0 10rem;
  }
}
.goals .icon-layer {
  position: relative;
  bottom: 5rem;
  text-align: center;
}
.goals .card {
  background: none;
  text-align: center;
  color: white;
}
.goals .card .card-header {
  border-bottom: none;
}
.goals .card .card-footer {
  border-top: none;
}
.goals .card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.goals .card .card-body h2,
.goals .card .card-body h5,
.goals .card .card-body p {
  margin: 0;
}
.goals .card .card-body h5 {
  font-weight: bold;
}
.goals-recommendation {
  background-color: #F6FAFF;
}
.goals-recommendation p span {
  text-decoration: underline;
}
.goals-recommendation p {
  color: #1C5499;
}
.goals-recommendation .badge img {
  width: 75%;
}
.kids_banner .col-12 {
  padding: 0;
}
.kids_banner .mov {
  position: relative;
}
.kids_banner .bg {
  position: relative;
}
.kids_banner .mov video {
  max-width: 100%;
  height: auto;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .kids_banner .mov .paper {
    width: 100%;
    height: 97%;
    position: absolute;
    z-index: 998;
  }
  .kids_banner .mov .buttons {
    position: absolute;
    left: 15%;
    top: 25%;
    display: grid;
    z-index: 999;
  }
}
@media screen and (min-width: 1024px) {
  .kids_banner .mov .paper {
    width: 100%;
    /* Cambiado de max-width a width */
    height: 99%;
    /* Cambiado de auto a 100% */
    position: absolute;
    z-index: 998;
  }
  .kids_banner .mov .buttons {
    position: absolute;
    left: 20%;
    top: 50%;
    display: grid;
    z-index: 999;
  }
}
.kids_banner .mov .buttons .btn-1 {
  padding: 0.5rem 4rem;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  background: none;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}
.kids_banner .mov .buttons .btn-1 i {
  font-size: 2rem;
}
@media screen and (min-width: 1024px) {
  .kids_banner .mov .buttons .btn-1 p {
    font-size: 27px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1023px) {
  .kids_banner .mov .buttons .btn-1 p {
    font-size: 18px;
    margin-bottom: 0;
  }
}
.kids_banner .mov .buttons .btn-1 p:hover {
  color: white;
  -webkit-text-decoration: underline white;
          text-decoration: underline white;
}
.kids_banner .mov .buttons .btn-sign-in {
  text-align: center;
}
.kids_banner .mov .buttons .btn-2 {
  padding: 0.5rem 3rem;
  background: #FFD700;
  color: #004a93;
  font-weight: 700;
  font-size: 12px;
  border: none;
  border-radius: 5rem;
  transition: all 0.3s ease;
}
.kids_banner .mov .buttons .btn-2:hover {
  padding: 0.5rem 3rem;
  background: none;
  color: #FFD700;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #FFD700;
  border-radius: 5rem;
  transition: all 300ms ease;
}
.kids_banner .modal video {
  max-width: 100%;
  height: auto;
}
.why {
  padding-bottom: 5rem;
}
.why .col-12 h2 {
  color: #2C49A1;
}
.why .col-12 p {
  color: #0571d0;
  text-align: center;
}
/* Carousel */
.why .carousel-inner {
  padding: 1em;
}
.why .card {
  margin: 0 0.5em;
  border: none;
  background: #F1FBFF;
  border-radius: 10px;
  position: relative;
  width: 90%;
  height: 350px;
  margin: 20px;
}
.why .carousel-control-prev,
.why .carousel-control-next {
  background-color: #e1e1e1;
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.why .card .img-wrapper {
  max-width: 100%;
  height: 13em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.why .card img {
  max-height: 100%;
}
.why .card .card-footer .fa-circle-chevron-right {
  display: none;
}
@media (max-width: 767px) {
  .why .card .img-wrapper {
    height: 17em;
  }
}
/* End Carousel */
.why .carousel-inner .card-header {
  align-self: center;
  background: none;
  border-bottom: none;
  padding-top: 2rem;
  display: inline-block;
}
.why .carousel-inner .card-header .bg-img {
  border-radius: 64% 36% 62% 38%/36% 47% 53% 64%;
  border: 8px solid #0056BD;
  height: 15rem;
  width: 15rem;
  overflow: hidden;
  position: relative;
}
.why .carousel-inner .card-header .bg-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
}
.why .carousel-inner .card-body {
  flex: 0 0 auto !important;
}
.why .carousel-inner .card-body .card-title {
  color: #0056C0;
  font-size: x-large;
}
.why .carousel-inner .card-footer {
  background: none;
  border-top: none;
  padding-bottom: 2rem;
}
.why .carousel-inner .card-footer i {
  font-size: 2rem;
  color: #0571d0;
}
.why .card:hover h5.card-title {
  color: #fff;
  /* Cambia el color según tus preferencias */
}
/* Shape divider */
@media screen and (min-width: 1024px) {
  .custom-shape-divider-bottom-1702417008 {
    position: relative;
    bottom: 9.9rem;
    right: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(-180deg);
    z-index: 1000 !important;
  }
  .custom-shape-divider-bottom-1702417008 svg {
    position: relative;
    display: block;
    width: calc(104% + 1.3px);
    height: 153px;
    transform: rotateY(-180deg);
  }
  .custom-shape-divider-bottom-1702417008 .shape-fill {
    fill: #FFFFFF !important;
  }
}
@media screen and (max-width: 1023px) {
  .custom-shape-divider-bottom-1702417008 {
    position: relative;
    bottom: 3rem;
    top: auto;
    right: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(-180deg);
    z-index: 1000 !important;
  }
  .custom-shape-divider-bottom-1702417008 svg {
    position: relative;
    display: block;
    width: calc(104% + 1.3px);
    height: 41px;
    transform: rotateY(-180deg);
  }
  .custom-shape-divider-bottom-1702417008 .shape-fill {
    fill: #FFFFFF !important;
  }
}
/* End Shape divider */
.why .carousel-inner .card .face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.5s;
}
.why .carousel-inner .card .front {
  transform: perspective(600px) rotateY(0deg);
  box-shadow: -2px 6px 8px #003060;
  text-align: center;
}
.why .carousel-inner .card .front:hover {
  background: linear-gradient(0deg, #9A00C0, #0056C0);
  box-shadow: 0 12px 20px -10px rgba(0, 48, 96, 0.6);
}
.why .carousel-inner .card .back {
  transform: perspective(600px) rotateY(-180deg);
  background: linear-gradient(0deg, #9A00C0, #0056C0);
  padding: 15px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  box-shadow: -2px 6px 8px #003060;
}
.why .carousel-inner .card .back h3 {
  font-size: 30px;
  margin-top: 20px;
  letter-spacing: 1px;
  line-height: 1;
}
.why .carousel-inner .card .back p {
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 0 1rem;
}
.why .carousel-inner .card:hover .front {
  transform: perspective(600px) rotateY(-180deg);
}
.why .carousel-inner .card:hover .back {
  transform: perspective(600px) rotateY(-360deg);
}
.mission {
  background: #F1FBFF;
  position: relative;
  min-height: 530px;
  padding-top: 6rem;
}
.mission .paint {
  position: absolute;
  left: 5rem;
  bottom: 30rem;
  z-index: 1000 !important;
  transition: all 0.5s ease;
}
.mission .paint-container {
  transition: all 0.5s ease;
}
.mission .paint-container .paint-item {
  transition: all 0.5s ease;
}
.mission .paint-container:hover .paint-item {
  animation: paint-animation 8s ease-in-out;
}
@keyframes paint-animation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(65rem);
  }
  100% {
    transform: translateX(0);
  }
}
.mission .logo img {
  max-width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .mission {
    padding-top: 16px;
    min-height: 710px;
  }
  .mission .logo {
    text-align: center;
  }
  .mission .logo img {
    max-width: 100%;
    height: auto;
    width: 7rem;
    margin-bottom: 3rem;
  }
  .mission .logo .map,
  .mission .logo .indicator {
    max-width: 100%;
    height: auto;
    width: 12rem;
    margin-bottom: 3rem;
  }
  .mission .paint {
    display: none;
  }
}
.mission .item {
  position: relative;
  text-align: center;
}
.mission .item img {
  max-width: 100%;
  height: 25rem;
  width: 45rem;
}
.mission .item .text {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-width: 400px;
}
.mission .item .text .mode {
  position: absolute;
  max-width: 100%;
  height: auto;
  width: 5rem;
  right: -12rem;
  top: -6rem;
}
.mission .item .text h3 strong {
  color: #2C49A1;
  margin-bottom: 10px;
}
.mission .item .text p {
  color: #0571d0;
  font-size: 12px;
}
@media screen and (min-width: 1024px) {
  .mission .carousel-control-next-icon {
    position: relative;
    left: -10rem;
    z-index: 100;
  }
  .mission .carousel-control-prev-icon {
    position: relative;
    right: -10rem;
    z-index: 100;
  }
}
.awards .cont-fluid-1 {
  background: linear-gradient(0deg, #631FB6, #0056c0);
  padding: 4rem 0;
  border-radius: 0 0 4rem 4rem;
  position: relative;
}
@media screen and (max-width: 1919px) {
  .awards .frame {
    display: none;
  }
}
@media screen and (min-width: 1920px) {
  .awards .frame {
    position: absolute;
    right: 8rem;
    max-width: 100%;
    width: 8rem;
  }
}
.awards .cloud {
  position: absolute;
  left: 8rem;
  top: -4rem;
  max-width: 100%;
  width: 12rem;
}
.awards .card {
  background: none;
  border: none;
  color: white;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .awards .card {
    padding: 2rem 0;
  }
  .awards .cloud {
    visibility: hidden;
  }
}
.awards .card .card-header {
  border-bottom: none;
}
.awards .card .card-header img {
  max-width: 100%;
  height: 2rem;
}
.awards .card .card-body {
  padding-bottom: 0;
}
.awards .card .card-footer {
  border-top: none;
  padding-top: 0;
  align-self: center;
}
.awards .card .card-footer p {
  margin-bottom: 0;
  color: white;
}
.awards .cont-2 h2 {
  font-weight: bold;
  color: #2C49A1;
}
@media screen and (max-width: 1023px) {
  .awards .cont-2 .medal {
    max-width: 100%;
    height: auto;
    width: 5rem;
  }
}
.awards .cont-2 .logos {
  padding: 5rem 0;
}
@media screen and (min-width: 1024px) {
  .awards .cont-2 .logos .img-1 {
    width: 12rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-2 {
    width: 12rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-3 {
    width: 10rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-4 {
    width: 14rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-5 {
    width: 9rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-6 {
    width: 18rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-7 {
    width: 10rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
}
@media screen and (max-width: 1023px) {
  .awards .cont-2 .logos .img-1 {
    width: 8rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-2 {
    width: 8rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-3 {
    width: 8rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-4 {
    width: 11rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-5 {
    width: 7rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-6 {
    width: 12rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .awards .cont-2 .logos .img-7 {
    width: 8rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
}
.awards .mov {
  position: relative;
}
.awards .mov video {
  max-width: 100%;
  height: auto;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .awards .mov .paper {
    width: 100%;
    height: 97%;
    position: absolute;
    z-index: 996;
  }
  .awards .mov .buttons {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    display: grid;
    z-index: 997;
  }
}
@media screen and (min-width: 1024px) {
  .awards .mov .paper {
    width: 100%;
    /* Cambiado de max-width a width */
    height: 99%;
    /* Cambiado de auto a 100% */
    position: absolute;
    z-index: 996;
  }
  .awards .mov .buttons {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    display: grid;
    z-index: 997;
  }
}
.awards .mov .buttons .btn-1 {
  padding: 0.5rem 4rem;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  background: none;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}
.awards .mov .buttons .btn-1 i {
  font-size: 2rem;
}
@media screen and (min-width: 1024px) {
  .awards .mov .buttons .btn-1 p {
    font-size: 27px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1023px) {
  .awards .mov .buttons .btn-1 p {
    font-size: 18px;
    margin-bottom: 0;
  }
}
.awards .mov .buttons .btn-1 p:hover {
  color: white;
  -webkit-text-decoration: underline white;
          text-decoration: underline white;
}
.awards .mov .buttons .btn-sign-in {
  text-align: center;
}
.awards .mov .buttons .btn-2 {
  padding: 0.5rem 3rem;
  background: #FFD700;
  color: #004a93;
  font-weight: 700;
  font-size: 12px;
  border: none;
  border-radius: 5rem;
  transition: all 0.3s ease;
}
.awards .mov .buttons .btn-2:hover {
  padding: 0.5rem 3rem;
  background: none;
  color: #FFD700;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #FFD700;
  border-radius: 5rem;
  transition: all 300ms ease;
}
.awards .modal video {
  max-width: 100%;
  height: auto;
}
.testimonials-section {
  background-color: #f1fbff;
  position: relative;
  margin-top: -7px;
}
.testimonials-section .cloud-1 {
  position: absolute;
  right: -185px;
  top: 5px;
  max-width: 100%;
  height: auto;
}
.testimonials-section .container-fluid {
  padding: 7rem 0;
}
.testimonials-section .container-fluid .title {
  margin-bottom: 3rem;
  color: #2C49A1;
}
.testimonials-section .container-fluid .testimonials {
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.testimonials-section .container-fluid .testimonials .testimonial {
  box-shadow: -2px 6px 8px #003060;
  border: none;
  background: white;
  border-radius: 2rem;
  padding: 2rem 2rem 1rem 2rem;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-header {
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: none;
  background: none;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-header .date {
  color: #004a93;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32.4px;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-header .desc {
  color: black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  height: 18rem;
  padding-bottom: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-body {
  padding: 0 !important;
  margin: 0 !important;
  max-height: 7rem !important;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-body .circle {
  border-radius: 50%;
  background: red;
  height: 5rem;
  width: 5rem;
  overflow: hidden;
  position: relative;
  margin: auto !important;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-body .circle img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-footer {
  border-top: none;
  background: none;
  padding: 0px !important;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-footer .name {
  color: black;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.64px;
  margin-bottom: 0px;
}
.testimonials-section .container-fluid .testimonials .testimonial .card-footer .parent {
  color: black;
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.testimonials-section .container-fluid .left-icon {
  position: absolute;
  right: 40px;
  top: 40%;
}
.testimonials-section .container-fluid .left-icon .left-icon-img {
  width: 40px;
}
.testimonials-section .container-fluid .right-icon {
  position: absolute;
  left: 10px;
  top: 40%;
}
.testimonials-section .container-fluid .right-icon .right-icon-img {
  width: 40px;
}
.testimonials-section .container-fluid .testimonials::-webkit-scrollbar {
  display: none;
}
.testimonials-section .custom-shape-divider-top {
  top: -72px;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(-180deg);
  position: relative;
  z-index: 997;
}
.testimonials-section .custom-shape-divider-top .svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 72px;
  transform: rotateY(-180deg);
}
.testimonials-section .custom-shape-divider-top .svg .shape-fill {
  fill: #f1fbff;
}
.testimonials-section .custom-shape-divider-bottom {
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(-180deg);
  position: relative;
  z-index: 0;
}
.testimonials-section .custom-shape-divider-bottom .svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}
.testimonials-section .custom-shape-divider-bottom .svg .shape-fill {
  fill: #ffffff;
}
@media only screen and (max-width: 400px) {
  .testimonials-section .left-icon {
    top: 50% !important;
  }
  .testimonials-section .right-icon {
    top: 50% !important;
  }
  .testimonials-section .card-header {
    min-height: 17rem !important;
  }
}
@media only screen and (min-width: 401px) and (max-width: 576px) {
  .testimonials-section .left-icon {
    top: 50% !important;
  }
  .testimonials-section .right-icon {
    top: 50% !important;
  }
  .testimonials-section .card-header {
    min-height: 15.5rem !important;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .testimonials-section .card-header {
    min-height: 13rem !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .testimonials-section .card-header {
    min-height: 14rem !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .testimonials-section .card-header {
    min-height: 15rem !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .testimonials-section .card-header {
    min-height: 17rem !important;
  }
}
@media only screen and (min-width: 1400px) {
  .testimonials-section .card-header {
    min-height: 14rem !important;
  }
}
.prices {
  margin-top: 1rem;
}
.prices .container {
  position: relative;
}
@media screen and (max-width: 1919px) {
  .prices .container-contract {
    display: none !important;
  }
}
.prices .container-contract {
  background: url(/assets/zoni_edu/assets/img/kids/prices/img_icon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  left: -75%;
  top: -8rem;
  width: 198px;
  z-index: 10000;
  height: 198px;
}
.prices .container-contract .text-1 {
  max-width: 160px;
  color: #9402b0;
  text-align: center;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.563rem;
}
.prices .container-contract .text-2 {
  max-width: 150px;
  color: #0439DB;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}
.prices .title h2 {
  color: #2C49A1;
}
.prices .title h5 {
  color: #0571d0;
}
.prices .title .btn-options .btn-1,
.prices .title .btn-options .btn-2 {
  border: 1px solid #004a93;
  border-radius: 5rem;
  background: none;
  color: #004a93;
  padding: 1rem 4rem;
  transition: all 500ms ease;
}
.prices .title .btn-options .active,
.prices .title .btn-options .active {
  border: 1px solid #004a93;
  border-radius: 5rem;
  background: #004a93;
  color: #fff;
  padding: 1rem 4rem;
  transition: all 500ms ease;
}
.prices .title .btn-options .btn-1:hover,
.prices .title .btn-options .btn-2:hover {
  border: 1px solid #004a93;
  border-radius: 5rem;
  background: #004a93;
  color: #fff;
  padding: 1rem 4rem;
  transition: all 500ms ease;
}
.prices .title .btn-options .btn-1:focus,
.prices .title .btn-options .btn-2:focus {
  outline: none;
  border: 1px solid #004a93;
  border-radius: 5rem;
  background: none;
  color: #004a93;
  padding: 1rem 4rem;
  transition: all 500ms ease;
}
.prices .title .btn-options .btn-1:focus {
  background: #004a93;
  color: #fff;
}
.prices .title .btn-options .btn-2:focus {
  background: #004a93;
  color: #fff;
}
.prices .title .btn-options .btn-3 {
  border: 1px solid #5dcdf0;
  border-radius: 5rem;
  background: none;
  color: #5dcdf0;
  padding: 1rem 4rem;
  transition: all 500ms ease;
}
.prices .title .btn-options .btn-3:hover {
  border: 1px solid #5dcdf0;
  border-radius: 5rem;
  background: #5dcdf0;
  color: white;
  padding: 1rem 4rem;
  transition: all 500ms ease;
}
/* Carousel */
.prices .carousel-inner {
  padding: 5rem 0;
  height: 50%;
}
.prices .card {
  margin: 0 0.5em;
  box-shadow: -2px 6px 8px #6c757d;
  border: none;
  background: linear-gradient(0deg, #631fb6, #0056c0);
  border-radius: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.prices .card .card-header {
  border-bottom: none;
}
.prices .card .card-header .number-label-container {
  max-height: 3.6rem;
}
.prices .card .card-header .private-label-container {
  max-height: 1rem;
}
.prices .card .card-header .number-label-container .number-label {
  color: #ffffff;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.prices .card .card-header .private-label-container .private-label {
  color: #ffffff;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.prices .card .card-header .lessons-label-container .lessons-label {
  color: #ffffff;
  font-size: 1.09rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.prices .card .card-header .live {
  display: inline-flex;
  color: white;
  align-items: center;
  font-size: 0.875rem;
}
.prices .card .card-header .live img {
  max-width: 100%;
  padding-left: 1rem;
}
.prices .card .card-body {
  background: #fff;
  margin: 1rem;
  border-radius: 1rem;
}
.prices .card .card-body .info h4 {
  font-size: 1rem;
  margin-bottom: 0;
}
.prices .card .card-body .info p {
  font-size: 0.8rem;
}
.prices .card .card-body .info .btn-1 {
  border: 2px solid #8300C0;
  border-radius: 5rem;
  background: none;
  color: #8300C0;
  padding: 0.2rem 2rem;
  transition: all 0.5s ease;
}
.prices .card .card-body .info .btn-1:hover {
  border: 2px solid #8300C0;
  border-radius: 5rem;
  background: #8300C0;
  color: #fff;
  padding: 0.2rem 2rem;
  transition: all 0.5s ease;
}
.prices .card .card-body .info .btn-2 {
  border: 2px solid #00DD59;
  border-radius: 5rem;
  background: none;
  color: #00DD59;
  padding: 0.2rem 2rem;
  transition: all 0.5s ease;
}
.prices .card .card-body .info .btn-2:hover {
  border: 2px solid #00DD59;
  border-radius: 5rem;
  background: #00DD59;
  color: #fff;
  padding: 0.2rem 2rem;
  transition: all 0.5s ease;
}
.prices .card .card-body .info .btn-4 {
  border: 2px solid #5dcdf0;
  border-radius: 5rem;
  background: none;
  color: #5dcdf0;
  padding: 0.2rem 2rem;
  transition: all 0.5s ease;
}
.prices .card .card-body .info .btn-4:hover {
  border: 2px solid #5dcdf0;
  border-radius: 5rem;
  background: #5dcdf0;
  color: #fff;
  padding: 0.2rem 2rem;
  transition: all 0.5s ease;
}
.prices .card .card-body .info .btn-1 .row {
  align-items: center;
}
.prices .card .card-body .info .btn-1 .row p strong {
  font-size: 0.9rem;
}
.prices .card .card-body .info .btn-2 .row {
  align-items: center;
}
.prices .card .card-body .info .btn-2 .row p strong {
  font-size: 0.9rem;
}
.prices .card .card-body .info .btn-4 .row {
  align-items: center;
}
.prices .card .card-body .info .btn-4 .row p strong {
  font-size: 0.9rem;
}
.prices .card .card-body .info .save {
  text-align: justify;
}
.prices .card .card-body .info .save p {
  margin-bottom: 0;
  padding-right: 1rem;
  font-weight: 700;
  color: red;
}
.prices .card .card-footer {
  border-top: none;
}
.prices .card .card-footer .btn-1 {
  border: 2px solid #FFAD29;
  border-radius: 0.7rem;
  background: #FFAD29;
  color: #fff;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .card .card-footer .btn-1:hover {
  border: 2px solid #FFAD29;
  border-radius: 0.7rem;
  background: none;
  color: #FFAD29;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .card .card-footer .btn-2 {
  border: 2px solid #00DD59;
  border-radius: 0.7rem;
  background: #00DD59;
  color: #fff;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .card .card-footer .btn-2:hover {
  border: 2px solid #00DD59;
  border-radius: 0.7rem;
  background: none;
  color: #00DD59;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .card .card-footer .btn-3 {
  border: 2px solid #b704f5;
  border-radius: 0.7rem;
  background: #b704f5;
  color: #fff;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .card .card-footer .btn-3:hover {
  border: 2px solid #b704f5;
  border-radius: 0.7rem;
  background: #fff;
  color: #b704f5;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .card .card-footer .btn-4 {
  border: 2px solid #5dcdf0;
  border-radius: 0.7rem;
  background: #5dcdf0;
  color: #fff;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .card .card-footer .btn-4:hover {
  border: 2px solid #5dcdf0;
  border-radius: 0.7rem;
  background: #fff;
  color: #5dcdf0;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.prices .carousel-control-prev,
.prices .carousel-control-next {
  background-color: #e1e1e1;
  width: 6vh;
  height: 6vh;
  border-radius: 50%;
  top: 60%;
}
.prices .card .img-wrapper {
  max-width: 100%;
  height: 13em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prices .card img {
  max-height: 100%;
}
@media (max-width: 767px) {
  .prices .card .img-wrapper {
    height: 17em;
  }
}
/* End Carousel */
#carouselExampleControls4 {
  display: none;
}
.teacher-section {
  background-color: #f1fbff;
  position: relative;
  margin-top: -7px;
}
.teacher-section .container-fluid {
  padding: 7rem 0;
}
.teacher-section .container-fluid .title {
  margin-bottom: 3rem;
  color: #2C49A1;
}
.teacher-section .container-fluid .teachers {
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.teacher-section .container-fluid .teachers .teacher {
  box-shadow: -2px 6px 8px #003060;
  border: none;
  background: white;
  border-radius: 2rem;
  padding: 2rem 2rem 1rem 2rem;
}
.teacher-section .container-fluid .teachers .teacher .card-header {
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: none;
  background: none;
}
.teacher-section .container-fluid .teachers .teacher .card-header .date {
  color: #004a93;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32.4px;
}
.teacher-section .container-fluid .teachers .teacher .card-header .desc {
  color: black;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  height: 18rem;
  padding-bottom: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;
}
.teacher-section .container-fluid .teachers .teacher .card-body {
  padding: 0 !important;
  margin: 0 !important;
  max-height: 7rem !important;
}
.teacher-section .container-fluid .teachers .teacher .card-body .circle {
  border-radius: 50%;
  background: red;
  height: 5rem;
  width: 5rem;
  overflow: hidden;
  position: relative;
  margin: auto !important;
}
.teacher-section .container-fluid .teachers .teacher .card-body .circle img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
}
.teacher-section .container-fluid .teachers .teacher .card-footer {
  border-top: none;
  background: none;
  padding: 0px !important;
}
.teacher-section .container-fluid .teachers .teacher .card-footer .name {
  color: black;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.64px;
  margin-bottom: 0px;
}
.teacher-section .container-fluid .teachers .teacher .card-footer .parent {
  color: black;
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.teacher-section .container-fluid .left-icon {
  position: absolute;
  right: 40px;
  top: 40%;
}
.teacher-section .container-fluid .left-icon .left-icon-img {
  width: 40px;
}
.teacher-section .container-fluid .right-icon {
  position: absolute;
  left: 10px;
  top: 40%;
}
.teacher-section .container-fluid .right-icon .right-icon-img {
  width: 40px;
}
.teacher-section .container-fluid .teachers::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 400px) {
  .teacher-section .left-icon {
    top: 50% !important;
  }
  .teacher-section .right-icon {
    top: 50% !important;
  }
  .teacher-section .card-header {
    min-height: 17rem !important;
  }
}
@media only screen and (min-width: 401px) and (max-width: 576px) {
  .teacher-section .left-icon {
    top: 50% !important;
  }
  .teacher-section .right-icon {
    top: 50% !important;
  }
  .teacher-section .card-header {
    min-height: 15.5rem !important;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .teacher-section .card-header {
    min-height: 13rem !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .teacher-section .card-header {
    min-height: 14rem !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .teacher-section .card-header {
    min-height: 15rem !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .teacher-section .card-header {
    min-height: 17rem !important;
  }
}
@media only screen and (min-width: 1400px) {
  .teacher-section .card-header {
    min-height: 14rem !important;
  }
}
.place {
  padding: 7rem 0;
  background: #0056BD;
}
.place h2,
.place p {
  text-align: justify;
  color: white;
}
.place button {
  border: 2px solid #FFAD29;
  border-radius: 0.7rem;
  background: #FFAD29;
  color: #fff;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
.place button:hover {
  border: 2px solid #FFAD29;
  border-radius: 0.7rem;
  background: none;
  color: #FFAD29;
  padding: 0.5rem 5rem;
  transition: all 0.5s ease;
}
/* Shapes */
.custom-shape-divider-bottom-1703026153 {
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(-180deg);
}
.custom-shape-divider-bottom-1703026153 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 181px;
  transform: rotateY(-180deg);
}
.custom-shape-divider-bottom-1703026153 .shape-fill {
  fill: #0056BD;
}
/* End Shapes */
.place-cloud {
  position: relative;
  top: -8rem;
}
.place-cloud img {
  max-width: 100%;
  height: auto;
  width: 100vw;
}
@media screen and (max-width: 1023px) {
  .place-cloud {
    position: relative;
    top: -1.5rem;
  }
}
.place-girl {
  position: relative;
}
.place-girl img {
  max-width: 100%;
  height: auto;
  width: 10rem;
  position: absolute;
  right: 5rem;
  bottom: 1rem;
}
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .place-girl {
    position: relative;
  }
  .place-girl img {
    max-width: 100%;
    height: auto;
    width: 7rem;
    position: absolute;
    right: 9.5rem;
    bottom: -2rem;
  }
}
.questions {
  padding-bottom: 7rem;
}
.questions .imgs {
  position: relative;
  bottom: -60rem;
}
.questions .imgs .group {
  max-width: 100%;
  height: auto;
  width: 10rem;
  position: absolute;
  right: 5rem;
  bottom: 1rem;
}
.questions .imgs .turn {
  max-width: 100%;
  height: auto;
  width: 10rem;
  position: absolute;
  left: 5rem;
  bottom: 0rem;
}
@media screen and (max-width: 1919px) {
  .questions .group,
  .questions .turn {
    display: none;
  }
}
.questions h2 {
  color: #2C49A1;
}
.questions .nav {
  border-bottom: #004a93;
}
.questions .nav .nav-link {
  color: #708AA7;
}
.questions .nav .active {
  color: #004a93;
  border: none;
  border-bottom: 2px solid #004a93;
}
.questions .nav .nav-link:hover {
  border: white;
}
.questions .nav .nav-link:focus {
  color: #004a93;
  border: none;
  border-bottom: 2px solid #004a93;
}
@media screen and (min-width: 1024px) {
  .bottom {
    margin-bottom: -10rem;
  }
}
.bottom img {
  max-width: 100%;
  height: auto;
  width: 100vw;
}
@media screen and (max-width: 1023px) {
  .bottom button {
    position: relative;
    top: -5rem;
    right: 0;
    padding: 0.5rem 3rem;
    border-radius: 1rem;
    border: 1px solid orange;
    background: orange;
    color: #fff;
  }
  .bottom button:hover {
    position: relative;
    top: -5rem;
    right: 0;
    padding: 0.5rem 3rem;
    border-radius: 1rem;
    border: 1px solid orange;
    background: none;
    color: orange;
  }
}
@media screen and (min-width: 1024px) {
  .bottom button {
    position: relative;
    top: -15rem;
    right: 0;
    padding: 1rem 6rem;
    border-radius: 1rem;
    border: 1px solid orange;
    background: orange;
    color: #fff;
  }
  .bottom button:hover {
    position: relative;
    top: -15rem;
    right: 0;
    padding: 1rem 6rem;
    border-radius: 1rem;
    border: 1px solid orange;
    background: none;
    color: orange;
  }
}
.cash_section {
  background-color: #004a93;
}
.cash_section p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: white;
  font-weight: 400;
  margin-bottom: 0;
}
.cash_section .banner-row {
  background-color: #f5f9fc !important;
  z-index: -2;
}
.cash_section .image-container::before {
  content: '';
  position: absolute;
  right: calc(-50vw + 50%);
  top: 86.615%;
  width: 50vw;
  /* 591 - 512 */
  height: 13.5385%;
  background-color: #004a93;
  z-index: 9;
}
.cash_section .image-container::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 86.615%;
  width: 50vw;
  /* 591 - 512 */
  height: 13.5385%;
  background-color: #004a93;
  z-index: 9;
}
.cash_section .image-container {
  position: relative;
}
.cash_section img {
  z-index: 10;
}
.kids_cash_section .banner {
  background-color: #00529B;
}
.kids_cash_section p {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 0;
}
.kids_cash_section .banner-row {
  background-color: #f5f9fc !important;
  z-index: -2;
}
.kids_cash_section .image-container::before {
  content: '';
  position: absolute;
  right: calc(-50vw + 50%);
  top: 86.615%;
  width: 50vw;
  /* 591 - 512 */
  height: 13.5385%;
  background-color: #00529B;
  z-index: 9;
}
.kids_cash_section .image-container::after {
  content: '';
  position: absolute;
  right: 50%;
  top: 86.615%;
  width: 50vw;
  /* 591 - 512 */
  height: 13.5385%;
  background-color: #00529B;
  z-index: 9;
}
.kids_cash_section .image-container {
  position: relative;
}
.kids_cash_section img {
  z-index: 10;
}
.school-banner {
  margin-top: 1rem;
}
.school-banner .info-section .content .title {
  color: #ff6700;
  max-width: 494px;
  font-size: 2.5rem;
}
.school-banner .info-section .content .subtitle {
  font-size: 1.125rem;
  color: #606060;
  max-width: 487px;
}
.school-banner .info-section .content .button {
  font-size: 1.125rem;
  width: 200px;
  height: 55px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 13px;
}
.school-banner .info-section .content .zoni-img {
  height: 11rem !important;
}
@media only screen and (max-width: 1199px) and (min-width: 400px) {
  .school-banner .info-section .content .zoni-img {
    height: 7rem !important;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 400px) {
  .school-banner .video-section .banner-video {
    margin: auto;
    height: 320px;
  }
}
@media only screen and (max-width: 400px) and (min-width: 300px) {
  .school-banner .video-section .banner-video {
    margin: auto;
    height: 250px;
  }
}
@media only screen and (min-width: 1200px) {
  .school-banner .video-section .banner-video {
    margin-right: auto;
    height: 500px;
  }
}
@media only screen and (max-width: 992px) {
  .school-banner .info-section .content {
    text-align: center;
  }
  .school-banner .info-section .content .title {
    max-width: 100%;
  }
  .school-banner .info-section .content .subtitle {
    max-width: 100%;
  }
  .school-banner .info-section .content .button {
    margin-bottom: 32px;
    width: 175px;
    height: 45px;
  }
}
.programs-section {
  background-color: #F1F8FF;
  padding-top: 6.563rem;
}
.programs-section .info-section {
  position: relative;
}
.programs-section .info-section .title {
  text-align: center;
  font-size: 2.5rem;
  color: #00529b;
  text-transform: capitalize;
}
.programs-section .info-section .subtitle {
  margin-top: 8px;
  text-align: center;
  color: #5B5A5A;
  font-size: 1.125rem;
  margin-bottom: 32px;
}
.programs-section .info-section .programs {
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.programs-section .info-section .programs .program {
  margin-top: 6.25rem;
  border-radius: 20px;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.programs-section .info-section .programs .program .program-img-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 15.313rem;
  text-align: center;
}
.programs-section .info-section .programs .program .program-img-container .program-video {
  position: relative;
  height: 18.75rem;
  margin-top: -3.438rem;
  margin-right: -0.75rem;
}
.programs-section .info-section .programs .program .program-img-container.first-card {
  background-color: #0074E4;
}
.programs-section .info-section .programs .program .program-img-container.second-card {
  background-color: #FFD700;
}
.programs-section .info-section .programs .program .program-img-container.third-card {
  background-color: #FF6D42;
}
.programs-section .info-section .programs .program .program-img-container.fourth-card {
  background-color: #F09000;
}
.programs-section .info-section .programs .program .program-img-container.fifth-card {
  background-color: #FFD700;
}
.programs-section .info-section .programs .program .program-value {
  color: #151515;
  font-size: 0.96rem;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F8F8F8;
}
.programs-section .info-section .programs .program .program-credits {
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27.2px;
}
.programs-section .info-section .programs .program .program-credits.first-card {
  background: #EDCD25;
}
.programs-section .info-section .programs .program .program-credits.second-card {
  background: #0074E4;
}
.programs-section .info-section .programs .program .program-credits.third-card {
  background: #EF3E42;
}
.programs-section .info-section .programs .program .program-credits.fourth-card {
  background: #FF6D42;
}
.programs-section .info-section .programs .program .program-credits.fifth-card {
  background: #FF6D42;
}
.programs-section .info-section .programs .program .program-title {
  font-size: 1.5rem;
  color: #151515;
  max-width: 300px;
}
.programs-section .info-section .programs .program .program-description {
  font-size: 0.938rem;
  color: #5B5A5A;
  margin-bottom: 60px;
}
.programs-section .info-section .programs .program .program-button {
  width: 185px;
  height: 41px;
  border-radius: 7px;
  background: #004a93;
  color: white;
  padding-top: 5px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 30.6px;
  position: absolute;
  bottom: -21px;
}
.programs-section .info-section .left-icon {
  position: absolute;
  right: 20px;
  top: 55%;
}
.programs-section .info-section .left-icon .left-icon-img {
  width: 40px;
}
.programs-section .info-section .right-icon {
  position: absolute;
  left: 10px;
  top: 55%;
}
.programs-section .info-section .right-icon .right-icon-img {
  width: 40px;
}
.programs-section .info-section .programs::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 992px) {
  .programs-section .info-section {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .programs-section .info-section .left-icon {
    top: 60%;
    display: none;
  }
  .programs-section .info-section .right-icon {
    top: 60%;
    display: none;
  }
}
.mission-vision-section {
  padding: 3.688rem 0;
  position: relative;
}
.mission-vision-section .img-section .logo {
  height: 9.875rem;
}
.mission-vision-section .content-section {
  padding-top: 8.125rem;
  padding-bottom: 15.625rem;
}
.mission-vision-section .content-section .subtitle {
  font-size: 1.25rem;
  color: #00529b;
}
.mission-vision-section .content-section p {
  font-size: 1.125rem;
  color: #000;
}
.mission-vision-section .img-students {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (min-width: 767px) and (max-width: 1199px) {
  .mission-vision-section .img-students {
    width: auto;
    height: 40%;
  }
}
@media only screen and (max-width: 767px) {
  .mission-vision-section .img-section {
    text-align: center;
  }
  .mission-vision-section .content-section {
    padding-top: 4.125rem;
    padding-bottom: 24px;
  }
  .mission-vision-section .content-section .subtitle {
    text-align: center;
  }
  .mission-vision-section .content-section p {
    text-align: center;
  }
  .mission-vision-section .img-students {
    display: none;
  }
}
.about-section {
  min-height: 419px;
  position: relative;
  background: #FFD700;
  padding-bottom: 30px;
}
.about-section .link-info {
  color: black !important;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem;
  text-decoration-line: underline;
  text-decoration-color: black !important;
}
.about-section .accordion-section {
  margin-top: 75px;
}
.about-section .accordion-section .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-bg: white;
}
.about-section .accordion-section .accordion .accordion-button {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  width: auto !important;
  color: #00529b;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.2px;
}
.about-section .accordion-section .accordion .accordion-button:focus {
  box-shadow: none !important;
}
.about-section .accordion-section .accordion .accordion-button::after {
  margin-right: 10px;
}
.about-section .accordion-section .accordion .accordion-body {
  border: 0;
  background: transparent !important;
}
.about-section .img {
  position: absolute;
  left: 0;
  bottom: 0;
}
@media only screen and (max-width: 991px) {
  .about-section {
    padding: 70px 50px;
    min-height: 335px;
    max-height: 1140px;
    background: #FFD700;
  }
  .about-section .accordion-section {
    max-height: 319px;
    height: 319px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 1919px) {
  .about-section .img {
    display: none;
  }
}
.school-questions {
  background: #F1F8FF;
  padding: 126px 0;
}
.school-questions .title {
  font-size: 2.25rem;
  color: #0056C0;
}
.school-questions .nav {
  border-bottom: #004a93;
}
.school-questions .nav .nav-link {
  font-size: 1.216rem;
  color: #708aa7;
  border: none;
  background: none;
}
.school-questions .nav .active {
  color: #004a93 !important;
  border: none;
  border-bottom: 2px solid #004a93 !important;
}
.school-questions .nav .nav-link:hover {
  color: #004a93 !important;
  border: white;
}
.school-questions .nav .nav-link:focus {
  color: #004a93 !important;
  border: none;
  border-bottom: 2px solid #004a93 !important;
}
.school-questions .nav h2 {
  color: #2C49A1;
}
.school-questions .accordion-item {
  border-bottom: 0.5px solid #b8b8b866;
}
.school-questions .accordion-button {
  border: none !important;
  background: none !important;
  color: #0056C0 !important;
}
.school-questions .accordion-button:focus {
  box-shadow: none !important;
}
.school-questions .accordion-body {
  background: none !important;
  border: none !important;
}
.school-questions .accordion {
  --bs-accordion-bg: none !important;
  --bs-accordion-border-color: none !important;
  --bs-accordion-color: #727272 !important;
}
.experienced-teachers {
  background-color: #0074E4;
  padding: 50px 0;
}
.experienced-teachers .content-section .title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  font-weight: 600;
  text-transform: capitalize;
}
.experienced-teachers .content-section .subtitle {
  color: #fff;
  font-size: 0.875rem;
}
.experienced-teachers .content-section .link {
  color: #fff;
  font-size: 0.75rem;
  text-transform: capitalize;
}
@media only screen and (min-width: 768px) {
  .experienced-teachers .content-section .link {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .experienced-teachers .content-section {
    margin-top: 20px;
  }
  .experienced-teachers .content-section .title {
    text-align: center;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 2.25rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  .experienced-teachers .content-section .subtitle {
    color: #fff;
    font-size: 0.875rem;
  }
  .experienced-teachers .img-teachers {
    width: auto;
    height: 50%;
  }
}
.core-values {
  background-color: #0074e4;
  padding: 5.25rem 0 3.25rem 0;
  margin-top: 8rem;
}
.core-values .image-section {
  text-align: center;
  margin-top: -150px;
}
.core-values .content-section {
  margin: 6rem 0 6rem 0;
}
.core-values .content-section .title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 2.5rem;
  text-transform: capitalize;
}
.core-values .content-section .subtitle {
  color: #fff;
  font-size: 1rem;
  padding-top: 16px;
}
.core-values .content-section .link {
  color: #fff;
  font-size: 1rem;
  text-transform: capitalize;
}
.core-values .content-section .item-description {
  margin-bottom: 30px;
}
.core-values .content-section .item-description .item-value {
  color: #fff;
  font-size: 1.25rem;
  text-decoration-line: underline;
  padding-right: 16px;
}
.core-values .content-section .description-values {
  background-color: #fff;
  border-radius: 25px;
  opacity: 0.8;
}
.core-values .content-section .description-values .description {
  padding: 10px;
}
.core-values .content-section .description-body {
  padding-right: 46px;
}
@media (max-width: 991px) {
  .core-values .content-section {
    text-align: center;
  }
}
.core-values .mobile-carrousel {
  height: 4.5rem !important;
}
.core-values .accordion-item {
  border: none;
}
.core-values .accordion-button {
  background: none !important;
  border: none !important;
  color: #fff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  text-decoration-line: underline;
  text-transform: capitalize;
}
.core-values .accordion-button:focus {
  box-shadow: none !important;
}
.core-values .accordion-body {
  background: none !important;
  border: none !important;
  border-radius: 25px !important;
  opacity: 0.8 !important;
  background: #fff !important;
  color: #000;
  box-sizing: none;
}
.core-values .accordion {
  --bs-accordion-bg: none !important;
  --bs-accordion-border-color: none !important;
}
.core-values .accordion-button::after {
  transform: none !important;
  transition: none !important;
  width: 0;
}
@media screen and (max-width: 992px) {
  html {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  html {
    font-size: 14px !important;
  }
}
.school-page .discover .zoni-icon {
  margin-top: -10rem;
}
.info-journey-content {
  margin-top: 10.188rem;
}
.info-journey-content .info-items-content .items-content-title .title {
  color: #004a93;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  /* 100% */
  width: 507px;
  height: 89px;
  flex-shrink: 0;
}
.info-journey-content .info-items-content .items-content-subtitle {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  /* 100% */
  margin-top: 2.063rem;
}
.info-journey-content .info-items-content .items-content-description {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  /* 120% */
  width: 411px;
  flex-shrink: 0;
  margin-top: 0.938rem;
}
@media screen and (max-width: 768px) {
  .info-journey-content .info-items-content .title {
    font-size: 30px;
  }
  .info-journey-content .info-items-content .items-content-subtitle {
    font-size: 18px;
  }
  .info-journey-content .info-items-content .items-content-description {
    font-size: 16px;
    line-height: 20px;
  }
}
.school-programs-banner {
  margin-top: 3.563rem;
}
.school-programs-banner .banner-title {
  margin-top: 4.313rem;
  color: #ffffff;
  background: #0074E4;
  font-family: Poppins;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.438rem;
  padding-right: 40px;
  padding-left: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 30px 30px 30px 30px;
}
.school-programs-banner .banner-title-2 {
  color: #000000;
  background: #FFD700;
  font-family: Poppins;
  font-size: 2.5rem;
  transform: rotate(3.465deg);
  font-style: normal;
  font-weight: 700;
  line-height: 3.438rem;
  padding-right: 40px;
  padding-left: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 30px 30px 30px 30px;
}
.school-programs-banner .banner-title-without {
  color: #004a93;
  font-size: 3rem;
  font-weight: 700;
  line-height: 47px;
  /* 97.917% */
  font-style: normal;
}
.school-programs-banner .description {
  margin-top: 1rem;
  color: #000000;
  font-family: Poppins;
  font-size: smaller;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.school-programs-banner .button {
  margin-top: 1rem;
  width: 205px;
  border-radius: 46px;
  background: #FFD700;
  color: #004a93;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
@media only screen and (max-width: 991px) {
  .school-programs-banner .content-title {
    text-align: center;
  }
  .school-programs-banner .content-title .title {
    margin-top: 1rem;
  }
  .school-programs-banner .content-description {
    text-align: center;
  }
  .school-programs-banner .content-button {
    text-align: center;
    margin-bottom: 3rem;
  }
  .school-programs-banner .content-button .button {
    height: 40px;
    width: 158px;
  }
}
@media only screen and (max-width: 768px) {
  .school-programs-banner .content-title .banner-title {
    margin: auto;
    margin-top: 4.313rem;
  }
  .school-programs-banner .content-title .banner-title-2 {
    margin: auto;
  }
}
.school-programs-credits {
  margin-top: 3rem;
}
.school-programs-credits .graphic {
  background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section.svg");
  width: 37.125rem;
  height: 36.25rem;
}
.school-programs-credits .graphic .number-info-content {
  max-height: 105px;
}
.school-programs-credits .graphic .number-info-content .number-info {
  color: #ffffff;
  font-family: Poppins;
  font-size: 6.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.school-programs-credits .graphic .credits-info-content {
  max-height: 105px;
}
.school-programs-credits .graphic .credits-info-content .credits-info {
  color: #ffffff;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.school-programs-credits .graphic .description-info-content {
  margin-bottom: 80px;
}
.school-programs-credits .graphic .description-info-content .description-info {
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  margin-right: 125px;
  margin-left: 125px;
}
.school-programs-credits .credits-text-info {
  color: #000000;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .school-programs-credits .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section.svg");
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .school-programs-credits .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section.svg");
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .school-programs-credits .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section.svg");
    background-size: 28.125rem 27.25rem;
    width: 28.125rem;
    height: 27.25rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 1199px) {
  .school-programs-credits .graphic .number-info-content {
    margin-top: 3rem;
    max-height: 75px;
  }
  .school-programs-credits .graphic .number-info-content .number-info {
    font-size: 5.25rem;
  }
  .school-programs-credits .graphic .credits-info-content .credits-info {
    font-size: 2rem;
  }
  .school-programs-credits .graphic .description-info-content .description-info {
    font-size: 0.638rem;
    margin-right: 62px;
    margin-left: 62px;
  }
}
@media only screen and (min-width: 510px) and (max-width: 777px) {
  .school-programs-credits .graphic {
    margin: auto;
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
  .school-programs-credits .text-info-content {
    text-align: center;
  }
  .school-programs-credits .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 509px) {
  .school-programs-credits .graphic {
    margin: auto;
    background-size: 27.125rem 26.25rem;
    width: 27.125rem;
    height: 26.25rem;
  }
  .school-programs-credits .text-info-content {
    text-align: center;
  }
  .school-programs-credits .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
.school-programs-requirements {
  margin-top: 6.625rem;
}
.school-programs-requirements .requirements-background {
  background: #ffffff;
  background: linear-gradient(-90deg, #ffffff 60%, #0074E4 60%);
}
.school-programs-requirements .white-color {
  color: #ffffff !important;
}
.school-programs-requirements .title-content .title {
  color: #004a93;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}
.school-programs-requirements .requirement-left-section {
  padding-top: 57px;
  padding: 2rem;
}
.school-programs-requirements .requirement-right-section {
  background: #0074E4;
  border-radius: 0px 87px 0px 0px;
  padding-top: 57px;
  padding-right: 66px;
}
.school-programs-requirements .requirement-right-section .description-content {
  margin-top: 2rem;
}
.school-programs-requirements .requirement-right-section .description-content .description-right {
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.school-programs-requirements .requirement-right-section .content-value {
  margin-top: 4.563rem;
}
.school-programs-requirements .requirement-right-section .content-value .value {
  font-family: Poppins;
  font-size: 6.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  /* 55% */
}
.school-programs-requirements .requirement-right-section .date-content {
  margin-bottom: 1.563rem;
}
.school-programs-requirements .requirement-right-section .date-content .date-value {
  font-family: Poppins;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  /* 57.8px */
}
.school-programs-requirements .requirement-right-section .content-button {
  text-align: center;
  margin-bottom: 3rem;
}
.school-programs-requirements .requirement-right-section .content-button .button {
  margin-top: 1rem;
  width: 205px;
  border-radius: 46px;
  background: #FFD700;
  color: #004a93;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
.school-programs-requirements .requirement-right-section .diploma-item {
  color: #ffffff;
  font-family: Poppins;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 400;
  line-height: 199%;
}
.school-programs-requirements .requirement-items {
  margin-top: 2.938rem;
  margin-top: 1.738rem;
}
.school-programs-requirements .requirement-items .requirement-item-info {
  margin-top: 1rem;
  margin-right: 70px;
  margin-bottom: 1rem;
}
.school-programs-requirements .requirement-items .requirement-square {
  margin-left: 0.3rem;
}
.school-programs-requirements .requirement-items .requirement-text {
  color: #000000;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.school-programs-requirements .requirement-items .blue-requirement {
  color: #0074e4;
  font-size: 30px;
  font-weight: 700;
  line-height: 55px;
  /* 183.333% */
}
.school-programs-requirements .requirement-items .number-rectangle {
  background-color: black;
  width: 45px;
  height: 45px;
}
@media only screen and (max-width: 991px) {
  .school-programs-requirements .requirements-background {
    background: none;
  }
  .school-programs-requirements .description-content {
    padding-inline: 2rem;
  }
  .school-programs-requirements .requirement-right-section {
    margin-top: 3rem;
    background: #0074E4;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    border-radius: 0;
  }
  .school-programs-requirements .title-content {
    line-height: 30px;
  }
  .school-programs-requirements .content-value {
    margin-top: 2rem;
    text-align: center;
  }
  .school-programs-requirements .date-content {
    text-align: center;
    margin-bottom: 0 !important;
  }
  .school-programs-requirements .button {
    width: 158px;
  }
}
.school-programs-reasons {
  background-color: #F6FAFF;
  padding: 5.313rem 0 5.688rem 0;
}
.school-programs-reasons .reasons-left-section .content-reasons .title {
  color: #004a93;
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
}
.school-programs-reasons .reasons-left-section .content-reasons .title .title-reasons {
  background: #FF6D42;
  background: linear-gradient(to left, #FF6D42 0%, #0074E4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.school-programs-reasons .reasons-left-section .button {
  margin-top: 1.5rem;
  background: #FFD700;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #004a93;
  font-family: Poppins;
  font-size: 1.125rem;
  width: 205px;
  border-radius: 46px;
  font-style: normal;
  font-weight: 600;
  border: none;
  line-height: 170%;
}
@media only screen and (max-width: 991px) {
  .school-programs-reasons .img-content {
    text-align: center;
  }
  .school-programs-reasons .reasons-left-section {
    margin-top: 45px;
  }
  .school-programs-reasons .reasons-left-section .content-reasons {
    margin-top: 1.5rem;
    text-align: start;
  }
  .school-programs-reasons .reasons-left-section .button {
    margin-top: 2rem;
    width: 158px;
  }
}
.school-programs-items-reasons {
  margin-top: 5.063rem;
  margin-bottom: 2.875rem;
}
.school-programs-items-reasons .img-reasons-content {
  text-align: center;
  height: 5rem;
}
.school-programs-items-reasons .img-reasons-content .img-item {
  width: 5rem;
}
.school-programs-items-reasons .item-reason-content {
  text-align: start;
  margin-top: 2.063rem;
}
.school-programs-items-reasons .item-reason-content .item-content {
  color: #000000;
  font-size: 0.938rem;
  font-weight: 500;
  line-height: 18px;
  /* 120% */
}
@media only screen and (max-width: 991px) {
  .school-programs-items-reasons .reasons-item {
    margin-top: 2.063rem;
  }
}
.school-programs-steps {
  margin-top: 10.438rem;
  margin-bottom: 3rem;
}
.school-programs-steps .steps-title-content {
  text-align: center;
}
.school-programs-steps .steps-title-content .title {
  color: #004a93;
  font-size: 3.438rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.125rem;
  /* 90.909% */
}
.school-programs-steps .steps-title-content .subtitle {
  color: #004a93;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.125rem;
}
.school-programs-steps .steps-items {
  margin-top: 3.5rem;
}
.school-programs-steps .steps-items .steps-item {
  border-radius: 20px;
  background-color: #FFD700;
}
.school-programs-steps .steps-items .steps-item .content-item-description {
  padding-left: 16px;
}
.school-programs-steps .steps-items .steps-item .content-item-description .item-title {
  color: #000000;
  font-family: Poppins;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
}
.school-programs-steps .steps-items .steps-item .content-item-description .item-subtitle {
  color: #000000;
  font-family: Poppins;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
.school-programs-steps .steps-items .img-steps {
  max-width: 581px;
}
@media only screen and (max-width: 991px) {
  .school-programs-steps .steps-img-content {
    text-align: center;
  }
}
@media only screen and (max-width: 450px) {
  .school-programs-steps .steps-item .item-number {
    height: 100px;
  }
}
.school-programs-transfer-credits .content-two-components-global {
  background-color: #0074E4;
}
.school-programs-transfer-credits .content-two-components-global .content-two-components {
  min-height: 300px;
}
.school-programs-transfer-credits .content-two-components-global .content-two-components .transfer-icon {
  width: 64px;
  height: 64px;
  margin: auto;
}
.school-programs-transfer-credits .content-two-components-global .content-two-components .transfer-credits-title-content {
  text-align: center;
}
.school-programs-transfer-credits .content-two-components-global .content-two-components .transfer-credits-title-content .title {
  display: block;
  color: #ffffff;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  /* 100% */
}
.school-programs-transfer-credits .content-two-components-global .content-two-components .transfer-credits-title-content .title-two {
  color: #ffffff;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  /* 100% */
}
.school-programs-transfer-credits .content-two-components-global .content-two-components .button-transfer-credits {
  width: 205px;
  border-radius: 46px;
  background: #FFD700;
  flex-shrink: 0;
  color: #004a93;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  /* 47.6px */
  border-color: transparent;
  margin: auto;
  margin-top: 0.5rem;
}
.school-programs-transfer-credits .content-middle-components-global {
  padding: 64px;
  background-color: #FFD700;
}
.school-programs-transfer-credits .content-end-components-global .content-end-components-global-color {
  padding: 64px;
  background-color: #FFD700;
  border-radius: 0px 0px 0px 87px;
}
.school-programs-transfer-credits .transfer-title {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  /* 150% */
}
.school-programs-transfer-credits .transfer-subtitle {
  color: #000000;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
}
@media only screen and (max-width: 991px) {
  .school-programs-transfer-credits .button-transfer-credits {
    margin-top: 2rem;
    height: 40px;
    width: 158px;
  }
}
@media only screen and (max-width: 768px) {
  .school-programs-transfer-credits .content-middle-components-global {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-top: 0px;
    background-color: transparent !important;
  }
  .school-programs-transfer-credits .content-middle-components-global .content-middle-components-global-color {
    padding: 60px 40px;
    background-color: #FFD700 !important;
    border-radius: 0px 0px 0px 87px !important;
  }
  .accordion {
    --bs-accordion-bg: transparent;
    --bs-accordion-border-color: transparent;
    --bs-accordion-border-width: 0;
    --bs-accordion-btn-padding-y: 0;
    --bs-accordion-body-padding-x: 0;
    --bs-accordion-body-padding-y: 0;
    --bs-accordion-active-bg: white;
  }
  .accordion .accordion-button {
    height: auto !important;
    background: transparent !important;
    border: none !important;
    width: auto !important;
    font-family: Poppins;
    font-size: 1.3rem;
    font-style: normal;
    cursor: default;
    color: #004a93;
    font-weight: 300;
    line-height: 29px;
    /* 241.667% */
    text-transform: capitalize;
  }
  .accordion .accordion-button::after {
    color: #004a93;
    margin-right: 10px;
  }
  .accordion .accordion-button:focus {
    box-shadow: none !important;
  }
  .accordion .accordion-body {
    border: 0;
    background: transparent !important;
  }
  .accordion .accordion-collapse {
    margin-top: 8px !important;
  }
}
.school-programs-which-prog {
  margin-top: 5.375rem;
  background: #ffffff;
  background: linear-gradient(-90deg, #ffffff 60%, #F0F7FF 60%);
}
.school-programs-which-prog .content-questions-color {
  background: #F0F7FF;
  border-radius: 0px 87px 0px 0px;
  padding-top: 57px;
  padding-right: 66px;
}
.school-programs-which-prog .content-questions-color .content-top-info {
  margin-bottom: 3.375rem;
}
.school-programs-which-prog .content-questions-color .content-questions .first-question {
  color: #004a93;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.313rem;
}
.school-programs-which-prog .content-questions-color .content-questions .button {
  margin-top: 1rem;
  width: 290px;
  border-radius: 21px;
  background: #FFD700;
  color: #004a93;
  font-size: 1.45rem;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
.school-programs-which-prog .content-questions-color .content-questions .second-question {
  color: #004a93;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 140%;
  /* 42px */
}
.school-programs-which-prog .content-questions-color .content-questions .response {
  color: #004a93;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 103%;
  /* 30.9px */
}
.school-programs-which-prog .content-questions-color .content-questions .phone-number-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.school-programs-which-prog .content-questions-color .content-questions .phone-number {
  color: #004a93;
  font-size: 2.188rem;
  font-weight: 600;
  line-height: 140%;
  /* 49px */
  text-decoration-line: underline;
}
@media only screen and (max-width: 991px) {
  .school-programs-which-prog {
    background: none;
  }
  .school-programs-which-prog .content-questions-color {
    margin-top: 3rem;
    background: #F0F7FF;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    border-radius: 0;
  }
  .school-programs-which-prog .which-img-content {
    text-align: center;
  }
  .school-programs-which-prog .content-questions {
    text-align: center;
  }
  .school-programs-which-prog .content-questions .button {
    height: 40px !important;
    width: 230px !important;
  }
}
.school-programs-years-program .first-column {
  background-color: #FF6D42;
  padding-right: 5.5rem;
}
.school-programs-years-program .first-column .title {
  color: #ffffff;
  font-size: 1.875rem;
  font-weight: 700;
}
.school-programs-years-program .accordion-section .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-bg: white;
  --bs-accordian-btn-active-icon: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+Cjxzdmcgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSIwIDAgMTcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+DQoJPHBhdGggZD0iTTE2LjM1NCA1LjA3NWwtNy44NTUgNy44NTQtNy44NTMtNy44NTQgMC43MDctMC43MDcgNy4xNDUgNy4xNDYgNy4xNDgtNy4xNDcgMC43MDggMC43MDh6IiBmaWxsPSIjMDAwMDAwIiAvPg0KPC9zdmc+) !important;
  --bs-accordian-btn-icon: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIEdlbmVyYXRvcjogU1ZHIFJlcG8gTWl4ZXIgVG9vbHMgLS0+Cjxzdmcgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSIwIDAgMTcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+DQoJPHBhdGggZD0iTTEzLjIwNyA4LjQ3MmwtNy44NTQgNy44NTQtMC43MDctMC43MDcgNy4xNDYtNy4xNDYtNy4xNDYtNy4xNDggMC43MDctMC43MDcgNy44NTQgNy44NTR6IiBmaWxsPSIjMDAwMDAwIiAvPg0KPC9zdmc+) !important;
}
.school-programs-years-program .accordion-section .accordion .accordion-button {
  height: auto !important;
  margin: auto;
  background: transparent !important;
  border: none !important;
  width: auto !important;
  color: #00529b;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.2px;
  cursor: default;
}
.school-programs-years-program .accordion-section .accordion .accordion-button:focus {
  box-shadow: none !important;
}
.school-programs-years-program .accordion-section .accordion .accordion-button::after {
  margin-right: 50px;
}
.school-programs-years-program .accordion-section .accordion .accordion-body {
  border: 0;
  background: transparent !important;
}
.school-programs-years-program .accordion-section .accordion .accordion-collapse {
  margin-top: 8px !important;
}
.school-programs-years-program .column-style .blue-column {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #0074E4;
}
.school-programs-years-program .column-style .blue-soft-column {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #cce3fa;
}
.school-programs-years-program .column-style .subtitle {
  color: #ffffff;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
}
.school-programs-years-program .column-style .subtitle-blue {
  color: #004a93;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
}
.school-programs-years-program .column-style .credits-number {
  color: #ffffff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
}
.school-programs-years-program .column-style .credits-number-blue {
  color: #004a93;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
}
.school-programs-years-program .column-style .course-item {
  width: 12.625rem;
  height: auto;
  min-height: 3.125rem;
  border-radius: 4px;
  background: #ffffff;
  text-align: center;
  margin: auto;
  margin-bottom: 0.75rem;
}
.school-programs-years-program .column-style .course-item .course-item-text {
  color: #004a93;
  font-size: 0.75rem;
}
@media screen and (min-width: 991px) {
  .school-programs-years-program .accordion-button::after {
    margin-right: 50px;
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .school-programs-years-program .blue-column {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .school-programs-years-program .blue-soft-column {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .school-programs-years-program .first-column {
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #FF6D42;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
}
.school-programs-career-technical-choose .card-choose-item {
  padding-top: 3.938rem;
}
.school-programs-career-technical-choose .card-choose-item .img-content .img-card {
  border-radius: 30px 30px 0 0;
  width: 100%;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content {
  width: 90%;
  margin-right: 0.875rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0 0 30px 30px;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-highschool-content {
  padding-top: 1.313rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-highschool-content .highschool {
  color: #004a93;
  font-family: "Helvetica Neue" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 180%;
  padding-top: 1.375rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-title-content {
  min-height: 3.188rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-title-content .title {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 21px;
  /* 105% */
  margin-right: 9.688;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .learn-more-content {
  margin-bottom: 1rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .learn-more-content .learn-more {
  color: #0074E4;
  font-family: "Helvetica Neue" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 180%;
  /* 25.2px */
  text-decoration-line: underline;
}
@media screen and (max-width: 991px) {
  .school-programs-career-gain .content-title {
    padding-right: 0rem;
    text-align: center;
  }
  .school-programs-career-gain .content-title .title {
    width: 80%;
  }
  .school-programs-career-gain .content-description {
    padding-right: 0rem;
    text-align: center;
  }
  .school-programs-career-gain .content-description .description {
    width: 80%;
  }
}
.school-programs-head-choose {
  background-color: #FF6D42;
  text-align: center;
  margin-top: 11.5rem;
}
.school-programs-head-choose .head-background .head-img-choose {
  margin-top: -105px;
  margin-bottom: 1.5rem;
}
.school-programs-head-choose .head-background .head-choose-title {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  line-height: 45px;
  /* 125% */
  flex-shrink: 0;
}
.school-programs-head-choose .head-background .head-subtitle-content {
  padding-bottom: 2.938rem;
}
.school-programs-head-choose .head-background .head-subtitle-content .head-choose-subtitle {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: lighter;
  line-height: 134%;
  /* 22.78px */
}
.school-programs-career-gain {
  margin-top: 3rem;
}
.school-programs-career-gain .container {
  background: #2273d1 !important;
  border-radius: 12px;
}
.school-programs-career-gain .content-title {
  padding-top: 2.125rem;
  padding-right: 3.813rem;
}
.school-programs-career-gain .content-title .title {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  line-height: 42px;
  /* 131.25% */
}
.school-programs-career-gain .content-description {
  padding-right: 3.813rem;
  padding-bottom: 2rem;
}
.school-programs-career-gain .content-description .description {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 36px;
  /* 150% */
}
.school-programs-career-gain .content-button {
  text-align: center;
}
.school-programs-career-gain .content-button .button {
  width: 205px;
  border-radius: 46px;
  background: #FFD700;
  color: #004a93;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
.school-programs-college-banner-tutoring {
  margin-top: 3.563rem;
  margin-bottom: 5rem;
}
.school-programs-college-banner-tutoring .img-item {
  display: inline-block;
  width: 75%;
}
.school-programs-college-banner-tutoring .content-img-container {
  text-align: left;
}
.school-programs-college-banner-tutoring .banner-title-without {
  margin-top: 4.313rem;
  color: #004a93;
  font-family: Poppins;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.438rem;
}
.school-programs-college-banner-tutoring .description {
  margin-top: 1rem;
  color: #000000;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.school-programs-college-banner-tutoring .button {
  margin-top: 1rem;
  width: 205px;
  border-radius: 46px;
  background: #FFD700;
  color: #004a93;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
@media only screen and (max-width: 991px) {
  .school-programs-college-banner .content-img-container {
    text-align: center;
  }
  .school-programs-college-banner .content-title {
    text-align: center;
  }
  .school-programs-college-banner .content-title .title {
    margin-top: 1rem;
  }
  .school-programs-college-banner .content-description {
    text-align: center;
  }
  .school-programs-college-banner .content-button {
    text-align: center;
    margin-bottom: 3rem;
  }
  .school-programs-college-banner .content-button .button {
    width: 158px;
  }
}
.school-programs-orange-stripe-no-shield {
  background-color: #FF6D42;
  height: 12.0625rem;
  text-align: center;
  display: flex;
  align-items: center;
}
.school-programs-orange-stripe-no-shield .head-background {
  width: 100%;
}
.school-programs-orange-stripe-no-shield .head-background .orange-stripe-title {
  color: #ffffff;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 45px;
  flex-shrink: 0;
  text-align: center;
}
.school-programs-orange-stripe-no-shield .head-background .orange-stripe-subtitle-content .orange-stripe-subtitle {
  color: #ffffff;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 3.12rem;
}
.right-side-tutoring-motivation-content {
  margin-top: 10rem;
}
.right-side-tutoring-motivation-content .blue-box {
  background-color: #cce3fa;
  border-radius: 60px 20px 0px 20px;
  margin-right: 4rem;
  margin-bottom: 3rem;
  padding: 2rem;
}
.right-side-tutoring-motivation-content .motivation-description {
  font-weight: 500;
  font-size: 1.125rem;
}
.right-side-tutoring-motivation-content .content-button {
  text-align: center;
}
.right-side-tutoring-motivation-content .content-button .button {
  width: 205px;
  border-radius: 46px;
  background: #FFD700;
  color: #004a93;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
.school-programs-tutoring-info .content-two-components-global {
  background-color: #FFD700;
}
.school-programs-tutoring-info .content-two-components-global .content-two-components {
  min-height: 300px;
  background-color: #FFD700;
}
.school-programs-tutoring-info .content-two-components-global .content-two-components .transfer-credits-title-content .title {
  text-align: right;
  font-size: 2.35rem;
  color: #00529b;
  font-weight: 700;
  line-height: 3.0625rem;
  /* 122.5% */
}
.school-programs-tutoring-info .content-end-components-global .content-end-components-global-color {
  padding: 2rem;
  background-color: #FFD700;
  border-radius: 0px 0px 0px 87px;
}
.school-programs-tutoring-info .transfer-title {
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.school-programs-tutoring-info .transfer-subtitle {
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.school-programs-tutoring-info .content-middle-components-global {
  background-color: #cce3fa;
  align-content: start;
  padding-top: 4rem;
}
.school-programs-tutoring-info .content-end-components-global .content-end-components-global-color {
  align-content: start;
  background-color: #cce3fa;
  padding-top: 4rem;
}
.school-programs-tutoring-info .triangle-conatainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.school-programs-tutoring-info .triangle-conatainer .triangle {
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-right: 1.5rem solid #00529b;
  border-bottom: 1rem solid transparent;
}
.pricing-tutoring-section {
  padding-top: 3.5rem;
}
.pricing-tutoring-section .info-section {
  position: relative;
  margin-bottom: 0rem;
}
.pricing-tutoring-section .info-section .center {
  justify-content: center;
  padding-right: 3rem;
  padding-left: 3rem;
}
.pricing-tutoring-section .info-section .center .title {
  text-align: right;
  font-size: 1.875rem;
  color: #00529b;
}
.pricing-tutoring-section .info-section .subtitle {
  margin-top: 8px;
  text-align: center;
  color: #5B5A5A;
  font-size: 1.125rem;
  margin-bottom: 32px;
}
.pricing-tutoring-section .info-section .programs {
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.pricing-tutoring-section .info-section .programs .program {
  margin-top: 6.25rem;
  border-radius: 20px;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.pricing-tutoring-section .info-section .programs .program .program-img-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 15.313rem;
  text-align: center;
}
.pricing-tutoring-section .info-section .programs .program .program-img-container .program-video {
  position: relative;
  height: 18.75rem;
  margin-top: -3.438rem;
  margin-right: -0.75rem;
}
.pricing-tutoring-section .info-section .programs .program .program-img-container.first-card {
  background-color: #0074E4;
}
.pricing-tutoring-section .info-section .programs .program .program-img-container.second-card {
  background-color: #FFD700;
}
.pricing-tutoring-section .info-section .programs .program .program-img-container.third-card {
  background-color: #FF6D42;
}
.pricing-tutoring-section .info-section .programs .program .program-img-container.fourth-card {
  background-color: #F09000;
}
.pricing-tutoring-section .info-section .programs .program .program-img-container.fifth-card {
  background-color: #FFD700;
}
.pricing-tutoring-section .info-section .programs .program .program-value {
  color: #151515;
  font-size: 0.96rem;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F8F8F8;
}
.pricing-tutoring-section .info-section .programs .program .program-credits {
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27.2px;
}
.pricing-tutoring-section .info-section .programs .program .program-credits.first-card {
  background: #EDCD25;
}
.pricing-tutoring-section .info-section .programs .program .program-credits.second-card {
  background: #0074E4;
}
.pricing-tutoring-section .info-section .programs .program .program-credits.third-card {
  background: #EF3E42;
}
.pricing-tutoring-section .info-section .programs .program .program-credits.fourth-card {
  background: #FF6D42;
}
.pricing-tutoring-section .info-section .programs .program .program-credits.fifth-card {
  background: #FF6D42;
}
.pricing-tutoring-section .info-section .programs .program .program-title {
  font-size: 1.5rem;
  color: #151515;
  max-width: 300px;
}
.pricing-tutoring-section .info-section .programs .program .program-description {
  font-size: 0.938rem;
  color: #5B5A5A;
  margin-bottom: 60px;
}
.pricing-tutoring-section .info-section .programs .program .program-button {
  width: 185px;
  border-radius: 7px;
  background: #004a93;
  color: white;
  padding-top: 5px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 30.6px;
  position: absolute;
  bottom: -21px;
}
.pricing-tutoring-section .info-section .left-icon {
  position: absolute;
  right: 20px;
  top: 55%;
}
.pricing-tutoring-section .info-section .left-icon .left-icon-img {
  width: 40px;
}
.pricing-tutoring-section .info-section .right-icon {
  position: absolute;
  left: 10px;
  top: 55%;
}
.pricing-tutoring-section .info-section .right-icon .right-icon-img {
  width: 40px;
}
.pricing-tutoring-section .info-section .programs::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 992px) {
  .programs-section .info-section {
    margin-bottom: 0rem;
  }
}
@media only screen and (max-width: 600px) {
  .programs-section .info-section .left-icon {
    top: 60%;
    display: none;
  }
  .programs-section .info-section .right-icon {
    top: 60%;
    display: none;
  }
}
.creative-title .title-container {
  text-align: center;
  margin-top: 6rem;
}
.creative-title .title-container .title {
  display: inline-block;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.0625rem;
  color: #151515;
}
.creative-title .title-container .title-first-part {
  display: inline-block;
  background-color: #FFD700;
  border-radius: 30px;
  padding: 0.5rem;
  text-align: center;
}
.creative-title .title-container .title-second-part {
  text-align: center;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .creative-title .title-container {
    display: grid;
    justify-content: center;
  }
  .creative-title .title-container .title-first-part {
    padding-right: 1rem;
    padding-left: 1rem;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
  }
  .creative-title .title-container .title-first-part .title {
    display: inline-block;
    text-align: center;
  }
  .creative-title .title-container .title-second-part {
    margin: auto;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-top: 0.5rem;
    text-align: center;
  }
  .creative-title .title-container .title-second-part .title {
    display: inline-block;
    text-align: center;
  }
}
.school-programs-career-technical-choose .card-choose-item {
  padding-top: 3.938rem;
}
.school-programs-career-technical-choose .card-choose-item .img-content .img-card {
  border-radius: 30px 30px 0 0;
  width: 100%;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content {
  width: 91%;
  margin-right: 0.875rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0 0 30px 30px;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-highschool-content {
  padding-top: 1.313rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-highschool-content .highschool {
  color: #004a93;
  font-family: "Helvetica Neue" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 180%;
  padding-top: 1.375rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-title-content {
  min-height: 3.188rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .content-title-content .title {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 21px;
  /* 105% */
  margin-right: 9.688;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .learn-more-content {
  margin-bottom: 1rem;
}
.school-programs-career-technical-choose .card-choose-item .card-body-content .learn-more-content .learn-more {
  color: #0074E4;
  font-family: "Helvetica Neue" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 180%;
  /* 25.2px */
  text-decoration-line: underline;
}
.school-programs-head-choose {
  background-color: #FF6D42;
  text-align: center;
  margin-top: 11.5rem;
}
.school-programs-head-choose .head-background .head-img-choose {
  margin-top: -105px;
  margin-bottom: 1.5rem;
}
.school-programs-head-choose .head-background .head-choose-title {
  color: #ffffff;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 45px;
  /* 125% */
  flex-shrink: 0;
}
.school-programs-head-choose .head-background .head-subtitle-content {
  padding-bottom: 2.938rem;
}
.school-programs-head-choose .head-background .head-subtitle-content .head-choose-subtitle {
  color: #ffffff;
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 134%;
  /* 22.78px */
}
.school-programs-career-gain {
  margin-top: 3rem;
}
.school-programs-career-gain .container {
  background: #2273d1 !important;
  border-radius: 12px;
}
.school-programs-career-gain .content-title {
  padding-top: 2.125rem;
  padding-right: 3.813rem;
}
.school-programs-career-gain .content-title .title {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
  line-height: 42px;
  /* 131.25% */
}
.school-programs-career-gain .content-description {
  padding-right: 3.813rem;
  padding-bottom: 2rem;
}
.school-programs-career-gain .content-description .description {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 36px;
  /* 150% */
}
.school-programs-career-gain .content-button {
  text-align: center;
}
.school-programs-career-gain .content-button .button {
  width: 205px;
  border-radius: 46px;
  background: #FFD700;
  color: #004a93;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
@media only screen and (max-width: 991px) {
  .school-programs-career-gain .content-button {
    margin-bottom: 1rem;
  }
}
.school-individual-programs .content-head-courses {
  background-color: #FF6D42;
  text-align: center;
  margin-top: 11.5rem;
}
.school-individual-programs .content-head-courses .head-background .head-img-choose {
  margin-top: -105px;
  margin-bottom: 1.5rem;
}
.school-individual-programs .content-head-courses .head-background .content-title .title {
  color: #ffffff;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 45px;
  /* 125% */
  flex-shrink: 0;
}
.school-individual-programs .content-head-courses .head-background .content-subtitle {
  padding-bottom: 2.938rem;
}
.school-individual-programs .content-head-courses .head-background .content-subtitle .subtitle {
  color: #ffffff;
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 134%;
  /* 22.78px */
}
.school-individual-programs .container {
  margin-top: 9.5rem !important;
}
.school-individual-programs .content-collage-courses .content-title-courses {
  margin-bottom: 1rem;
}
.school-individual-programs .content-collage-courses .content-title-courses .title {
  color: #004a93;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  /* 96.667% */
}
.school-individual-programs .content-collage-courses .content-button {
  margin-top: 1.5rem;
}
.school-individual-programs .content-collage-courses .content-button .button {
  margin-top: 1rem;
  width: 205px;
  height: 47px;
  border-radius: 46px;
  background: #FFD700;
  color: #004a93;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: none;
}
.school-individual-programs .content-courses-types-cards .card-item-1 {
  background: #FFD700;
  min-height: 10.563rem;
}
.school-individual-programs .content-courses-types-cards .card-item-2 {
  background: #FF9142;
  min-height: 10.563rem;
}
.school-individual-programs .content-courses-types-cards .card-item-1,
.school-individual-programs .content-courses-types-cards .card-item-2 {
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.3rem;
}
.school-individual-programs .content-courses-types-cards .card-item-1 .content-card-labels .title-card,
.school-individual-programs .content-courses-types-cards .card-item-2 .content-card-labels .title-card {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
}
.school-individual-programs .content-courses-types-cards .card-item-1 .content-card-labels .description-card,
.school-individual-programs .content-courses-types-cards .card-item-2 .content-card-labels .description-card {
  color: #000000;
  font-size: 0.875rem;
  font-weight: 400;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .school-individual-programs .content-courses-types-cards .card-item-1,
  .school-individual-programs .content-courses-types-cards .card-item-2 {
    min-height: 124px;
  }
  .school-individual-programs .content-courses-types-cards .card-item-1 .content-card-labels .title-card,
  .school-individual-programs .content-courses-types-cards .card-item-2 .content-card-labels .title-card {
    color: #000000;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .school-individual-programs .content-courses-types-cards .card-item-1 .content-card-labels .description-card,
  .school-individual-programs .content-courses-types-cards .card-item-2 .content-card-labels .description-card {
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
@media only screen and (max-width: 991px) {
  .school-individual-programs .card-yellow {
    background: #FFD700;
  }
  .school-individual-programs .card-orange {
    background: #FF9142;
  }
}
.school-programs-admission-page .blue_stripe {
  margin: 67px 0;
  background: #0074E4;
  min-height: 7.813rem;
}
.school-programs-admission-page .blue_stripe .title {
  color: #ffffff;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 45px;
  /* 125% */
  text-align: center;
}
.school-programs-admission-page .list-requirements-content .requirement-item .number-content {
  padding-top: 1.5rem;
}
.school-programs-admission-page .list-requirements-content .requirement-item .number-content .number {
  color: #0074E4;
  font-size: 2rem;
  font-weight: 700;
  line-height: normal;
}
.school-programs-admission-page .list-requirements-content .requirement-item .description-content-1 {
  background: #f0f0f0;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 31px;
}
.school-programs-admission-page .list-requirements-content .requirement-item .description-content-1 .description {
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}
.school-programs-admission-page .list-requirements-content .requirement-item .description-content-2 {
  background: #cce3fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 31px;
}
.school-programs-admission-page .list-requirements-content .requirement-item .description-content-2 .description {
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}
.school-programs-admission-page .list-requirements-content .requirement-item .description-content-2 .list-items .item-one {
  margin-top: 0.563rem !important;
}
.school-programs-admission-page .list-requirements-content .requirement-item .description-content-2 .list-items .item-two {
  margin-top: 0.563rem !important;
}
.school-programs-admission-page .list-requirements-content .requirement-item .description-content-2 .list-items .item-three {
  margin-top: 0.563rem !important;
}
.programs-section-enrollment {
  background-color: #ffffff;
}
.programs-section-enrollment .info-section {
  position: relative;
  margin-bottom: 4rem;
}
.programs-section-enrollment .info-section .title {
  text-align: center;
  font-size: 2.5rem;
  color: #00529b;
  text-transform: capitalize;
}
.programs-section-enrollment .info-section .subtitle {
  margin-top: 8px;
  text-align: center;
  color: #5B5A5A;
  font-size: 1.125rem;
  margin-bottom: 32px;
}
.programs-section-enrollment .info-section .programs {
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.programs-section-enrollment .info-section .programs .program {
  margin-top: 6.25rem;
  border-radius: 20px;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.programs-section-enrollment .info-section .programs .program .program-img-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 15.313rem;
  text-align: center;
}
.programs-section-enrollment .info-section .programs .program .program-img-container .program-video {
  position: relative;
  height: 18.75rem;
  margin-top: -3.438rem;
  margin-right: -0.75rem;
}
.programs-section-enrollment .info-section .programs .program .program-img-container.first-card {
  background-color: #0074E4;
}
.programs-section-enrollment .info-section .programs .program .program-img-container.second-card {
  background-color: #FFD700;
}
.programs-section-enrollment .info-section .programs .program .program-img-container.third-card {
  background-color: #FF6D42;
}
.programs-section-enrollment .info-section .programs .program .program-img-container.fourth-card {
  background-color: #F09000;
}
.programs-section-enrollment .info-section .programs .program .program-img-container.fifth-card {
  background-color: #FFD700;
}
.programs-section-enrollment .info-section .programs .program .program-value {
  color: #151515;
  font-size: 0.96rem;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F8F8F8;
}
.programs-section-enrollment .info-section .programs .program .program-credits {
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27.2px;
}
.programs-section-enrollment .info-section .programs .program .program-credits.first-card {
  background: #EDCD25;
}
.programs-section-enrollment .info-section .programs .program .program-credits.second-card {
  background: #0074E4;
}
.programs-section-enrollment .info-section .programs .program .program-credits.third-card {
  background: #EF3E42;
}
.programs-section-enrollment .info-section .programs .program .program-credits.fourth-card {
  background: #FF6D42;
}
.programs-section-enrollment .info-section .programs .program .program-credits.fifth-card {
  background: #FF6D42;
}
.programs-section-enrollment .info-section .programs .program .program-title {
  font-size: 1.5rem;
  color: #151515;
  max-width: 300px;
}
.programs-section-enrollment .info-section .programs .program .program-description {
  font-size: 0.938rem;
  color: #5B5A5A;
  margin-bottom: 60px;
}
.programs-section-enrollment .info-section .programs .program .program-button {
  width: 185px;
  border-radius: 7px;
  background: #004a93;
  color: white;
  padding-top: 5px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 30.6px;
  position: absolute;
  bottom: -21px;
}
.programs-section-enrollment .info-section .left-icon {
  position: absolute;
  right: 20px;
  top: 55%;
}
.programs-section-enrollment .info-section .left-icon .left-icon-img {
  width: 40px;
}
.programs-section-enrollment .info-section .right-icon {
  position: absolute;
  left: 10px;
  top: 55%;
}
.programs-section-enrollment .info-section .right-icon .right-icon-img {
  width: 40px;
}
.programs-section-enrollment .info-section .programs::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 992px) {
  .programs-section .info-section {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .programs-section .info-section .left-icon {
    top: 60%;
    display: none;
  }
  .programs-section .info-section .right-icon {
    top: 60%;
    display: none;
  }
}
.school-programs-transfer-credits-section {
  margin-top: 3rem;
}
.school-programs-transfer-credits-section .graphic {
  background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
  width: 37.125rem;
  height: 36.25rem;
}
.school-programs-transfer-credits-section .graphic .number-info-content {
  max-height: 2.8rem;
}
.school-programs-transfer-credits-section .graphic .number-info-content .number-info {
  color: #ffffff;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.school-programs-transfer-credits-section .graphic .credits-info-content {
  max-height: 5rem;
}
.school-programs-transfer-credits-section .graphic .credits-info-content .credits-info {
  color: #ffffff;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.school-programs-transfer-credits-section .graphic .description-info-content {
  max-height: 105px;
  margin-bottom: 80px;
}
.school-programs-transfer-credits-section .graphic .description-info-content .description-info {
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.77rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-right: 6rem;
  margin-left: 6rem;
}
.school-programs-transfer-credits-section .credits-text-info {
  color: #000000;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .school-programs-transfer-credits-section .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .school-programs-transfer-credits-section .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .school-programs-transfer-credits-section .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
    background-size: 28.125rem 27.25rem;
    width: 28.125rem;
    height: 27.25rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 1199px) {
  .school-programs-transfer-credits-section .graphic .number-info-content {
    margin-top: 3rem;
    max-height: 75px;
  }
  .school-programs-transfer-credits-section .graphic .number-info-content .number-info {
    font-size: 2rem;
  }
  .school-programs-transfer-credits-section .graphic .credits-info-content .credits-info {
    font-size: 2rem;
  }
  .school-programs-transfer-credits-section .graphic .description-info-content .description-info {
    font-size: 0.638rem;
    margin-right: 50px;
    margin-left: 50px;
    line-height: 1.1rem;
  }
}
@media only screen and (min-width: 510px) and (max-width: 777px) {
  .school-programs-transfer-credits-section .graphic {
    margin: auto;
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
  .school-programs-transfer-credits-section .text-info-content {
    text-align: center;
  }
  .school-programs-transfer-credits-section .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 509px) {
  .school-programs-transfer-credits-section .graphic {
    margin: auto;
    background-size: 27.125rem 26.25rem;
    width: 27.125rem;
    height: 26.25rem;
  }
  .school-programs-transfer-credits-section .text-info-content {
    text-align: center;
  }
  .school-programs-transfer-credits-section .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
.info-monthly-items .info-items-content {
  margin-bottom: 3.688rem;
  margin-top: 3rem;
}
.info-monthly-items .info-items-content .info-items-content-green-1 {
  padding: 1rem;
  background: #f0f0f0;
  border-radius: 8px;
  margin-bottom: 22px;
}
.info-monthly-items .info-items-content .info-items-content-green-1 .description-content .description-item {
  color: #000000;
  font-weight: 400;
  line-height: 20px;
  /* 153.846% */
}
.info-monthly-items .info-items-content .info-items-content-green-2 {
  padding: 1rem;
  background: #cce3fa;
  border-radius: 8px;
  margin-bottom: 22px;
}
.info-monthly-items .background-monthly {
  background: #FFD700;
}
.info-monthly-items .background-monthly .text-end-one {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}
.info-monthly-items .background-monthly .text-end-one .title-end-one {
  min-height: 4.765rem;
  color: #000000;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 36px;
  /* 120% */
}
.info-monthly-items .background-monthly .text-end-one .subtitle-end-one {
  color: #000000;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: 24px;
  /* 160% */
}
.school-individual-course-section {
  margin-top: 6rem;
}
.school-individual-course-section .container-fluid {
  --bs-gutter-x: 0px !important;
}
.school-individual-course-section .accordion-general .title-heading-one {
  font-size: 1.875rem;
}
.school-individual-course-section .course-extension-content {
  margin-bottom: 3rem;
  flex-shrink: 0;
}
.school-individual-course-section .course-extension-content .course-extension-content-title {
  margin-bottom: 1.25rem;
}
.school-individual-course-section .course-extension-content .course-extension-content-title .title {
  color: #004a93;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  /* 120% */
}
.school-individual-course-section .course-extension-content .course-extension-content-description .description {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
.school-individual-course-section .tex-content .subtitleschedule {
  color: #00529b;
  font-size: 30px;
  font-weight: 700;
}
.school-individual-course-section .item-1 .accordion-button::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMkwxMSAxMUwyMCAyIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) !important;
}
.school-individual-course-section .item-2 .accordion-button::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTIgMkwxMSAxMUwyMCAyIiBzdHJva2U9IiMwMDc0RTQgIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4=) !important;
}
.school-individual-course-section .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-bg: white;
}
.school-individual-course-section .accordion .accordion-button {
  width: 100% !important;
  height: 57px !important;
  margin: auto;
  border: none !important;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.2px;
  cursor: default;
}
.school-individual-course-section .accordion .accordion-button-blue {
  background: #0074E4 !important;
  color: #ffffff !important;
}
.school-individual-course-section .accordion .accordion-button-blue-soft {
  background: #CCE3FA !important;
  color: #0074E4 !important;
}
.school-individual-course-section .accordion .accordion-button:focus {
  box-shadow: none !important;
}
.school-individual-course-section .accordion .accordion-body {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border: 0;
  background: transparent !important;
}
.school-individual-course-section .accordion .accordion-collapse {
  margin-top: 8px !important;
}
.school-service-finance-section {
  margin-top: 57px;
  padding-bottom: 6rem;
  background-color: #F8FBFF;
}
.school-service-finance-section .tex-content .service-subtitle {
  color: #00529b;
  font-size: 1.875rem;
  font-weight: 700;
}
.school-service-finance-section .tex-content .service-description {
  color: #000000;
  font-size: 0.938rem;
  font-weight: 400;
  line-height: normal;
  /* 166.667% */
}
.school-funding-page {
  margin-top: 7.5rem;
}
.school-funding-page .regions {
  margin-bottom: 4.688rem;
}
.school-funding-page .regions .regions-img {
  text-align: center;
}
.school-funding-page .background-yellow {
  background-color: #FFD700;
  border-radius: 20px;
  padding: 2.81rem;
}
.school-funding-page .background-yellow .title {
  color: #000000;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 40px;
  /* 125% */
  margin-bottom: 1.5rem;
}
.school-funding-page .background-yellow .margin-items {
  margin-bottom: 1.563rem;
}
.school-funding-page .strip-blue {
  background-color: #0074E4;
  height: 11rem;
}
.school-funding-page .strip-blue .title-content {
  text-align: center;
}
.school-funding-page .strip-blue .title-content .title {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 60px;
  /* 125% */
}
.school-funding-page .funding-page-tips {
  margin-top: 8rem;
  text-align: center;
}
.school-funding-page .funding-page-tips .tips-img {
  text-align: center;
}
.school-funding-page .item-text {
  text-align: center;
  padding: 3.125rem;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}
.school-funding-page .title-item {
  color: #000;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
}
.school-funding-page .background-end .text-content {
  border-radius: 20px;
  background: #cce3fa;
  align-items: center;
  padding: 5.188rem;
  margin-top: 5.625rem;
}
.school-funding-page .background-end .text-content .text {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 50px;
  /* 125% */
}
.items-end {
  margin-top: 8.688rem;
}
.items-end .items-button {
  text-align: center;
}
.items-end .items-button .buttonEnrollNow {
  margin-top: 1rem;
  width: 369.447px;
  flex-shrink: 0;
  border-radius: 63px;
  background: #0074e4;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 170%;
  /* 81.6px */
  margin-top: 13.125rem;
  margin-bottom: 2.5rem;
}
.items-end .backgroud-columuns {
  background: #ffd700;
  color: #000;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  padding: 1rem;
  margin-bottom: 2.125rem;
}
.items-end .backgroud-columuns .tips-text-items-end-six {
  align-items: center;
  margin-top: 1rem;
}
.items-end .backgroud-columuns .tips-text-items-end-seven {
  align-items: center;
  margin-top: 1.25rem;
}
.items-end .backgroud-columuns .tips-text-items-end-eight {
  align-items: center;
  margin-top: 0.938rem;
}
.items-end .backgroud-columuns .tips-text-items-end-nine {
  align-items: center;
  margin-top: 1.25rem;
}
.signup-page {
  height: 100vh;
}
@media screen and (min-width: 1024px) {
  .signup-page {
    height: 100%;
  }
}
.signup-page .text-orange {
  color: #FF432A;
}
.signup-page .bg-orange {
  background-color: #FF432A;
  color: white;
}
.signup-page .bg-orange:hover {
  background-color: #f9523c;
  color: white;
}
.signup-page .border-bottom-orange {
  border-bottom: 2px solid #FF432A;
}
.signup-page .form-control {
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  padding: 0;
}
.signup-page .form-control:focus {
  box-shadow: none;
  outline: none;
}
.signup-page .img-fluid {
  max-width: 100%;
  height: auto;
}
.refund-calculations .table-responsive {
  background-color: #cde3fa;
}
.refund-calculations .table-responsive .table {
  background-color: #cde3fa;
  color: black;
}
.refund-calculations .table-responsive .table .cell-1 {
  background-color: #cde3fa;
  border-bottom: 2px solid #827e7e;
  border-left: 2px solid #827e7e;
  border-top: 0px;
  border-right: 0px;
}
.refund-calculations .table-responsive .table .cell-2 {
  background-color: #cde3fa;
  border-bottom: 2px solid #827e7e;
  border-right: 2px solid #827e7e;
  border-top: 0px;
  border-left: 0px;
}
.info-monthly-items {
  margin-top: 5rem;
}
.info-monthly-items .info-items-content .item-1 {
  margin-top: -5rem;
}
@media screen and (max-width: 991px) {
  .info-monthly-items .info-items-content .item-1 {
    margin-top: 0rem;
  }
}
.school-programs-banner .pill {
  border-radius: 30px 30px 30px 30px;
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
}
.school-programs-banner .pill .inside-pill {
  border-radius: 200px 50px 50px 200px;
  -webkit-border-radius: 200px 50px 50px 200px;
  -moz-border-radius: 200px 50px 50px 200px;
}
.how-it-works .description {
  max-width: 50rem;
}
.online-courses-section .course-accordian .accordion-button {
  font-size: 1.375rem;
  background-color: #0074E4 !important;
  border-radius: 50px 0px 0px 50px ;
  color: white;
  text-align: center;
}
.online-courses-section .course-accordian .accordion-button:hover {
  background-color: #0074E4 !important;
  color: white !important;
}
.online-courses-section .course-accordian .accordion-button:focus {
  background-color: #0074E4 !important;
  color: white !important;
}
.online-courses-section .course-accordian .accordion-button::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMkwxMSAxMUwyMCAyIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) !important;
}
@media screen and (max-width: 768px) {
  .online-courses-section .course-accordian h2 {
    width: 100% !important;
  }
  .online-courses-section .course-accordian .accordion-button {
    display: flex;
    justify-content: space-between;
    width: 50% !important;
  }
}
.distinguishes-section .que {
  width: 100%;
  text-align: center;
}
.distinguishes-section .ans {
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .distinguishes-section .que {
    width: 100% !important;
    text-align: start;
  }
  .distinguishes-section .ans {
    width: 100% !important;
    text-align: start;
  }
}
.track-info {
  color: #0074E4;
  background-image: url('/assets/zoni_edu/assets/img/school/programs/track_programs_page/track_info_bg.png');
  background-repeat: no-repeat;
}
.track-info .fs-90 {
  font-size: 90px;
}
.track-info .track-info-description-container {
  background-color: #cde3fa;
}
.track-info .track-info-description {
  font-size: small;
}
.track-info .track-info-border {
  background-color: #0074E4;
}
.track-info .border-primary {
  border-color: #0074E4 !important;
}
@media screen and (max-width: 770px) {
  .track-info .credits-container {
    border-right: 0px !important;
  }
}
@media screen and (max-width: 770px) {
  .track-info {
    margin-top: 8rem !important;
  }
}
.banner-track-section {
  height: 60vh;
  width: 100%;
}
.grad-requirements .banner-title {
  margin-top: 4.313rem;
  color: #ffffff;
  background: #0074E4;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.438rem;
  padding-right: 40px;
  padding-left: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 30px 30px 30px 30px;
}
.grad-requirements .banner-title-2 {
  color: #004A93;
  background: #FFD700;
  font-family: Poppins;
  font-size: 2rem;
  transform: rotate(3.465deg);
  font-style: normal;
  font-weight: 700;
  line-height: 3.438rem;
  padding-right: 40px;
  padding-left: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 30px 30px 30px 30px;
}
.grad-requirements .grad-req-credits-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  color: #0074E4;
  padding: 10px;
  font-weight: bold;
  font-size: 1.75rem;
  border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  box-shadow: -4px 4px 0px 2px rgba(0, 116, 228, 0.75);
  -webkit-box-shadow: -4px 4px 0px 1px rgba(0, 116, 228, 0.75);
  -moz-box-shadow: -4px 4px 0px 2px rgba(0, 116, 228, 0.75);
}
@media screen and (max-width: 992px) {
  .grad-requirements .banner-title {
    font-size: 1.75rem;
  }
  .grad-requirements .banner-title-2 {
    font-size: 2.5rem;
  }
}
.sample-course .list-item {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-style: dotted;
  margin: 2px;
}
.sample-course .accordion-item .acc-btn {
  padding-inline: 3rem;
  padding-top: 1rem;
  background-color: #CCE3FA !important;
}
.sample-course .accordion-item .accordion-body1 {
  background-color: #0074E4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem;
}
.sample-course .accordion-item .accordion-button {
  color: #708aa7;
  background-color: #CCE3FA !important;
  border: none !important;
}
.sample-course .accordion-item .accordion-button .grade-title {
  color: #0074E4 !important;
}
.sample-course .accordion-item .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.sample-course .accordion-item .accordion-button:not(.collapsed) {
  background-color: #CCE3FA !important;
}
@media only screen and (max-width: 768px) {
  .sample-course .accordion-item .accordion .accordion-body {
    border: 0;
    background: #0074E4 !important;
  }
}
.certification {
  margin-top: 10rem;
}
.certification .jobs-container {
  overflow-x: scroll;
  scrollbar-width: none;
}
.facts {
  margin-top: 5rem;
}
.facts .banner-title {
  color: #ffffff;
  background: #0074E4;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.438rem;
  padding-right: 40px;
  padding-left: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 30px 30px 30px 30px;
}
.facts .banner-title-2 {
  color: #004A93;
  background: #FFD700;
  font-family: Poppins;
  font-size: 2rem;
  transform: rotate(3.465deg);
  font-style: normal;
  font-weight: 700;
  line-height: 2.3rem;
  padding-right: 40px;
  padding-left: 40px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 30px 30px 30px 30px;
}
.facts .card-1 {
  background: #0074E4;
  color: white;
  font-size: xx-small;
}
.facts .card-2 {
  background: #CCE3FA;
  font-size: xx-small;
}
@media screen and (max-width: 992px) {
  .facts .banner-title {
    font-size: 1.75rem;
  }
  .facts .banner-title-2 {
    font-size: 2.5rem;
  }
}
.leadership-section {
  margin-top: 5rem;
}
.leadership-section .leadership-bg-section {
  margin-top: 15rem;
  background-repeat: no-repeat;
  padding: 2rem;
  background-size: 100vw;
  background-position-x: center;
}
.leadership-section .sub-4 {
  margin-top: 10rem;
}
.leadership-section .sub-4 .sub-4-body {
  padding-bottom: 5rem;
}
.leadership-section .sub-4 .description {
  font-size: small;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 17rem;
  text-align: justify;
  padding: 0px 5px ;
}
.leadership-section .sub-4 .titles {
  font-size: small;
  height: 6rem;
}
.leadership-section .sub-4 .profile-card {
  height: -moz-max-content;
  height: max-content;
  padding: 0 2rem 4rem 2rem;
}
.leadership-section .sub-4 .profile-card .bg-color-blue {
  background-color: #0074E4;
}
.leadership-section .sub-4 .profile-card .bg-color-yellow {
  background-color: #FFD700;
}
.leadership-section .sub-4 .profile-card .bg-color-red {
  background-color: #FF6D42;
}
.leadership-section .sub-4 .profile-card .row {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.leadership-section .sub-4 .profile-card .card-img {
  height: 10rem;
  width: 10rem;
  margin-top: -30%;
}
.leadership-section .sub-3 {
  margin-top: -10%;
}
.leadership-section .sub-3 .sub-3-body {
  padding-bottom: 5rem;
}
.leadership-section .sub-3 .description {
  font-size: small;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 17rem;
  text-align: justify;
  padding: 0px 5px ;
}
.leadership-section .sub-3 .titles {
  font-size: small;
  height: 6rem;
}
.leadership-section .sub-3 .profile-card {
  height: -moz-max-content;
  height: max-content;
  padding: 0 2rem 4rem 2rem;
}
.leadership-section .sub-3 .profile-card .bg-color-blue {
  background-color: #0074E4;
}
.leadership-section .sub-3 .profile-card .bg-color-yellow {
  background-color: #FFD700;
}
.leadership-section .sub-3 .profile-card .bg-color-red {
  background-color: #FF6D42;
}
.leadership-section .sub-3 .profile-card .row {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.leadership-section .sub-3 .profile-card .card-img {
  height: 10rem;
  width: 10rem;
  margin-top: -30%;
}
@media screen and (max-width: 991px) {
  .leadership-section .leadership-bg-section {
    background-size: cover;
    background-position: center;
  }
  .leadership-section .sub-4 .description {
    font-size: xx-small;
    height: 15rem;
    overflow-y: scroll;
    scrollbar-width: none;
    text-align: justify;
    padding: 0px 3px ;
  }
  .leadership-section .sub-4 .titles {
    font-size: small !important;
    height: 8rem;
  }
  .leadership-section .sub-4 .profile-card {
    margin-top: 6rem;
    padding-bottom: 20px;
  }
  .leadership-section .sub-3 .description {
    font-size: xx-small;
    height: 15rem;
    overflow-y: scroll;
    scrollbar-width: none;
    text-align: justify;
    padding: 0px 3px ;
  }
  .leadership-section .sub-3 .titles {
    font-size: small !important;
    height: 5rem;
  }
  .leadership-section .sub-3 .profile-card {
    margin-top: 2rem;
    padding-bottom: 20px;
  }
  .leadership-section .sub-3 .profile-card .row {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}
.school-partners-banner {
  margin-top: 5rem;
  color: #004A93;
}
.school-partners-banner .school-partner-banner-strip {
  margin-top: 10rem;
  height: 12rem;
  background-color: #FF6D42;
}
.school-partners-banner .school-partner-banner-strip .logo {
  height: 10rem;
  width: 10rem;
}
.school-partners-banner .school-partner-banner-strip .banner-title2 {
  padding: 2rem;
  margin-bottom: 5rem;
  margin-top: 1rem;
  padding-top: 0rem;
}
.school-partners-offer {
  margin-top: 5rem;
}
.school-partners-offer .title {
  color: #004A93;
}
.school-partners-offer .circle-img-container {
  height: 15rem;
  width: 15rem;
  background-color: #0074E4;
}
.school-partners-offer .img-circle {
  height: 15rem;
  width: 15rem;
}
.school-partners-offer .dropdown-menu {
  width: 25rem;
  background-color: #FF6D42;
  color: white;
  border-radius: 20px;
  text-align: justify;
  padding: 1rem 2rem !important;
}
.school-partners-offer .dropdown-toggle {
  background-color: #FFD700 !important;
}
.school-partners-inclusions {
  margin-top: 5rem;
  color: #004A93;
}
.school-partners-inclusions .additional-inclusions {
  padding: 3rem 2rem;
  background-color: #0074E4;
}
.how-we-operate {
  margin-top: 5rem;
  background-color: #CCE3FA;
  padding: 0;
  color: #004A93;
}
.how-we-operate .operate-title {
  box-shadow: inset -1px -8px 4px -2px #4F120040;
  background-color: #FF6D42;
}
.how-we-operate .accordion-button {
  background-color: #CCE3FA !important;
  color: #004A93 !important;
}
.how-we-operate .accordion {
  border: 0px !important;
}
.how-we-operate .accordion-collapse {
  background-color: #CCE3FA !important;
}
.how-we-operate .accordion-collapse .accordion-body {
  background-color: #CCE3FA !important;
  color: #004A93 !important;
  box-shadow: inset -1px -8px 4px -6px #004a93;
}
.how-we-operate .accordion-button:focus {
  background-color: #CCE3FA !important;
  box-shadow: none;
  outline: none !important;
  color: #004A93;
}
.how-we-operate .input-container .label {
  background-color: white !important;
  font-weight: bold;
  text-align: center;
}
.how-we-operate .input-container .input {
  border: none;
}
.how-we-operate .input-container .input:focus {
  outline: none;
}
.how-we-operate .steps {
  background-color: #CCE3FA;
  padding: 2rem;
  box-shadow: inset -1px -8px 4px -6px #004a93;
}
@media screen and (max-width: 991px) {
  .how-we-operate .accordion .accordion-button {
    letter-spacing: 0px !important;
    text-transform: none !important;
  }
}
.technical-support-banner {
  margin-top: 5rem;
}
.techical-ticket-system {
  margin-top: 5rem;
}
.technical-requirements {
  margin-top: 6rem;
}
.technical-requirements .cells {
  border-width: 1px !important;
}
@media screen and (max-width: 770px) {
  .technical-requirements .cells {
    border-width: 0px !important;
  }
  .technical-requirements .cells-description {
    border-right: 0px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-bottom: 1px solid black !important;
    margin-bottom: 5px;
  }
  .technical-requirements .cells-description ul {
    list-style-type: disc !important;
  }
}
.home-schooling-banner {
  margin-top: 3.563rem;
}
.home-schooling-benefits {
  margin-top: 3.563rem;
}
.home-schooling-benefits .graphic {
  background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
  width: 37.125rem;
  height: 36.25rem;
}
.home-schooling-benefits .graphic .number-info-content {
  max-height: 105px;
}
.home-schooling-benefits .graphic .number-info-content .number-info {
  color: #ffffff;
  font-family: Poppins;
  font-size: 6.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.home-schooling-benefits .benefits-container {
  border-radius: 12rem;
  background-color: #0074E4;
}
.home-schooling-benefits .benefits-container .benefits-title {
  width: 75%;
}
@media screen and (max-width: 992px) {
  .home-schooling-benefits .benefits-container .benefits-title {
    width: 50%;
    font-size: medium;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .home-schooling-benefits .graphic {
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-schooling-benefits .graphic {
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-schooling-benefits .graphic {
    background-size: 28.125rem 27.25rem;
    width: 28.125rem;
    height: 27.25rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 1199px) {
  .home-schooling-benefits .graphic .number-info-content {
    margin-top: 3rem;
    max-height: 75px;
  }
  .home-schooling-benefits .graphic .number-info-content .number-info {
    font-size: 5.25rem;
  }
  .home-schooling-benefits .graphic .credits-info-content .credits-info {
    font-size: 2rem;
  }
  .home-schooling-benefits .graphic .description-info-content .description-info {
    font-size: 0.638rem;
    margin-right: 62px;
    margin-left: 62px;
  }
}
@media only screen and (min-width: 510px) and (max-width: 777px) {
  .home-schooling-benefits .graphic {
    margin: auto;
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
  .home-schooling-benefits .text-info-content {
    text-align: center;
  }
  .home-schooling-benefits .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 509px) {
  .home-schooling-benefits .graphic {
    margin: auto;
    background-size: 27.125rem 26.25rem;
    width: 27.125rem;
    height: 26.25rem;
  }
  .home-schooling-benefits .text-info-content {
    text-align: center;
  }
  .home-schooling-benefits .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
.home-schooling-decide {
  margin-top: 3.563rem;
  color: #00529B;
}
.home-schooling-decide .choose-section {
  margin-top: 5rem;
}
.home-schooling-decide .list-1 {
  background-color: #FFD700;
}
.home-schooling-decide .list-2 {
  background-color: #FF9142;
}
.home-schooling-individual-courses {
  margin-top: 8rem;
}
.home-schooling-individual-courses .indi-description {
  font-size: small;
}
.home-schooling-individual-courses .list-container {
  margin-top: 5rem;
}
.home-schooling-individual-courses .list-container .list-1 {
  background-color: #FFD700;
}
.home-schooling-individual-courses .list-container .list-2 {
  background-color: #FF9142;
}
.home-schooling-individual-courses .list-container .choose-description {
  font-size: small;
}
.course-catalog-banner {
  width: 100%;
  height: 90vh;
  background: url("/assets/zoni_edu/assets/img/school/programs/course_catalog/banner/banner-img.jpg") top center;
  background-size: cover;
}
.course-catalog-banner .container {
  position: relative;
}
.course-catalog-banner h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
}
.course-catalog-banner h2 {
  margin: 10px 0 0 0;
  font-size: 24px;
}
@media (max-width: 992px) {
  #why-us {
    margin-top: -6em;
  }
  .course-catalog-banner {
    margin-bottom: 0;
    height: 50vh;
  }
  .course-catalog-banner .container {
    padding-bottom: 63px;
  }
  .course-catalog-banner h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .course-catalog-banner h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
@media (max-height: 600px) {
  .course-catalog-banner {
    height: 110vh;
  }
}
.search-form {
  margin-top: -5rem;
  z-index: 999;
}
.search-form .search-button {
  margin-bottom: -3rem;
}
@media screen and (min-width: 991px) {
  .search-form .search-button {
    margin-bottom: 0rem;
  }
}
.interest-courses {
  margin-top: 5rem;
}
.interest-courses .card-container {
  display: flex;
  /* Makes cards sit side-by-side by default */
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.interest-courses .card {
  flex: 1 0 auto;
  gap: 5px;
  margin: 5px;
  width: 18em;
}
.interest-courses .card-img-top {
  width: auto !important;
}
.interest-courses .card-list {
  flex: 1 0 auto;
  /* Removes width sharing for better mobile layout */
  margin: 1px;
}
@media (max-width: 991px) {
  .interest-courses {
    /* Adjust breakpoint as needed */
  }
  .interest-courses .card-container {
    width: 90vw;
  }
  .interest-courses .list-links {
    max-width: 20em;
  }
  .interest-courses .card-list {
    flex: none;
    /* Removes width sharing for better mobile layout */
  }
  .interest-courses .card {
    flex: none;
    /* Removes width sharing for better mobile layout */
    width: auto;
    margin-right: 5px;
    margin-left: 5px;
  }
}
.top-course {
  margin-top: 5rem;
}
.top-course .card-container {
  display: flex;
  /* Makes cards sit side-by-side by default */
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.top-course .card {
  flex: 1 0 auto;
  gap: 5px;
  margin: 5px;
  width: 18em;
}
.top-course .card-list {
  flex: 1 0 auto;
  /* Removes width sharing for better mobile layout */
  margin: 1px;
}
@media (max-width: 991px) {
  .top-course {
    /* Adjust breakpoint as needed */
  }
  .top-course .card-container {
    width: 90vw;
  }
  .top-course .list-links {
    max-width: 20em;
  }
  .top-course .card-list {
    flex: none;
    /* Removes width sharing for better mobile layout */
  }
  .top-course .card {
    flex: none;
    /* Removes width sharing for better mobile layout */
    width: auto;
    margin-right: 20px;
    margin-left: 2px;
  }
}
.trending-courses {
  margin-top: 5rem;
}
.trending-courses .card-container {
  display: flex;
  /* Makes cards sit side-by-side by default */
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.trending-courses .card {
  flex: 1 0 auto;
  gap: 5px;
  margin: 5px;
  width: 18em;
}
.trending-courses .card-list {
  flex: 1 0 auto;
  /* Removes width sharing for better mobile layout */
  margin: 1px;
}
@media (max-width: 991px) {
  .trending-courses {
    /* Adjust breakpoint as needed */
  }
  .trending-courses .card-container {
    width: 90vw;
  }
  .trending-courses .list-links {
    max-width: 20em;
  }
  .trending-courses .card-list {
    flex: none;
    /* Removes width sharing for better mobile layout */
  }
  .trending-courses .card {
    flex: none;
    /* Removes width sharing for better mobile layout */
    width: auto;
    margin-right: 5px;
    margin-left: 5px;
  }
}
.clubs-banner {
  margin-top: 5rem;
}
.clubs-benefits {
  margin-top: 3.563rem;
  background-image: url('/assets/zoni_edu/assets/img/school/clubs_page/circle.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
}
.clubs-benefits .item-1 {
  background-color: #0074E4;
  border-radius: 10rem 0px 0px 0px;
  height: 60vh;
  padding: 2rem 5rem 8rem 5rem;
  position: absolute;
  color: white;
}
.clubs-benefits .item-2 {
  background-color: #FFD700;
  border-radius: 10rem 0px 0px 0px ;
  height: 60vh;
  position: absolute;
  padding: 5rem 5rem 8rem 5rem;
}
.clubs-benefits .list-container {
  position: relative;
  height: 75rem;
}
.clubs-benefits .list-container .list-item-1 {
  top: 0;
  padding: 2rem 3rem 8rem 5rem !important;
}
.clubs-benefits .list-container .list-item-2 {
  top: 15rem;
}
.clubs-benefits .list-container .list-item-3 {
  top: 30rem;
}
.clubs-benefits .list-container .list-item-4 {
  height: 50vh;
  top: 45rem;
}
@media screen and (max-width: 991px) {
  .clubs-benefits .list-item-1 {
    font-size: small;
    padding: 2rem 3rem 5rem 5rem !important;
  }
  .clubs-benefits .list-item-2 {
    padding: 3rem 3rem 5rem 5rem !important;
  }
  .clubs-benefits .list-item-3 {
    height: 50vh;
    padding: 3rem 3rem 5rem 5rem !important;
  }
  .clubs-benefits .list-item-4 {
    top: 50rem;
    padding: 3rem 3rem 5rem 5rem !important;
    height: -moz-max-content !important;
    height: max-content !important;
  }
}
.clubs-opportunities {
  margin-top: 5rem;
}
.clubs-opportunities .graphic {
  background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section.svg");
  width: 37.125rem;
  height: 36.25rem;
}
.clubs-opportunities .graphic .number-info-content {
  max-height: 105px;
}
.clubs-opportunities .graphic .number-info-content .number-info {
  color: #ffffff;
  font-family: Poppins;
  font-size: 6.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .clubs-opportunities .graphic {
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clubs-opportunities .graphic {
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .clubs-opportunities .graphic {
    background-size: 28.125rem 27.25rem;
    width: 28.125rem;
    height: 27.25rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 1199px) {
  .clubs-opportunities .graphic .number-info-content {
    margin-top: 3rem;
    max-height: 75px;
  }
  .clubs-opportunities .graphic .number-info-content .number-info {
    font-size: 5.25rem;
  }
  .clubs-opportunities .graphic .credits-info-content .credits-info {
    font-size: 2rem;
  }
  .clubs-opportunities .graphic .description-info-content .description-info {
    font-size: 0.838rem;
  }
}
@media only screen and (min-width: 510px) and (max-width: 777px) {
  .clubs-opportunities .graphic {
    margin: auto;
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
  .clubs-opportunities .text-info-content {
    text-align: center;
  }
  .clubs-opportunities .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 509px) {
  .clubs-opportunities .graphic {
    margin: auto;
    background-size: 27.125rem 26.25rem;
    width: 27.125rem;
    height: 26.25rem;
  }
  .clubs-opportunities .text-info-content {
    text-align: center;
  }
  .clubs-opportunities .text-info-content .credits-text-info {
    margin-bottom: 2rem;
  }
}
.international-students-banner {
  margin-top: 0rem;
}
.international-students-banner .col-12 {
  padding: 0;
}
.international-students-banner .mov {
  position: relative;
}
.international-students-banner .mov .buttons .btn-1 {
  padding: 0.5rem 4rem;
  color: #fff;
  border: 0px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  background: none;
  display: flex;
  align-items: start;
  flex-direction: column;
  transition: all 0.3s ease;
}
.international-students-banner .mov video {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.international-students-banner .mov .buttons .btn-sign-in {
  text-align: center;
}
.international-students-banner .mov .buttons .btn-2 {
  padding: 0.5rem 3rem;
  background: #FFD700;
  color: #004a93;
  font-weight: 700;
  font-size: 12px;
  border: none;
  border-radius: 5rem;
  transition: all 0.3s ease;
}
.international-students-banner .mov .buttons .btn-2:hover {
  padding: 0.5rem 3rem;
  background: none;
  color: #FFD700;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #FFD700;
  border-radius: 5rem;
  transition: all 300ms ease;
}
.international-students-banner .modal video {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 1023px) {
  .international-students-banner .mov .paper {
    width: 100%;
    height: 97%;
    position: absolute;
    z-index: 998;
  }
  .international-students-banner .mov .buttons {
    position: absolute;
    top: 25%;
    display: grid;
    z-index: 999;
  }
}
@media screen and (min-width: 1024px) {
  .international-students-banner .mov .paper {
    width: 100%;
    /* Cambiado de max-width a width */
    height: 99%;
    /* Cambiado de auto a 100% */
    position: absolute;
    z-index: 998;
  }
  .international-students-banner .mov .buttons {
    position: absolute;
    top: 50%;
    display: grid;
    z-index: 999;
  }
}
@media screen and (min-width: 1024px) {
  .international-students-banner .mov .buttons .btn-1 p {
    font-size: 27px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1023px) {
  .international-students-banner .mov .buttons .btn-1 p {
    margin-bottom: 0;
  }
}
.international-studemts-benefits {
  margin-top: 5rem;
}
.international-studemts-benefits .floatingLabel {
  margin-left: -5rem;
  z-index: 10;
}
.international-studemts-benefits .floatingLabel .floatingLabelContent {
  box-shadow: -6px 9px 0px 0px #004a93;
  background-color: #FF6D42;
}
.international-studemts-benefits .benefits-list-container {
  background-color: #CCE3FA;
}
@media screen and (max-width: 991px) {
  .international-studemts-benefits .floatingLabel {
    margin-left: -11rem;
    margin-bottom: -2rem;
  }
}
.international-blue-strip {
  background-color: #0074E4;
}
.internation-explore-programs {
  margin-top: 5rem;
}
.international-why-wait {
  margin-top: 3.563rem;
}
.international-why-wait .graphic {
  background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
  width: 37.125rem;
  height: 36.25rem;
}
.international-why-wait .number-info-content {
  max-height: 105px;
}
.international-why-wait .number-info {
  color: white;
  font-family: Poppins;
  font-size: 4rem;
  width: 50%;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.international-why-wait .credits-info-content {
  max-height: 105px;
}
.international-why-wait .credits-info {
  color: white;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.international-why-wait .description-info-content {
  max-height: 105px;
}
.international-why-wait .description-info {
  color: white;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-right: 125px;
  margin-left: 125px;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .international-why-wait .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .international-why-wait .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .international-why-wait .graphic {
    background-image: url("/assets/zoni_edu/assets/img/school/programs/college/credits/credits_section_orange.svg");
    background-size: 28.125rem 27.25rem;
    width: 28.125rem;
    height: 27.25rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 1199px) {
  .international-why-wait .number-info {
    font-size: 5.25rem;
  }
  .international-why-wait .credits-info-content .credits-info {
    font-size: 2rem;
  }
  .international-why-wait .description-info {
    font-size: 0.838rem;
    margin-right: 62px;
    margin-left: 62px;
  }
}
@media only screen and (min-width: 510px) and (max-width: 777px) {
  .international-why-wait .graphic {
    margin: auto;
    background-size: 33.125rem 32.25rem;
    width: 33.125rem;
    height: 32.25rem;
  }
  .international-why-wait .text-info-content {
    text-align: center;
  }
  .international-why-wait .credits-text-info {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 330px) and (max-width: 509px) {
  .international-why-wait .graphic {
    margin: auto;
    background-size: 27.125rem 26.25rem;
    width: 27.125rem;
    height: 26.25rem;
  }
  .international-why-wait .text-info-content {
    text-align: center;
  }
  .international-why-wait .credits-text-info {
    margin-bottom: 2rem;
  }
}
.events-office-hours .weekdays {
  margin-right: 8rem;
}
.events-office-hours .fa-play {
  color: #CCE3FA;
}
@media screen and (max-width: 992px) {
  .events-office-hours .weekdays {
    margin-right: 0rem;
  }
}
.parents-join {
  background-color: #f1f8ff;
  padding-bottom: 5rem;
}
.parents-join .left-title {
  padding: 2em 5em;
}
.parents-join .programs-section {
  background-color: #F1F8FF;
  padding-top: 6.563rem;
}
.parents-join .programs-section .info-section {
  position: relative;
  margin-bottom: 200px;
}
.parents-join .programs-section .info-section .title {
  text-align: center;
  font-size: 2.5rem;
  color: #00529b;
  text-transform: capitalize;
}
.parents-join .programs-section .info-section .subtitle {
  margin-top: 8px;
  text-align: center;
  color: #5B5A5A;
  font-size: 1.125rem;
  margin-bottom: 32px;
}
.parents-join .programs-section .info-section .programs {
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.parents-join .programs-section .info-section .programs .program {
  margin-top: 6.25rem;
  border-radius: 20px;
  background-color: #CCE3FA;
  height: 25rem;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.parents-join .programs-section .info-section .programs .program .program-img-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 10.313rem;
  text-align: center;
}
.parents-join .programs-section .info-section .programs .program .program-img-container img {
  width: 7rem;
}
.parents-join .programs-section .info-section .programs .program .program-img-container .program-video {
  position: relative;
  height: 18.75rem;
  margin-top: -3.438rem;
  margin-right: -0.75rem;
}
.parents-join .programs-section .info-section .programs .program .program-img-container.first-card {
  background-color: #0074E4;
}
.parents-join .programs-section .info-section .programs .program .program-img-container.second-card {
  background-color: #FFD700;
}
.parents-join .programs-section .info-section .programs .program .program-img-container.third-card {
  background-color: #FF6D42;
}
.parents-join .programs-section .info-section .programs .program .program-img-container.fourth-card {
  background-color: #F09000;
}
.parents-join .programs-section .info-section .programs .program .program-img-container.fifth-card {
  background-color: #FFD700;
}
.parents-join .programs-section .info-section .programs .program .program-value {
  color: #151515;
  font-size: 0.96rem;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F8F8F8;
}
.parents-join .programs-section .info-section .programs .program .program-credits {
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27.2px;
}
.parents-join .programs-section .info-section .programs .program .program-credits.first-card {
  background: #EDCD25;
}
.parents-join .programs-section .info-section .programs .program .program-credits.second-card {
  background: #0074E4;
}
.parents-join .programs-section .info-section .programs .program .program-credits.third-card {
  background: #EF3E42;
}
.parents-join .programs-section .info-section .programs .program .program-credits.fourth-card {
  background: #FF6D42;
}
.parents-join .programs-section .info-section .programs .program .program-credits.fifth-card {
  background: #FF6D42;
}
.parents-join .programs-section .info-section .programs .program .program-title {
  font-size: 1.32rem;
  color: #151515;
  max-width: 300px;
}
.parents-join .programs-section .info-section .programs .program .program-description {
  font-size: 0.938rem;
  color: #5B5A5A;
  margin-bottom: 60px;
}
.parents-join .programs-section .info-section .programs .program .program-button {
  width: 185px;
  height: 41px;
  border-radius: 7px;
  background: #004a93;
  color: white;
  padding-top: 5px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 30.6px;
  position: absolute;
  bottom: -21px;
}
.parents-join .programs-section .info-section .left-icon {
  position: absolute;
  right: 20px;
  top: 55%;
}
.parents-join .programs-section .info-section .left-icon .left-icon-img {
  width: 40px;
}
.parents-join .programs-section .info-section .right-icon {
  position: absolute;
  left: 10px;
  top: 55%;
}
.parents-join .programs-section .info-section .right-icon .right-icon-img {
  width: 40px;
}
.parents-join .programs-section .info-section .programs::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 992px) {
  .parents-join .programs-section .info-section {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .parents-join .programs-section .info-section .left-icon {
    top: 60%;
    display: none;
  }
  .parents-join .programs-section .info-section .right-icon {
    top: 60%;
    display: none;
  }
}
.about-home-section {
  position: relative;
  background: #FFD700;
  padding: 30px 20px;
}
.about-home-section .link-info {
  color: black !important;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem;
  text-decoration-line: underline;
  text-decoration-color: black !important;
}
.about-home-section .accordion-section {
  margin-top: 10px;
}
.about-home-section .accordion-section .accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-bg: white;
}
.about-home-section .accordion-section .accordion .accordion-button {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  width: auto !important;
  color: #00529b;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.2px;
}
.about-home-section .accordion-section .accordion .accordion-button:focus {
  box-shadow: none !important;
}
.about-home-section .accordion-section .accordion .accordion-button::after {
  margin-right: 10px;
}
.about-home-section .accordion-section .accordion .accordion-body {
  border: 0;
  background: transparent !important;
}
.about-home-section .img {
  position: absolute;
  left: 0;
  bottom: 0;
}
@media only screen and (max-width: 991px) {
  .about-section {
    padding: 70px 50px;
    min-height: 335px;
    max-height: 1140px;
    background: #FFD700;
  }
  .about-section .accordion-section {
    max-height: 319px;
    height: 319px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 1919px) {
  .about-section .img {
    display: none;
  }
}
.school-home-banner {
  margin-top: 1rem;
}
.school-home-banner .info-section .content .title {
  color: #ff6700;
  max-width: 494px;
  font-size: 2.5rem;
}
.school-home-banner .info-section .content .subtitle {
  font-size: 1.125rem;
  color: #606060;
  max-width: 487px;
}
.school-home-banner .info-section .content .button {
  font-size: 1.125rem;
  width: 200px;
  height: 55px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 13px;
}
.school-home-banner .info-section .content .zoni-img {
  height: 11rem !important;
}
@media only screen and (max-width: 1199px) and (min-width: 300px) {
  .school-home-banner .info-section .content .zoni-img {
    height: 7rem !important;
    width: 20rem;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 400px) {
  .school-home-banner .video-section .banner-video {
    margin: auto;
    height: 320px;
  }
}
@media only screen and (max-width: 400px) and (min-width: 300px) {
  .school-home-banner .video-section .banner-video {
    margin: auto;
    height: 250px;
  }
}
@media only screen and (min-width: 1200px) {
  .school-banner .video-section .banner-video {
    margin-right: auto;
    height: 500px;
  }
}
@media only screen and (max-width: 992px) {
  .school-banner .info-section .content {
    text-align: center;
  }
  .school-banner .info-section .content .title {
    max-width: 100%;
  }
  .school-banner .info-section .content .subtitle {
    max-width: 100%;
  }
  .school-banner .info-section .content .button {
    margin-bottom: 32px;
    width: 175px;
    height: 45px;
  }
}
.core-home-values {
  background-color: #0074e4;
  padding: 5.25rem 0 3.25rem 0;
}
.core-home-values .image-section {
  text-align: center;
  margin-top: -150px;
}
@media screen and (max-width: 992px) {
  .core-home-values .image-section {
    margin-top: -110px;
  }
  .core-home-values .image-section .logo {
    width: 90.33px;
  }
}
.core-home-values .content-section {
  margin: 6rem 0 6rem 0;
}
.core-home-values .content-section .title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 2.5rem;
}
.core-home-values .content-section .subtitle {
  color: #fff;
  font-size: 0.875rem;
  padding-top: 16px;
  font-weight: 400;
}
.core-home-values .content-section .link {
  color: #fff;
  font-size: 1rem;
  text-transform: capitalize;
}
.core-home-values .content-section .item-description {
  margin-bottom: 30px;
}
.core-home-values .content-section .item-description .item-value {
  color: #fff;
  font-size: 1.25rem;
  text-decoration-line: underline;
  padding-right: 16px;
  font-weight: 400;
}
.core-home-values .content-section .description-values {
  background-color: #fff;
  border-radius: 25px;
  opacity: 0.8;
}
.core-home-values .content-section .description-values .description {
  padding: 10px;
}
.core-home-values .content-section .description-body {
  padding-right: 46px;
}
@media (max-width: 991px) {
  .core-home-values .content-section {
    text-align: center;
  }
}
.core-home-values .mobile-carrousel {
  height: 4.5rem !important;
}
.core-home-values .accordion-item {
  border: none;
}
.core-home-values .accordion-button {
  background: none !important;
  border: none !important;
  color: #fff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  text-decoration-line: underline;
  text-transform: capitalize;
}
.core-home-values .accordion-button:focus {
  box-shadow: none !important;
}
.core-home-values .accordion-body {
  background: none !important;
  border: none !important;
  border-radius: 25px !important;
  opacity: 0.8 !important;
  background: #fff !important;
  color: #000;
  box-sizing: none;
}
.core-home-values .accordion {
  --bs-accordion-bg: none !important;
  --bs-accordion-border-color: none !important;
}
.core-home-values .accordion-button::after {
  transform: none !important;
  transition: none !important;
  width: 0;
}
.experienced-home-teachers {
  background-color: #0074E4;
  padding: 50px 0;
}
.experienced-home-teachers .content-section .title {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  font-weight: 500;
  text-transform: capitalize;
}
.experienced-home-teachers .content-section .subtitle {
  color: #fff;
  font-size: 0.875rem;
}
.experienced-home-teachers .content-section .link {
  color: #fff;
  font-size: 0.75rem;
  text-transform: capitalize;
}
@media only screen and (min-width: 768px) {
  .experienced-home-teachers .content-section .link {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .experienced-home-teachers .content-section {
    margin-top: 20px;
    text-align: center;
  }
  .experienced-home-teachers .content-section .title {
    text-align: center;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 2.25rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  .experienced-home-teachers .content-section .subtitle {
    color: #fff;
    font-size: 0.875rem;
  }
  .experienced-home-teachers .img-teachers {
    width: auto;
    height: 50%;
  }
}
.school-home-questions {
  background: #F1F8FF;
  padding: 126px 0;
  margin-bottom: 5rem;
}
.school-home-questions .title {
  font-size: 2.25rem;
  color: #0056C0;
}
.school-home-questions .nav {
  border-bottom: #004a93;
}
.school-home-questions .nav .nav-link {
  font-size: 1.216rem;
  color: #708aa7;
  border: none;
  background: none;
}
.school-home-questions .nav .active {
  color: #004a93 !important;
  border: none;
  border-bottom: 2px solid #004a93 !important;
}
.school-home-questions .nav .nav-link:hover {
  color: #004a93 !important;
  border: white;
}
.school-home-questions .nav .nav-link:focus {
  color: #004a93 !important;
  border: none;
  border-bottom: 2px solid #004a93 !important;
}
.school-home-questions .nav h2 {
  color: #2C49A1;
}
.school-home-questions .accordion-item {
  border-bottom: 0.5px solid #b8b8b866;
}
.school-home-questions .accordion-button {
  border: none !important;
  background: none !important;
  color: black !important;
  font-weight: 200;
}
.school-home-questions .accordion-button:focus {
  box-shadow: none !important;
}
.school-home-questions .accordion-body {
  background: none !important;
  border: none !important;
}
.school-home-questions .accordion {
  --bs-accordion-bg: none !important;
  --bs-accordion-border-color: none !important;
  --bs-accordion-color: #727272 !important;
}
@media screen and (max-width: 767px) {
  .school-home-questions .accordion-collapse {
    padding-inline: 14px !important;
  }
}
.mission-home-vision-section {
  padding-bottom: 0.5rem;
  position: relative;
  background-color: #F1F8FF;
}
.mission-home-vision-section .img-section {
  position: sticky;
  top: 60px;
  margin-top: -150px;
}
.mission-home-vision-section .img-section .logo {
  height: 9.875rem;
}
@media screen and (max-width: 767px) {
  .mission-home-vision-section .img-section {
    margin-top: 10px;
  }
}
.mission-home-vision-section .subtitle {
  font-size: 20px;
  color: #00529b;
  font-weight: 550;
}
.mission-home-vision-section p {
  font-size: 1.125rem;
  color: #000;
}
.mission-home-vision-section .description {
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
}
.mission-home-vision-section .description-title {
  font-weight: 550;
  font-size: 1rem;
}
.mission-home-vision-section .img-students {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (min-width: 767px) and (max-width: 1199px) {
  .mission-home-vision-section .img-students {
    width: auto;
    height: 40%;
  }
}
@media screen and (max-width: 768px) {
  .accordion .accordion-button {
    font-weight: 550 !important;
  }
}
@media only screen and (max-width: 767px) {
  .mission-vision-section .img-section {
    text-align: center;
  }
  .mission-vision-section .content-section {
    padding-top: 4.125rem;
    padding-bottom: 24px;
  }
  .mission-vision-section .content-section .subtitle {
    text-align: center;
  }
  .mission-vision-section .content-section p {
    text-align: center;
  }
  .mission-vision-section .img-students {
    display: none;
  }
}
.programs-home-section {
  background-color: #F1F8FF;
  padding-top: 6.563rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 992px) {
  .programs-home-section {
    padding-bottom: 1rem;
  }
}
.programs-home-section .info-section {
  position: relative;
}
.programs-home-section .info-section .title {
  text-align: center;
  font-size: 2.5rem;
  color: #00529b;
  text-transform: capitalize;
}
.programs-home-section .info-section .subtitle {
  margin-top: 8px;
  text-align: center;
  color: #5B5A5A;
  font-size: 1.125rem;
  margin-bottom: 32px;
}
.programs-home-section .info-section .programs {
  overflow: scroll !important;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
  padding-bottom: 70px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.programs-home-section .info-section .programs .program {
  margin-top: 6.25rem;
  border-radius: 20px;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.programs-home-section .info-section .programs .program .program-img-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 15.313rem;
  text-align: center;
}
.programs-home-section .info-section .programs .program .program-img-container .program-video {
  position: relative;
  height: 18.75rem;
  margin-top: -3.438rem;
  margin-right: -0.75rem;
}
.programs-home-section .info-section .programs .program .program-img-container.first-card {
  background-color: #0074E4;
}
.programs-home-section .info-section .programs .program .program-img-container.second-card {
  background-color: #FFD700;
}
.programs-home-section .info-section .programs .program .program-img-container.third-card {
  background-color: #FF6D42;
}
.programs-home-section .info-section .programs .program .program-img-container.fourth-card {
  background-color: #F09000;
}
.programs-home-section .info-section .programs .program .program-img-container.fifth-card {
  background-color: #FFD700;
}
.programs-home-section .info-section .programs .program .program-value {
  color: #151515;
  font-size: 0.96rem;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F8F8F8;
}
.programs-home-section .info-section .programs .program .program-credits {
  height: 41px;
  padding: 9px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27.2px;
}
.programs-home-section .info-section .programs .program .program-credits.first-card {
  background: #EDCD25;
}
.programs-home-section .info-section .programs .program .program-credits.second-card {
  background: #0074E4;
}
.programs-home-section .info-section .programs .program .program-credits.third-card {
  background: #EF3E42;
}
.programs-home-section .info-section .programs .program .program-credits.fourth-card {
  background: #FF6D42;
}
.programs-home-section .info-section .programs .program .program-credits.fifth-card {
  background: #FF6D42;
}
.programs-home-section .info-section .programs .program .program-title {
  font-size: 1.5rem;
  color: #151515;
  max-width: 300px;
}
.programs-home-section .info-section .programs .program .program-description {
  font-size: 0.938rem;
  color: #5B5A5A;
  margin-bottom: 60px;
}
.programs-home-section .info-section .programs .program .program-button {
  width: 185px;
  border-radius: 7px;
  background: #004a93;
  color: white;
  padding-top: 5px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 30.6px;
  position: absolute;
  bottom: -21px;
}
.programs-home-section .info-section .left-icon {
  position: absolute;
  right: 20px;
  top: 55%;
}
.programs-home-section .info-section .left-icon .left-icon-img {
  width: 40px;
}
.programs-home-section .info-section .right-icon {
  position: absolute;
  left: 10px;
  top: 55%;
}
.programs-home-section .info-section .right-icon .right-icon-img {
  width: 40px;
}
.programs-home-section .info-section .programs::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 992px) {
  .programs-section .info-section {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .programs-section .info-section .left-icon {
    top: 60%;
    display: none;
  }
  .programs-section .info-section .right-icon {
    top: 60%;
    display: none;
  }
}
@media screen and (max-width: 992px) {
  html {
    font-size: 12px !important;
    overflow-x: hidden;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  html {
    font-size: 14px !important;
    overflow-x: hidden;
  }
}
.school-page .discover .zoni-icon {
  margin-top: -10rem;
}
.study-abroad-hero {
  background: url('/assets/zoni_edu/assets/img/study_abroad/hero_img.jpg') no-repeat center center;
  background-size: cover;
  background-position: center;
  color: #00529b;
  /* Blue text color */
  height: 68dvh;
  /* Full viewport height */
  display: flex;
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
}
.study-abroad-hero .title {
  width: 50%;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .study-abroad-hero {
    height: 30dvh;
    /* Smaller height on mobile devices */
    padding: 15px;
    /* Added padding for smaller screens */
    text-align: start;
    align-items: start;
    justify-content: start;
  }
  .study-abroad-hero .title {
    width: 40%;
  }
}
@media (min-width: 768px) {
  .study-abroad-hero .title {
    font-size: 3.5rem !important;
  }
}
.study-abroad-our-mission h2 {
  font-size: 2rem;
  /* Adjust size as necessary */
  color: #00274A;
}
.study-abroad-our-mission .gradient-text {
  color: #00274A;
  background-image: -webkit-linear-gradient(-270deg, #00274A 0%, #0074E4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
.study-abroad-our-mission h4 {
  color: #000;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.study-abroad-our-mission p {
  color: #004a93;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: justify;
}
.study-abroad-our-mission .btn-primary {
  background-color: #0074E4;
  /* Match button color to the theme */
  border: none;
  /* Remove default border */
  padding: 0.75rem 4rem;
}
.study-abroad-our-mission {
  padding: 5rem 0;
}
/* Responsive adjustments */
@media (min-width: 768px) {
  .study-abroad-our-mission .title {
    width: 60%;
  }
}
.course-section {
  background: linear-gradient(-180deg, #0074E4 0%, #0768C7 100%);
  margin-bottom: 1rem;
  position: relative;
  padding-top: 20px;
}
.course-section .title {
  font-weight: 600;
}
.course-section .card-text {
  color: #004A93;
}
.course-section .card {
  border: none;
  /* Remove borders from cards */
  border-radius: 35px;
}
.course-section .card-body {
  height: 100px;
  /* Fixed height for consistency */
}
.course-section .card-img-top {
  height: 200px;
  /* Fixed height for consistency */
  -o-object-fit: cover;
     object-fit: cover;
  /* Ensure images cover the card image area */
  -o-object-position: 50% 15%;
     object-position: 50% 15%;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
}
@media (max-width: 1380px) and (min-width: 768px) {
  .course-section .card-title {
    font-size: 1.25rem !important;
  }
}
@media (min-width: 768px) {
  .course-section .title {
    max-width: 95%;
  }
}
.course-section .subtitle {
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .course-section .container {
    padding: 5rem 0;
    position: relative;
    z-index: 2;
    /* ensures the content is above the red line */
  }
  .course-section .red-line {
    position: absolute;
    top: 7rem;
    right: 0;
    width: 4rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .course-section .row:first-of-type {
    margin-bottom: 5rem;
  }
  .course-section .card-img-top {
    height: 250px;
    /* Fixed height for consistency */
    -o-object-fit: cover;
       object-fit: cover;
    /* Ensure images cover the card image area */
    -o-object-position: 50% 15%;
       object-position: 50% 15%;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
  }
  .course-section .card {
    border: none;
    /* Remove borders from cards */
    border-radius: 35px;
    min-height: 400px;
  }
  .course-section .card-body {
    height: auto;
    /* Fixed height for consistency */
  }
}
.course-section .card-title {
  color: #00529b;
  /* Blue text for titles inside cards */
}
.course-section .card#course1 .card-title::after {
  color: #00529b;
  margin-right: 2px;
}
.course-section .card#course2,
.course-section .card#course3,
.course-section .card#course4 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.course-section .card#course2 {
  background-image: url('/assets/zoni_edu/assets/img/study_abroad/course2.png');
}
.course-section .card#course2 .card-body {
  background: linear-gradient(-180deg, rgba(0, 128, 255, 0) 0%, #FFFFFF 100%);
  border-radius: 35px;
}
.course-section .card#course3 {
  background-image: url('/assets/zoni_edu/assets/img/study_abroad/course3.png');
}
.course-section .card#course3 .card-body {
  background: linear-gradient(-180deg, rgba(0, 128, 255, 0) 0%, #FFFFFF 100%);
  border-radius: 35px;
}
.course-section .card#course4 {
  background-image: url('/assets/zoni_edu/assets/img/study_abroad/course4.png');
}
.course-section .card#course4 .card-body {
  background: linear-gradient(-180deg, rgba(0, 128, 255, 0) 0%, #FFFFFF 100%);
  border-radius: 35px;
}
.course-section .btn-light {
  color: #0074E4;
  border-color: #0074E4;
}
.course-section .btn-light:hover {
  color: #fff;
  background-color: #0074E4;
}
.study-abroad-places {
  position: relative;
  padding-top: 20px;
  z-index: 2;
}
@media (min-width: 768px) {
  .study-abroad-places .container {
    position: relative;
    z-index: 2;
    /* ensures the content is above the red line */
  }
  .study-abroad-places .red-line {
    position: absolute;
    top: 6.5rem;
    right: 0;
    width: 4rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
.study-abroad-places h2 {
  font-size: 2rem;
  /* Adjust size as necessary */
  color: #004a93;
}
.study-abroad-places p {
  color: #000;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.study-abroad-places .btn-primary {
  background-color: #0074E4;
  /* Match button color to the theme */
  border: none;
  /* Remove default border */
  padding: 0.75rem 4rem;
}
.study-abroad-places {
  padding: 5rem 0;
}
/* Responsive adjustments */
@media (min-width: 768px) {
  .study-abroad-places .title {
    width: 40%;
  }
}
.study-abroad-campuses {
  padding-bottom: 40px;
}
.study-abroad-campuses .campus-spline {
  height: 500px;
  width: 100%;
  overflow: hidden;
  cursor: grab;
}
.study-abroad-campuses .campus-spline:active {
  cursor: grabbing;
}
.study-abroad-campuses .campus-spline:focus {
  cursor: grabbing;
}
.study-abroad-campuses .title {
  background: linear-gradient(-90deg, #0074E4 58.88%, #004a93 73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-bottom: 3rem;
}
.study-abroad-campuses h3 {
  color: #004A93;
  border: 1px solid #004a93;
  border-radius: 25px;
}
.study-abroad-why-zoni {
  background: #00529b;
  padding: 40px 0;
}
.study-abroad-why-zoni .white-bg {
  border-radius: 30px;
  background-color: #F6FAFF;
}
@media (min-width: 768px) {
  .study-abroad-why-zoni .container {
    max-width: 100%;
    /* Limiting container width for aesthetics */
    margin-right: -2rem;
  }
  .study-abroad-why-zoni .white-bg {
    border-radius: 70px;
    background-color: #F6FAFF;
  }
}
@media (max-width: 425px) {
  .study-abroad-why-zoni .container {
    max-width: 100%;
    /* Limiting container width for aesthetics */
    margin-right: -2rem;
  }
}
@media (min-width: 1080px) {
  .study-abroad-why-zoni .container {
    max-width: 95%;
    /* Limiting container width for aesthetics */
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  .study-abroad-why-zoni .card {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .study-abroad-why-zoni .text-muted {
    max-width: 800px;
  }
}
.study-abroad-why-zoni .card {
  border-radius: 50px;
  /* Rounded corners for the card */
  overflow: hidden;
  /* Ensures no content spills out */
  background-color: #F6FAFF;
  /* Light background for the card */
  padding: 50px 25px;
}
.study-abroad-why-zoni .text-muted {
  margin: 1rem auto;
  color: #004a93;
}
.study-abroad-why-zoni .btn-primary .btn-outline-primary {
  margin-top: 20px;
}
.study-abroad-why-zoni .btn-outline-primary {
  border-color: #00529b;
  /* Custom border color */
  color: #00529b;
  /* Text color */
}
.study-abroad-why-zoni .btn-outline-primary:hover {
  background-color: #00529b;
  /* Background color on hover */
  color: #ffffff;
  /* Text color on hover */
}
.study-abroad-why-zoni .img-fluid {
  border-radius: 5px;
  /* Slight rounding of the image edges */
  max-height: 300px;
  /* Limit the video thumbnail's height */
}
.study-abroad-why-zoni video {
  border-radius: 20px;
}
.admissions-section {
  background-color: #00529b;
  /* Dark blue background */
  padding-bottom: 40px;
}
.admissions-section .section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}
.admissions-section .accordion ::-webkit-scrollbar-thumb {
  border-radius: 5rem;
}
.admissions-section .section-subtitle {
  color: #fff;
}
.admissions-section .accordion-item {
  background-color: #00529B;
}
.admissions-section .card {
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 20px;
  padding-bottom: 0;
}
.admissions-section .card1 {
  background: url('/assets/zoni_edu/assets/img/study_abroad/requirement-1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 23rem;
  border-radius: 1rem;
}
.admissions-section .card2 {
  background: url('/assets/zoni_edu/assets/img/study_abroad/requirement-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 23rem;
  border-radius: 1rem;
}
.admissions-section .card3 {
  background: url('/assets/zoni_edu/assets/img/study_abroad/requirement-3.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 23rem;
  border-radius: 1rem;
}
.admissions-section .card4 {
  background: url('/assets/zoni_edu/assets/img/study_abroad/requirement-4.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 23rem;
  border-radius: 1rem;
}
@media (min-width: 768px) {
  .admissions-section .card-text {
    min-height: 225px;
  }
  .admissions-section .card-text h3 {
    min-height: 90px;
  }
}
@media (max-width: 768px) {
  .admissions-section .card-text h3 {
    width: 75%;
  }
}
.admissions-section .card-text h3 {
  color: #fff !important;
}
.admissions-section .card-image {
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 175px;
  border-radius: 10px;
}
.admissions-section .card-body {
  padding-bottom: 0 !important;
}
.admissions-section .card h3 {
  font-size: 1.5rem;
  font-weight: bold;
}
.admissions-section .card .btn {
  background-color: #fff;
  color: #00529b;
  border: none;
  padding: 10px 3rem;
}
.admissions-section .card .btn:hover {
  background-color: #F6FAFF !important;
}
.pre-arrival-section {
  background-color: #F6FAFF;
  /* Light grey background */
  color: #151515;
  position: relative;
  z-index: 2;
}
.pre-arrival-section .pre-accordion {
  margin-top: -1rem;
}
.pre-arrival-section .btn-info:focus {
  background-color: #CCE3FA !important;
  border: #CCE3FA !important;
}
@media (min-width: 768px) {
  .pre-arrival-section .container {
    position: relative;
    z-index: 2;
    /* ensures the content is above the red line */
  }
  .pre-arrival-section .red-line {
    position: absolute;
    top: 6.5rem;
    right: 0;
    width: 4rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .pre-arrival-section .pre-accordion {
    margin-top: -5rem;
  }
}
.pre-arrival-section .section-title {
  color: #004a93;
  /* Dark blue color */
  font-size: 2.5rem;
  font-weight: bold;
  width: 50%;
}
.pre-arrival-section .section-subtitle {
  color: #00529b;
  /* Dark blue color */
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.pre-arrival-section .section-text {
  color: #151515;
  font-size: 1rem;
  margin-bottom: 10px;
}
.pre-arrival-section .btn-info {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}
.pre-arrival-section .btn-info {
  min-width: 200px;
}
.pre-arrival-section .btn-info:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.pre-arrival-section .btn-info:hover:after {
  color: #EF3E42;
  margin-right: 2px;
}
.pre-arrival-section .btn-info:after {
  color: #EF3E42;
  margin-right: 2px;
}
.pre-arrival-section .img-fluid {
  max-width: 80%;
  height: auto;
}
.part-time-section {
  background-color: #F6FAFF;
  /* Light grey background */
  color: #151515;
}
.part-time-section p {
  font-size: 1rem;
  line-height: 1.6;
}
.part-time-section ul {
  list-style-type: disc;
  padding-right: 20px;
}
.part-time-section ul li {
  margin-bottom: 10px;
}
.full-time-section {
  background-color: #F6FAFF;
  /* Light grey background */
  color: #151515;
}
.full-time-section .info-section h5 {
  color: #004a93;
  /* Dark blue color */
  font-weight: bold;
}
.full-time-section .info-section p,
.full-time-section .info-section ul {
  font-size: 1rem;
  line-height: 1.6;
}
.full-time-section .info-section ul {
  list-style-type: disc;
  padding-right: 20px;
}
.full-time-section .info-section ul ul {
  list-style-type: circle;
}
.part-time-section .img-fluid,
.full-time-section .img-fluid {
  max-width: 100%;
  height: auto;
}
.tips-section {
  background-color: #F6FAFF;
  /* Light grey background */
  color: #151515;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .tips-section .container {
    position: relative;
    z-index: 2;
    /* ensures the content is above the red line */
  }
  .tips-section .red-line {
    position: absolute;
    top: 6.5rem;
    right: 0;
    width: 4rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
.tips-section .section-title {
  color: #00529b;
  /* Dark blue color */
  font-size: 2.5rem;
  font-weight: bold;
}
.tips-section .section-subtitle {
  color: #004a93;
  /* Dark blue color */
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: normal;
}
.tips-section p strong {
  color: #004a93;
  /* Dark blue color */
}
.tips-section .info-section p,
.tips-section .info-section ul {
  color: #004a93;
  /* Dark blue color */
  font-size: 1rem;
  line-height: 1.6;
}
.tips-section .info-section ul {
  list-style-type: disc;
  padding-right: 20px;
}
.tips-section .img-fluid {
  max-width: 100%;
  height: auto;
}
.tips-section .useful-websites {
  border-radius: 10px;
  color: #004a93;
  background: #fff;
  padding: 2rem;
}
.tips-section .useful-websites h5 {
  font-size: 1.25rem;
  color: #004a93;
  /* Dark blue color */
  margin-bottom: 10px;
}
.tips-section .useful-websites ul {
  list-style-type: none;
  padding-right: 0;
}
.tips-section .useful-websites ul li {
  margin-bottom: 10px;
}
.tips-section .useful-websites ul li a {
  color: #004a93;
  text-decoration: none;
}
.tips-section .useful-websites ul li a:hover {
  text-decoration: underline;
}
.goals {
  background: #004a93;
  padding-bottom: 4rem;
  margin-top: 5rem;
}
@media screen and (min-width: 1023px) {
  .goals .container-fluid {
    padding: 0 10rem;
  }
}
.goals .icon-layer {
  position: relative;
  bottom: 5rem;
  text-align: center;
}
.goals .card {
  background: none;
  text-align: center;
  color: white;
}
.goals .card .card-header {
  border-bottom: none;
}
.goals .card .card-footer {
  border-top: none;
}
.goals .card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.goals .card .card-body h2,
.goals .card .card-body h5,
.goals .card .card-body p {
  margin: 0;
}
.goals .card .card-body h5 {
  font-weight: bold;
}
.goals-recommendation {
  background-color: #F6FAFF;
}
.goals-recommendation p span {
  text-decoration: underline;
}
.goals-recommendation p {
  color: #1C5499;
}
.goals-recommendation .badge img {
  width: 75%;
}
.new-york-hero .title {
  font-size: 5rem;
  /* Larger font size */
  font-weight: bold;
  width: 100%;
  color: #004a93;
  /* White text color */
}
.new-york-hero {
  height: auto;
  /* Smaller height on mobile devices */
  padding: 15px;
  /* Added padding for smaller screens */
  background-size: cover;
  width: 100%;
}
/* Responsive adjustments */
@media (min-width: 768px) {
  .new-york-hero {
    padding: 15px;
    /* Added padding for smaller screens */
    background-size: cover;
    width: 100%;
  }
  .new-york-hero .title {
    font-size: 3rem;
    /* Smaller font size on mobile devices */
  }
}
.study-brooklyn-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-brooklyn-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-brooklyn-mobile-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-brooklyn-section .red-line {
  position: absolute;
  top: 3rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-brooklyn-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-brooklyn-section .main-text {
  color: black;
  font-size: 14px;
}
.study-brooklyn-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-brooklyn-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-brooklyn-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-brooklyn-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-brooklyn-section .info-text {
  color: black;
  font-size: 14px;
}
.study-brooklyn-section .image-section {
  position: relative;
}
.study-brooklyn-section .buttons-section {
  text-align: center;
}
.study-brooklyn-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-brooklyn-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-brooklyn-section .info-buttons li {
  margin-bottom: 10px;
}
.study-brooklyn-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-brooklyn-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-brooklyn-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-brooklyn-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-brooklyn-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-brooklyn-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-brooklyn-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-brooklyn-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-brooklyn-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-brooklyn-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-brooklyn-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-brooklyn-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-brooklyn-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-brooklyn-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-brooklyn-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
  max-height: 166.05px;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-brooklyn-section .red-line {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-brooklyn-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-brooklyn-section {
    padding: 30px 15px;
  }
  .study-brooklyn-section .main-title {
    font-size: 2rem;
  }
  .study-brooklyn-mobile-section .main-title {
    font-size: 2rem;
  }
  .study-brooklyn-section .subtitle {
    font-size: 1.5rem;
  }
  .study-brooklyn-section .highlight {
    font-size: 1.8rem;
  }
  .study-brooklyn-section .info-section {
    margin-bottom: 10px;
  }
  .study-brooklyn-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-brooklyn-section .red-line {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-brooklyn-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-brooklyn-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-brooklyn-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-brooklyn-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-brooklyn-section .close:hover,
.study-brooklyn-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-flushing-section {
  color: #CCE3FA;
}
.study-flushing-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-flushing-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-flushing-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-flushing-section .main-text {
  color: black;
  font-size: 14px;
}
.study-flushing-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-flushing-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-flushing-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-flushing-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-flushing-section .info-text {
  color: black;
  font-size: 14px;
}
.study-flushing-section .image-section {
  position: relative;
}
.study-flushing-section .info-buttons {
  text-align: center;
}
.study-flushing-section .buttons-section {
  text-align: center;
}
.study-flushing-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-flushing-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-flushing-section .info-buttons li {
  margin-bottom: 10px;
}
.study-flushing-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-flushing-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-flushing-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-flushing-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-flushing-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
}
.study-flushing-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
}
.study-flushing-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.study-flushing-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-flushing-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-flushing-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-flushing-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-flushing-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-flushing-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-flushing-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-flushing-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-flushing-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-flushing-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-flushing-section {
    padding: 30px 15px;
  }
  .study-flushing-section .main-title {
    font-size: 2rem;
  }
  .study-flushing-section .subtitle {
    font-size: 1.5rem;
  }
  .study-flushing-section .highlight {
    font-size: 1.8rem;
  }
  .study-flushing-section .info-section {
    margin-bottom: 10px;
  }
  .study-flushing-section .btn-primary {
    font-size: 1rem;
  }
  .study-flushing-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-flushing-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-flushing-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-flushing-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-flushing-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-flushing-section .close:hover,
.study-flushing-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-hempstead-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-hempstead-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-hempstead-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-hempstead-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-hempstead-section .main-text {
  color: black;
  font-size: 14px;
}
.study-hempstead-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-hempstead-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-hempstead-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-hempstead-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-hempstead-section .info-text {
  color: black;
  font-size: 14px;
}
.study-hempstead-section .image-section {
  position: relative;
}
.study-hempstead-section .buttons-section {
  text-align: center;
}
.study-hempstead-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-hempstead-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-hempstead-section .info-buttons li {
  margin-bottom: 10px;
}
.study-hempstead-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-hempstead-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-hempstead-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-hempstead-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-hempstead-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-hempstead-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-hempstead-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-hempstead-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-hempstead-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-hempstead-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-hempstead-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-hempstead-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-hempstead-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-hempstead-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-hempstead-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-hempstead-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-hempstead-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-hempstead-section {
    padding: 30px 15px;
  }
  .study-hempstead-section .main-title {
    font-size: 2rem;
  }
  .study-hempstead-section .subtitle {
    font-size: 1.5rem;
  }
  .study-hempstead-section .highlight {
    font-size: 1.8rem;
  }
  .study-hempstead-section .info-section {
    margin-bottom: 10px;
  }
  .study-hempstead-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-hempstead-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-hempstead-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-hempstead-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-hempstead-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-hempstead-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-hempstead-section .close:hover,
.study-hempstead-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-jackson-heights-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-jackson-heights-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-jackson-heights-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-jackson-heights-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-jackson-heights-section .main-text {
  color: black;
  font-size: 14px;
}
.study-jackson-heights-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-jackson-heights-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-jackson-heights-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-jackson-heights-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-jackson-heights-section .info-text {
  color: black;
  font-size: 14px;
}
.study-jackson-heights-section .image-section {
  position: relative;
}
.study-jackson-heights-section .buttons-section {
  text-align: center;
}
.study-jackson-heights-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-jackson-heights-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-jackson-heights-section .info-buttons li {
  margin-bottom: 10px;
}
.study-jackson-heights-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-jackson-heights-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-jackson-heights-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-jackson-heights-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-jackson-heights-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
}
.study-jackson-heights-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
}
.study-jackson-heights-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.study-jackson-heights-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-jackson-heights-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-jackson-heights-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-jackson-heights-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-jackson-heights-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-jackson-heights-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-jackson-heights-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-jackson-heights-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-jackson-heights-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-jackson-heights-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-jackson-heights-section {
    padding: 30px 15px;
  }
  .study-jackson-heights-section .main-title {
    font-size: 2rem;
  }
  .study-jackson-heights-section .subtitle {
    font-size: 1.5rem;
  }
  .study-jackson-heights-section .highlight {
    font-size: 1.8rem;
  }
  .study-jackson-heights-section .info-section {
    margin-bottom: 10px;
  }
  .study-jackson-heights-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-jackson-heights-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-jackson-heights-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-jackson-heights-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-jackson-heights-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-jackson-heights-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-jackson-heights-section .close:hover,
.study-jackson-heights-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-manhattan-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-manhattan-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.font-italic {
  font-style: italic;
}
.study-manhattan-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-manhattan-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-manhattan-section .main-text {
  color: black;
  font-size: 14px;
}
.study-manhattan-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-manhattan-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-manhattan-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-manhattan-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-manhattan-section .info-text {
  color: black;
  font-size: 14px;
}
.study-manhattan-section .image-section {
  position: relative;
}
.study-manhattan-section .buttons-section {
  text-align: center;
}
.study-manhattan-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-manhattan-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-manhattan-section .info-buttons li {
  margin-bottom: 10px;
}
.study-manhattan-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-manhattan-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-manhattan-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-manhattan-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-manhattan-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-manhattan-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-manhattan-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-manhattan-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-manhattan-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-manhattan-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-manhattan-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-manhattan-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-manhattan-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-manhattan-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-manhattan-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-manhattan-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-manhattan-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-manhattan-section {
    padding: 30px 15px;
  }
  .study-manhattan-section .main-title {
    font-size: 2rem;
  }
  .study-manhattan-section .subtitle {
    font-size: 1.5rem;
  }
  .study-manhattan-section .highlight {
    font-size: 1.8rem;
  }
  .study-manhattan-section .info-section {
    margin-bottom: 10px;
  }
  .study-manhattan-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-manhattan-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-manhattan-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-manhattan-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-manhattan-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-manhattan-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-manhattan-section .close:hover,
.study-manhattan-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-port-chester-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-port-chester-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-port-chester-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-port-chester-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-port-chester-section .main-text {
  color: black;
  font-size: 14px;
}
.study-port-chester-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-port-chester-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-port-chester-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-port-chester-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-port-chester-section .info-text {
  color: black;
  font-size: 14px;
}
.study-port-chester-section .image-section {
  position: relative;
}
.study-port-chester-section .buttons-section {
  text-align: center;
}
.study-port-chester-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-port-chester-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-port-chester-section .info-buttons li {
  margin-bottom: 10px;
}
.study-port-chester-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-port-chester-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-port-chester-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-port-chester-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-port-chester-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
}
.study-port-chester-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
}
.study-port-chester-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.study-port-chester-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-port-chester-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-port-chester-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-port-chester-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-port-chester-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-port-chester-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-port-chester-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-port-chester-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-port-chester-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-port-chester-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-port-chester-section {
    padding: 30px 15px;
  }
  .study-port-chester-section .main-title {
    font-size: 2rem;
  }
  .study-port-chester-section .subtitle {
    font-size: 1.5rem;
  }
  .study-port-chester-section .highlight {
    font-size: 1.8rem;
  }
  .study-port-chester-section .info-section {
    margin-bottom: 10px;
  }
  .study-port-chester-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-port-chester-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-port-chester-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-port-chester-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-port-chester-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-port-chester-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-port-chester-section .close:hover,
.study-port-chester-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.new-jersey-hero .title {
  font-size: 3rem;
  /* Larger font size */
  font-weight: bold;
  width: 100%;
  color: #004a93;
  /* White text color */
}
.new-jersey-hero {
  height: auto;
  /* Smaller height on mobile devices */
  padding: 15px;
  /* Added padding for smaller screens */
  background-size: cover;
  width: 100%;
}
/* Responsive adjustments */
@media (min-width: 768px) {
  .new-jersey-hero {
    padding: 15px;
    /* Added padding for smaller screens */
    background-size: cover;
    width: 100%;
  }
  .new-jersey-hero .title {
    font-size: 5rem;
    /* Larger font size */
    font-weight: bold;
    width: 100%;
    color: #004a93;
  }
}
.study-elizabeth-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-elizabeth-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-elizabeth-section .red-line {
  position: absolute;
  top: 3rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-elizabeth-section .main-text {
  color: black;
  font-size: 14px;
}
.study-elizabeth-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-elizabeth-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-elizabeth-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-elizabeth-section .info-text {
  color: black;
  font-size: 14px;
}
.study-elizabeth-section .image-section {
  position: relative;
}
.study-elizabeth-section .buttons-section {
  text-align: center;
}
.study-elizabeth-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-elizabeth-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-elizabeth-section .info-buttons li {
  margin-bottom: 10px;
}
.study-elizabeth-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-elizabeth-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-elizabeth-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-elizabeth-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-elizabeth-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-elizabeth-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-elizabeth-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-elizabeth-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-elizabeth-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-elizabeth-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-elizabeth-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-elizabeth-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-elizabeth-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-elizabeth-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-elizabeth-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
  max-height: 166.05px;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-elizabeth-section .red-line {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-elizabeth-section {
    padding: 30px 15px;
  }
  .study-elizabeth-section .main-title {
    font-size: 2rem;
  }
  .study-elizabeth-section .subtitle {
    font-size: 1.5rem;
  }
  .study-elizabeth-section .highlight {
    font-size: 1.8rem;
  }
  .study-elizabeth-section .info-section {
    margin-bottom: 10px;
  }
  .study-elizabeth-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-elizabeth-section .red-line {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-elizabeth-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-elizabeth-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-elizabeth-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-elizabeth-section .close:hover,
.study-elizabeth-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-newark-section {
  color: #CCE3FA;
}
.study-newark-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-newark-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-newark-section .main-text {
  color: black;
  font-size: 14px;
}
.study-newark-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-newark-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-newark-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-newark-section .info-text {
  color: black;
  font-size: 14px;
}
.study-newark-section .image-section {
  position: relative;
}
.study-newark-section .info-buttons {
  text-align: center;
}
.study-newark-section .buttons-section {
  text-align: center;
}
.study-newark-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-newark-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-newark-section .info-buttons li {
  margin-bottom: 10px;
}
.study-newark-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-newark-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-newark-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-newark-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-newark-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
}
.study-newark-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
}
.study-newark-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.study-newark-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-newark-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-newark-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-newark-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-newark-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-newark-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-newark-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-newark-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-newark-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-newark-section {
    padding: 30px 15px;
  }
  .study-newark-section .main-title {
    font-size: 2rem;
  }
  .study-newark-section .subtitle {
    font-size: 1.5rem;
  }
  .study-newark-section .highlight {
    font-size: 1.8rem;
  }
  .study-newark-section .info-section {
    margin-bottom: 10px;
  }
  .study-newark-section .btn-primary {
    font-size: 1rem;
  }
  .study-newark-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-newark-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-newark-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-newark-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-newark-section .close:hover,
.study-newark-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-passaic-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-passaic-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-passaic-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-passaic-section .main-text {
  color: black;
  font-size: 14px;
}
.study-passaic-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-passaic-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-passaic-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-passaic-section .info-text {
  color: black;
  font-size: 14px;
}
.study-passaic-section .image-section {
  position: relative;
}
.study-passaic-section .buttons-section {
  text-align: center;
}
.study-passaic-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-passaic-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-passaic-section .info-buttons li {
  margin-bottom: 10px;
}
.study-passaic-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-passaic-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-passaic-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-passaic-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-passaic-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-passaic-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-passaic-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-passaic-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-passaic-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-passaic-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-passaic-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-passaic-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-passaic-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-passaic-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-passaic-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-passaic-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-passaic-section {
    padding: 30px 15px;
  }
  .study-passaic-section .main-title {
    font-size: 2rem;
  }
  .study-passaic-section .subtitle {
    font-size: 1.5rem;
  }
  .study-passaic-section .highlight {
    font-size: 1.8rem;
  }
  .study-passaic-section .info-section {
    margin-bottom: 10px;
  }
  .study-passaic-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-passaic-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-passaic-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-passaic-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-passaic-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-passaic-section .close:hover,
.study-passaic-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-palisades-park-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-palisades-park-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-palisades-park-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-palisades-park-section .main-text {
  color: black;
  font-size: 14px;
}
.study-palisades-park-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-palisades-park-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-palisades-park-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-palisades-park-section .info-text {
  color: black;
  font-size: 14px;
}
.study-palisades-park-section .image-section {
  position: relative;
}
.study-palisades-park-section .buttons-section {
  text-align: center;
}
.study-palisades-park-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-palisades-park-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-palisades-park-section .info-buttons li {
  margin-bottom: 10px;
}
.study-palisades-park-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-palisades-park-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-palisades-park-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-palisades-park-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-palisades-park-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
}
.study-palisades-park-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
}
.study-palisades-park-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.study-palisades-park-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-palisades-park-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-palisades-park-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-palisades-park-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-palisades-park-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-palisades-park-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-palisades-park-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-palisades-park-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-palisades-park-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-palisades-park-section {
    padding: 30px 15px;
  }
  .study-palisades-park-section .main-title {
    font-size: 2rem;
  }
  .study-palisades-park-section .subtitle {
    font-size: 1.5rem;
  }
  .study-palisades-park-section .highlight {
    font-size: 1.8rem;
  }
  .study-palisades-park-section .info-section {
    margin-bottom: 10px;
  }
  .study-palisades-park-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-palisades-park-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-palisades-park-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-palisades-park-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-palisades-park-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-palisades-park-section .close:hover,
.study-palisades-park-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-westnewyork-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-westnewyork-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-westnewyork-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-westnewyork-section .main-text {
  color: black;
  font-size: 14px;
}
.study-westnewyork-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-westnewyork-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-westnewyork-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-westnewyork-section .info-text {
  color: black;
  font-size: 14px;
}
.study-westnewyork-section .image-section {
  position: relative;
}
.study-westnewyork-section .buttons-section {
  text-align: center;
}
.study-westnewyork-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-westnewyork-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-westnewyork-section .info-buttons li {
  margin-bottom: 10px;
}
.study-westnewyork-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-westnewyork-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-westnewyork-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-westnewyork-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-westnewyork-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-westnewyork-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-westnewyork-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-westnewyork-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-westnewyork-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-westnewyork-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-westnewyork-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-westnewyork-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-westnewyork-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-westnewyork-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-westnewyork-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-westnewyork-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-westnewyork-section {
    padding: 30px 15px;
  }
  .study-westnewyork-section .main-title {
    font-size: 2rem;
  }
  .study-westnewyork-section .subtitle {
    font-size: 1.5rem;
  }
  .study-westnewyork-section .highlight {
    font-size: 1.8rem;
  }
  .study-westnewyork-section .info-section {
    margin-bottom: 10px;
  }
  .study-westnewyork-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-westnewyork-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-westnewyork-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-westnewyork-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-westnewyork-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-westnewyork-section .close:hover,
.study-westnewyork-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.florida-hero .title {
  font-size: 3rem;
  /* Larger font size */
  /* White text color */
}
.florida-hero {
  height: auto;
  /* Smaller height on mobile devices */
  padding: 15px;
  /* Added padding for smaller screens */
  background-size: cover;
  width: 100%;
}
/* Responsive adjustments */
@media (min-width: 768px) {
  .florida-hero {
    padding: 15px;
    /* Added padding for smaller screens */
    background-size: cover;
    width: 100%;
  }
  .florida-hero .hero {
    height: 100% !important;
    width: 100% !important;
  }
  .florida-hero .title {
    margin: auto;
    font-size: 5rem;
    /* Larger font size */
    font-weight: bold;
    width: 100%;
    color: #004a93;
    /* Smaller font size on mobile devices */
  }
}
.study-miami-section {
  color: #CCE3FA;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.study-miami-section .red-line {
  position: absolute;
  top: 3rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-miami-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.button-3d {
  position: relative;
  display: inline-block;
  margin: 20px;
}
.button-3d a {
  color: white;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  font-size: 19px;
  text-align: center;
  text-decoration: none;
  background-color: #0094FF;
  display: block;
  position: relative;
  padding: 15px 35px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-shadow: 0px 1px 0px #000;
  filter: dropshadow(color=#000, offx=0px, offy=1px);
  box-shadow: inset 0 1px 0 #FFE5C4, 0 10px 0 #0262a6;
  border-radius: 5px;
}
.button-3d a:active {
  top: 10px;
  background-color: #0293f7;
  box-shadow: inset 0 1px 0 #acdafa, inset 0 -3px 0 #0262a6;
}
.button-3d:after {
  content: "";
  height: 100%;
  width: 100%;
  padding: 4px;
  position: absolute;
  bottom: -15px;
  z-index: -1;
  border-radius: 15px;
  background-color: #2B1800;
  border-radius: 50rem;
}
.study-miami-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-miami-section .main-text {
  color: black;
  font-size: 14px;
}
.study-miami-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-miami-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-miami-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-miami-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-miami-section .info-text {
  color: black;
  font-size: 14px;
}
.study-miami-section .image-section {
  position: relative;
}
.study-miami-section .buttons-section {
  text-align: center;
}
.study-miami-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-miami-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-miami-section .info-buttons li {
  margin-bottom: 10px;
}
.study-miami-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-miami-section .info-buttons .btn-link:hover {
  background-color: #004a93 !important;
  color: #fff;
}
.study-miami-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #fff;
  margin-right: 2px;
}
.study-miami-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-miami-section .info-buttons .btn-link.active {
  background-color: #004a93 !important;
  color: #fff;
}
.study-miami-section .info-buttons .btn-link.active:after {
  content: ' ↓';
  color: #fff;
  margin-right: 2px;
}
.study-miami-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-miami-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-miami-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-miami-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
  max-height: 166.05px;
}
.study-miami-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-miami-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-miami-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-miami-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-miami-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-miami-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-miami-section .dropdown-toggle .show {
  background-color: #004A93 !important;
  color: #fff !important;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-miami-section .red-line {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-miami-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-miami-section {
    padding: 30px 15px;
  }
  .study-miami-section .main-title {
    font-size: 2rem;
  }
  .study-miami-section .subtitle {
    font-size: 1.5rem;
  }
  .study-miami-section .highlight {
    font-size: 1.8rem;
  }
  .study-miami-section .info-section {
    margin-bottom: 10px;
  }
  .study-miami-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-miami-section .red-line {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-miami-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-miami-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-miami-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-miami-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-miami-section .close:hover,
.study-miami-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
/* CONTACT INFO SECTION */
.study-miami-section .contact-details {
  font-size: 1rem;
  color: #151515;
}
.study-miami-section .hours-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.study-miami-section .hours-list li {
  margin-bottom: 5px;
}
.study-miami-section .icon-location:before,
.study-miami-section .icon-phone:before,
.study-miami-section .icon-clock:before {
  content: '\1F4CD';
  margin-left: 5px;
}
.study-miami-section .icon-phone:before {
  content: '\260E';
}
.study-miami-section .icon-clock:before {
  content: '\23F0';
}
.study-orlando-section {
  color: #CCE3FA;
}
.study-orlando-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-orlando-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.study-orlando-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-orlando-section .main-text {
  color: black;
  font-size: 14px;
}
.study-orlando-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-orlando-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-orlando-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-orlando-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-orlando-section .info-text {
  color: black;
  font-size: 14px;
}
.study-orlando-section .image-section {
  position: relative;
}
.study-orlando-section .info-buttons {
  text-align: center;
}
.study-orlando-section .buttons-section {
  text-align: center;
}
.study-orlando-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-orlando-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-orlando-section .info-buttons li {
  margin-bottom: 10px;
}
.study-orlando-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-orlando-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-orlando-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-orlando-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-orlando-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
}
.study-orlando-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
}
.study-orlando-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.study-orlando-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
.study-orlando-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-orlando-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-orlando-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-orlando-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-orlando-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-orlando-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-orlando-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-orlando-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-orlando-section {
    padding: 30px 15px;
  }
  .study-orlando-section .main-title {
    font-size: 2rem;
  }
  .study-orlando-section .subtitle {
    font-size: 1.5rem;
  }
  .study-orlando-section .highlight {
    font-size: 1.8rem;
  }
  .study-orlando-section .info-section {
    margin-bottom: 10px;
  }
  .study-orlando-section .btn-primary {
    font-size: 1rem;
  }
  .study-orlando-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-orlando-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-orlando-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-orlando-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-orlando-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-orlando-section .close:hover,
.study-orlando-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.study-tampa-section {
  color: #CCE3FA;
  padding-top: 2rem;
  padding-bottom: 5rem;
}
.study-tampa-section .main-title {
  color: #004a93;
  font-size: 2.5rem;
  font-weight: bold;
}
.study-tampa-section .red-line {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.study-tampa-section .red-line-mobile {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 1rem;
  height: 3rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.study-tampa-section .main-text {
  color: black;
  font-size: 14px;
}
.study-tampa-section .subtitle {
  color: #004A93;
  font-size: 2rem;
  font-weight: bold;
}
.study-tampa-section .highlight {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-tampa-section .highlight-mobile {
  color: #004A93;
  font-size: 3.25rem;
  font-weight: bold;
}
.study-tampa-section .info-section {
  border-right: 4px solid #EF3E42;
  padding: 10px;
  margin-bottom: 15px;
}
.study-tampa-section .info-text {
  color: black;
  font-size: 14px;
}
.study-tampa-section .accordion .accordion-button {
  border: 0 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #004A93 !important;
  color: #fff !important;
}
.study-tampa-section .accordion .accordion-button .circle-icon {
  color: #fff !important;
}
.study-tampa-section .accordion .accordion-button .accordion-button::after {
  color: #fff !important;
}
.study-tampa-section .accordion .collapsed {
  color: #004A93 !important;
  background-color: #fff !important;
}
.study-tampa-section .accordion .collapsed .circle-icon {
  color: #0074E4 !important;
}
.study-tampa-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.study-tampa-section .image-section {
  position: relative;
}
.study-tampa-section .buttons-section {
  text-align: center;
}
.study-tampa-section .buttons-section .btn-primary {
  background-color: #00529b;
  border: #00529b;
  color: #fff;
  padding: 10px 2rem;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.study-tampa-section .info-buttons {
  list-style: none;
  padding: 0;
}
.study-tampa-section .info-buttons li {
  margin-bottom: 10px;
}
.study-tampa-section .info-buttons .btn-link {
  background-color: #fff;
  border: none;
  padding: 20px 25px;
  color: #004a93;
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
}
.study-tampa-section .info-buttons .btn-link:hover {
  background-color: #CCE3FA !important;
  color: #004a93;
}
.study-tampa-section .info-buttons .btn-link:hover:after {
  content: ' →';
  color: #004a93;
  margin-right: 2px;
}
.study-tampa-section .info-buttons .btn-link:after {
  content: ' →';
  color: #0074E4;
  margin-right: 2px;
}
.study-tampa-section .extra-info-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #004a93;
  text-align: end;
}
.study-tampa-section .extra-info-text {
  color: #151515;
  font-size: 1rem;
  text-align: end;
}
.study-tampa-section .image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}
.study-tampa-section .image-gallery .img-thumbnail {
  width: calc(50% - 10px);
  height: auto;
}
/* Responsive adjustments */
@media (max-width: 1024px) and (min-width: 768px) {
  .study-tampa-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 3rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-tampa-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
@media (max-width: 768px) {
  .study-tampa-section {
    padding: 30px 15px;
  }
  .study-tampa-section .main-title {
    font-size: 2rem;
  }
  .study-tampa-section .subtitle {
    font-size: 1.5rem;
  }
  .study-tampa-section .highlight {
    font-size: 1.8rem;
  }
  .study-tampa-section .info-section {
    margin-bottom: 10px;
  }
  .study-tampa-section .buttons-section .btn-primary {
    font-size: 1rem;
  }
  .study-tampa-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .study-tampa-section .red-line-mobile {
    position: absolute;
    top: 2rem;
    right: 0;
    width: 1rem;
    height: 3rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
/* MODAL */
.study-tampa-section .full-bleed-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}
.study-tampa-section .full-bleed-content {
  margin: auto;
  display: flex;
  width: 80%;
  max-width: 1080px;
}
.study-tampa-section .close {
  position: absolute;
  top: 15px;
  left: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.study-tampa-section .close:hover,
.study-tampa-section .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.account-acctivate-set-password .input-group .btn {
  border-radius: 0.25rem;
}
.standard-english-programs-classes .red-line-class-1 {
  position: absolute;
  top: 0rem;
  right: 0;
  width: 3rem;
  height: 5rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.standard-english-programs-classes .red-line-class-2 {
  position: absolute;
  top: 14rem;
  right: 0;
  width: 3rem;
  height: 5rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.standard-english-programs-classes .red-line-class-3 {
  position: absolute;
  top: 25rem;
  right: 0;
  width: 3rem;
  height: 5rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.standard-english-programs-classes .red-line-class-4 {
  position: absolute;
  top: 36rem;
  right: 0;
  width: 3rem;
  height: 5rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .standard-english-programs-classes .red-line-class-1 {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-2 {
    position: absolute;
    top: 17rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-3 {
    position: absolute;
    top: 28rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-4 {
    position: absolute;
    top: 41rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
@media screen and (max-width: 768px) {
  .standard-english-programs-classes .red-line-class-1 {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-2 {
    position: absolute;
    top: 17rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-3 {
    position: absolute;
    top: 28rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-4 {
    position: absolute;
    top: 47rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
@media screen and (max-width: 468px) {
  .standard-english-programs-classes .red-line-class-1 {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-2 {
    position: absolute;
    top: 22rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-3 {
    position: absolute;
    top: 34rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-4 {
    position: absolute;
    top: 56rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
@media screen and (max-width: 430px) {
  .standard-english-programs-classes .red-line-class-1 {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-2 {
    position: absolute;
    top: 19rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-3 {
    position: absolute;
    top: 30rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-4 {
    position: absolute;
    top: 51rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
@media screen and (max-width: 415px) {
  .standard-english-programs-classes .red-line-class-1 {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-2 {
    position: absolute;
    top: 21rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-3 {
    position: absolute;
    top: 32rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-4 {
    position: absolute;
    top: 55rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
@media screen and (max-width: 400px) {
  .standard-english-programs-classes .red-line-class-1 {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-2 {
    position: absolute;
    top: 21rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-3 {
    position: absolute;
    top: 34rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-4 {
    position: absolute;
    top: 58rem;
    right: 0;
    width: 3rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
@media screen and (max-width: 375px) {
  .standard-english-programs-classes .red-line-class-2 {
    position: absolute;
    top: 22rem;
    right: 0;
    width: 2rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-1 {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 2rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-3 {
    position: absolute;
    top: 35rem;
    right: 0;
    width: 2rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .standard-english-programs-classes .red-line-class-4 {
    position: absolute;
    top: 59rem;
    right: 0;
    width: 2rem;
    height: 5rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
.why-choose-exam-prep-section .red-line {
  position: absolute;
  top: 4rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
@media (max-width: 480px) {
  .why-choose-exam-prep-section .cards {
    padding: 3rem 7rem;
  }
  .why-choose-exam-prep-section .red-line {
    position: absolute;
    top: 4rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
.preparation-courses-exam-english-section .red-line {
  position: absolute;
  top: -2rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
@media (max-width: 480px) {
  .preparation-courses-exam-english-section .red-line {
    position: absolute;
    top: -2rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
.course-overview-study-abroad-section .red-line {
  position: absolute;
  top: -1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
@media (max-width: 480px) {
  .course-overview-study-abroad-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
.field-study-study-abroad-section .red-line {
  position: absolute;
  top: -1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.field-study-study-abroad-section .title {
  font-size: 45px;
}
@media (max-width: 480px) {
  .field-study-study-abroad-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .field-study-study-abroad-section .title {
    font-size: 30px;
  }
}
.course-benefits-study-abroad-section .red-line {
  position: absolute;
  top: -1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
@media (max-width: 480px) {
  .course-benefits-study-abroad-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
}
.plus-course-study-abroad-section .red-line {
  position: absolute;
  top: -1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.plus-course-study-abroad-section .title {
  font-size: 45px;
}
.plus-course-study-abroad-section .table {
  --bs-table-bg: transparent !important;
  --bs-table-color: inherit !important;
}
@media (max-width: 480px) {
  .plus-course-study-abroad-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .plus-course-study-abroad-section .title {
    font-size: 30px;
  }
}
.why-choose-buisness-english-section .red-line {
  position: absolute;
  top: -1rem;
  right: 0;
  width: 4rem;
  height: 6rem;
  background-color: #EF3E42;
  border-left: 2px;
  border: none !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
}
.why-choose-buisness-english-section .blue-box {
  height: 15rem;
}
.why-choose-buisness-english-section .title {
  font-size: 45px;
}
.course-benefits-study-abroad-section .title {
  font-size: 45px;
}
@media (max-width: 480px) {
  .why-choose-buisness-english-section .red-line {
    position: absolute;
    top: 1rem;
    right: 0;
    width: 2rem;
    height: 6rem;
    background-color: #EF3E42;
    border-left: 2px;
    border: none !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;
  }
  .why-choose-buisness-english-section .blue-box {
    height: auto;
  }
  .why-choose-buisness-english-section .title {
    font-size: 30px;
  }
  .course-benefits-study-abroad-section .title {
    font-size: 30px;
  }
}
.standard-english-exams-programs {
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .standard-english-exams-programs {
    margin-top: 4rem;
  }
}
.course-overview-study-abroad-section .course-overview {
  font-size: 30px;
}
@media screen and (min-width: 768px) {
  .course-overview-study-abroad-section .course-overview {
    font-size: 45px;
  }
}
